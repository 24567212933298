/**
 * Get token from local storage
 * @returns {string|null}
 */
export const getToken = () => {
  const tokenHash = localStorage.getItem('accessToken');

  if (tokenHash) {
    const first = tokenHash.slice(0, 20);
    const second = tokenHash.slice(20, 41);
    const tokenSecurity = first + getRandomInt(9) + second;
    const token = tokenSecurity !== null ? `Token ${tokenSecurity}` : '';

    return token;
  }

  return null;
};

/**
 * Get random number
 * @param {int} max
 * @returns int
 */
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
