/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cloud_icon': {
    width: 523.646,
    height: 523.646,
    viewBox: '0 0 523.646 523.646',
    data: '<path pid="0" d="M235.523 169.902H7.412c-4.09 0-7.412 3.321-7.412 7.421v67.418a7.417 7.417 0 007.412 7.415h228.111c4.1 0 7.421-3.322 7.421-7.415v-67.424a7.415 7.415 0 00-7.421-7.415zm-114.048 58.691H24.107v-38.946h97.368v38.946zm114.048 40.758H7.412A7.417 7.417 0 000 276.763v67.424a7.41 7.41 0 007.412 7.418h228.111c4.1 0 7.421-3.31 7.421-7.418v-67.424c0-4.09-3.321-7.412-7.421-7.412zm-114.048 57.683H24.107v-38.946h97.368v38.946zm114.048 40.814H7.412c-4.09 0-7.412 3.322-7.412 7.424v67.406a7.413 7.413 0 007.412 7.418h228.111c4.1 0 7.421-3.315 7.421-7.418V375.26a7.413 7.413 0 00-7.421-7.412zm-114.048 60.596H24.107v-38.951h97.368v38.951zm402.171-163.485c0 51.708-42.061 93.763-93.768 93.763H260.624c1.2-2.914 1.938-6.076 1.938-9.422v-10.367h167.316c40.79 0 73.979-33.184 73.979-73.979s-33.183-73.97-73.979-73.97c-3.837 0-7.886.37-12.378 1.126l-6.324 1.067-3.559-5.346c-13.771-20.694-36.812-33.053-61.602-33.053-2.482 0-5.013.127-7.495.381l-8.346.848-2.199-8.098c-8.724-32.122-38.041-54.565-71.304-54.565-37.223 0-68.024 27.671-73.14 63.511h-20.005c5.225-46.79 44.984-83.303 93.145-83.303 39.458 0 74.526 24.881 87.95 61.439 29.908-.375 57.795 13.249 75.799 36.727a87.475 87.475 0 019.458-.532c51.707.004 93.768 42.072 93.768 93.773z"/>'
  }
})
