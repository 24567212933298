const getDefaultState = () => {
  return {
    form: {
      apt_type: [],
      rooms: [],
      material_type: [],
      is_angled: false
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterType(state) {
    return !!(
      state.form.apt_type.length ||
      state.form.rooms.length ||
      state.form.material_type.length ||
      state.form.is_angled
    );
  }
};

export const mutations = {
  CHANGE_APT_TYPE(state, payload) {
    state.form.apt_type = payload;
  },
  CHANGE_ROOMS(state, payload) {
    state.form.rooms = payload;
  },
  CHANGE_MATERIAL_TYPE(state, payload) {
    state.form.material_type = payload;
  },
  CHANGE_ANGLED(state, payload) {
    state.form.is_angled = payload;
  },
  RESET_FILTER_TYPE(state) {
    Object.assign(state, getDefaultState());
  }
};
