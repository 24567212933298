<template>
  <input-wrapper :class="[$bem(), { 'not-empty': !!value }]" v-bind="$attrs" v-on="$listeners">
    <template #input="{ on, isError }">
      <textarea
        :class="[$bem('input')]"
        v-bind="{
          name,
          value,
          required,
          disabled,
          size,
          placeholder,

        }"
        v-on="{
          ...$listeners,
          ...on,
          input: (e) => $emit('input', e.target.value)
        }"
        v-input-autofocus="autofocus"
      />

      <label
        v-show="!!label"
        :class="[$bem('label'), dir]"
        v-bind="{ disabled }"
      >
        {{ label }}
        <slot name="label" v-bind="{ label, isError }" />
        <sub v-show="required" :class="$bem('required')">*</sub>
      </label>
    </template>
  </input-wrapper>

</template>

<script>
import component from '@/mixins/component';
import inputWrapper from '../inputs/input-wrapper';

export default {
  name: 'e-textarea',
  mixins: [component],
  components: {inputWrapper},
  directives: {
    'input-autofocus': {
      bind(el, { value }) {
        if (value) el.focus();
      },
      update(el, { value }) {
        if (value) el.focus();
      }
    }
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    // s = 40px, l = 56px, m = 64px, xl = 72px
    size: {
      type: String,
      default: undefined
    },
  }
};
</script>
