import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

const allModules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    const modulesAll = modules;
    modulesAll[name] = { ...module, mutations: { ...module.mutations, ...mutations }}
    return modulesAll;
  }, {});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: allModules
});
