<template>
  <div :class="$bem()">
    <e-multiselect
      placeholder="Почніть вводити:"
      :close-on-select="false"
      :clear-on-select="false"
      :show-no-results="false"
      label="name"
      track-by="id"
      :show-labels="false"
      v-on="$listeners"
      :multiple="multiple"
      v-bind="$attrs"
      :options="microdistricts"
      :internalSearch="false"
      @search-change="findMicrodistrict"
    >
      <span slot="noResult">Немає результатів!!!</span>
    </e-multiselect>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import component from '../../../mixins/component';
import eMultiselect from '../../../elements/e-multiselect';

export default {
  name: 'add-object-microdistricts',
  mixins: [component],
  components: { eMultiselect },
  computed: {
    ...mapGetters(['microdistricts'])
  },
  data: {
      timer: null
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['handleGetMicroDistrict']),
    findMicrodistrict(name) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => { 
        this.handleGetMicroDistrict({ name })
      }, 300)
    }
  },
};
</script>
