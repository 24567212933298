<template>
  <footer id="contacts" :class="$bem()">
    <div class="landing-container">
      <div :class="$bem('inner')">
        <img
          src="@/assets/images/logo/logo-04.webp"
          alt="EasyBase"
          loading="lazy"
          :class="$bem('logo')"
        />

        <ul :class="$bem('social')">
          <li>
            <a :href="INSTAGRAM_LINK" target="_blank">
              <img
                src="@/assets/landing/instagram.svg"
                alt="EasyBase instagram"
                loading="lazy"
                width="28"
                height="28"
              />
            </a>
          </li>

          <li>
            <a :href="FACEBOOK_LINK" target="_blank">
              <img
                src="@/assets/landing/facebook.svg"
                alt="EasyBase facebook"
                loading="lazy"
                width="28"
                height="28"
              />
            </a>
          </li>

          <li>
            <a :href="VIBER_LINK" target="_blank">
              <img
                src="@/assets/landing/viber.svg"
                alt="EasyBase viber"
                loading="lazy"
                width="28"
                height="28"
              />
            </a>
          </li>

          <li>
            <a :href="TELEGRAM_LINK" target="_blank">
              <img
                src="@/assets/landing/telegram.svg"
                alt="EasyBase telegram"
                loading="lazy"
                width="28"
                height="28"
              />
            </a>
          </li>
        </ul>

        <small :class="$bem('copyright')">
          © 2021-2024 EasyBase. Всі права захищені.
        </small>
      </div>
    </div>
  </footer>
</template>

<script>
import component from '@/mixins/component';
import {
  TELEGRAM_LINK,
  VIBER_LINK,
  INSTAGRAM_LINK,
  FACEBOOK_LINK
} from '@/helpers/constants';

export default {
  name: 'landing-footer',
  mixins: [component],
  data() {
    return {
      TELEGRAM_LINK,
      VIBER_LINK,
      INSTAGRAM_LINK,
      FACEBOOK_LINK
    };
  }
};
</script>
