const getDefaultState = () => {
  return {
    form: {
      updated_from: '',
      updated_to: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterDate(state) {
    return !!(state.form.updated_from.length || state.form.updated_to.length);
  }
};

export const mutations = {
  CHANGE_VALUE_FROM(state, payload) {
    state.form.updated_from = payload;
  },
  CHANGE_VALUE_TO(state, payload) {
    state.form.updated_to = payload;
  },
  RESET_FILTER_DATES(state) {
    Object.assign(state, getDefaultState());
  }
};
