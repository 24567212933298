/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon5': {
    width: 16,
    height: 16,
    viewBox: '0 0 444.955 444.955',
    data: '<path pid="0" d="M95.347 0H31.782C14.239 0 0 14.239 0 31.782v63.565c0 17.544 14.239 31.782 31.782 31.782h63.565c17.544 0 31.782-14.239 31.782-31.782V31.782C127.13 14.239 112.891 0 95.347 0zM254.26 0h-63.565c-17.544 0-31.782 14.239-31.782 31.782v63.565c0 17.544 14.239 31.782 31.782 31.782h63.565c17.544 0 31.782-14.239 31.782-31.782V31.782C286.042 14.239 271.804 0 254.26 0zM413.172 0h-63.565c-17.544 0-31.783 14.239-31.783 31.782v63.565c0 17.544 14.239 31.782 31.783 31.782h63.565c17.544 0 31.783-14.239 31.783-31.782V31.782C444.955 14.239 430.716 0 413.172 0zM95.347 158.912H31.782C14.239 158.912 0 173.151 0 190.695v63.565c0 17.544 14.239 31.782 31.782 31.782h63.565c17.544 0 31.782-14.239 31.782-31.782v-63.565c.001-17.544-14.238-31.783-31.782-31.783zM254.26 158.912h-63.565c-17.544 0-31.782 14.239-31.782 31.782v63.565c0 17.544 14.239 31.782 31.782 31.782h63.565c17.544 0 31.782-14.239 31.782-31.782v-63.565c0-17.543-14.238-31.782-31.782-31.782zM413.172 158.912h-63.565c-17.544 0-31.783 14.239-31.783 31.782v63.565c0 17.544 14.239 31.782 31.783 31.782h63.565c17.544 0 31.783-14.239 31.783-31.782v-63.565c0-17.543-14.239-31.782-31.783-31.782zM95.347 317.825H31.782C14.239 317.825 0 332.063 0 349.607v63.565c0 17.544 14.239 31.783 31.782 31.783h63.565c17.544 0 31.782-14.239 31.782-31.783v-63.565c.001-17.544-14.238-31.782-31.782-31.782zM254.26 317.825h-63.565c-17.544 0-31.782 14.239-31.782 31.783v63.565c0 17.544 14.239 31.783 31.782 31.783h63.565c17.544 0 31.782-14.239 31.782-31.783v-63.565c0-17.545-14.238-31.783-31.782-31.783zM413.172 317.825h-63.565c-17.544 0-31.783 14.239-31.783 31.783v63.565c0 17.544 14.239 31.783 31.783 31.783h63.565c17.544 0 31.783-14.239 31.783-31.783v-63.565c0-17.545-14.239-31.783-31.783-31.783z"/>'
  }
})
