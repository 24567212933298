/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mail_add_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M945.5 477.7c-24.6 0-44.5-20-44.5-44.6v-60.2L545.8 538.4c-16.8 9.9-37.2 7.4-51.3-4.6-13.1 6.8-29.2 7.1-42.9-.8L99.1 374.2v437.7h467.7c24.6 0 44.5 19.9 44.5 44.6 0 24.6-19.9 44.6-44.5 44.6h-490c-18.1 0-33.6-10.8-40.5-26.3-15.5-7-26.3-22.5-26.3-40.6V165.8c0-18.1 10.8-33.6 26.3-40.6 7-15.5 22.5-26.3 40.5-26.3h846.4c18.1 0 33.6 10.8 40.5 26.3 15.5 7 26.3 22.5 26.3 40.6v267.3c0 24.7-19.9 44.6-44.5 44.6zM99.1 188.1V283c8.8-.8 17.9 1 26.2 5.7l370.8 167.1c1.6.9 2.6 2.5 4 3.5.2-.1.3-.4.6-.5l376.7-175.5c7.4-4.4 15.5-6.2 23.5-6V188H99.1zm579.1 490.1h89.1v-89.1c0-24.6 19.9-44.6 44.5-44.6s44.5 20 44.5 44.6v89.1h89.1c24.6 0 44.5 19.9 44.5 44.6s-19.9 44.6-44.5 44.6h-89.1v89.1c0 24.6-19.9 44.6-44.5 44.6s-44.5-19.9-44.5-44.6v-89.1h-89.1c-24.6 0-44.5-19.9-44.5-44.6-.1-24.6 19.9-44.6 44.5-44.6z"/>'
  }
})
