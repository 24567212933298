<template>
  <div
    :class="[$bem(), { overlay: overlay && value }]"
    v-on="{ ...$listeners, input: close }"
    @keydown.esc.prevent="close"
  >
    <slot name="activator" v-bind="{ open, close }" />

    <div
      v-if="value !== undefined"
      v-show="value"
      :class="[
        $bem('content'), 'p-2',
        { top, bottom, left, right, overlay: overlay && value, fullscreen },

        contentClass
      ]"
      ref="content"
      :style="{
        top,
        bottom,
        left,
        right,
        width: fullscreen ? undefined : width,
        height: fullscreen ? undefined : height,
        minWidth,
        maxWidth
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

const {
  document: { body }
} = window;

export default {
  name: 'e-menu',
  inheritAttrs: false,
  mixins: [component],
  data: ({ $bem }) => ({ freezeClass: $bem('body-freeze') }),
  watch: {
    value: {
      handler(value) {
        if (this.freezeBody) body.classList[value ? 'add' : 'remove'](this.freezeClass);

        ['click', 'touchstart'].forEach((e) => {
          body[value ? 'addEventListener' : 'removeEventListener'](e, this.clickOutside, true);
        });
      },
      immediate: true
    }
  },
  methods: {
    clickOutside({ target }) {
      const { $el: root, $refs: { content } = {}, overlay, close } = this;

      if (
        !content ||
        (!content.isEqualNode(target) && !(overlay ? content : root).contains(target))
      )
        close();
    },
    open() {
      if (!this.value) this.$emit('input', true);
    },
    close() {
      if (this.value && !this.persistent) this.$emit('input', false);
    }
  },
  beforeDestroy() {
    if (body.classList.contains(this.freezeClass)) body.classList.remove(this.freezeClass);

    ['click', 'touchstart'].forEach((e) => {
      body.removeEventListener(e, this.clickOutside, true);
    });
  },
  props: {
    value: {
      // коль undefined контент не рендериться
      type: Boolean,
      default: undefined
    },
    persistent: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    freezeBody: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: [String, Array, Object],
      default: undefined
    },
    top: {
      type: [Boolean, String],
      default: false
    },
    bottom: {
      type: [Boolean, String],
      default: false
    },
    left: {
      type: [Boolean, String],
      default: false
    },
    right: {
      type: [Boolean, String],
      default: false
    },
    width: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: undefined
    },
    minWidth: {
      type: String,
      default: undefined
    },
    maxWidth: {
      type: String,
      default: undefined
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  }
};
</script>
