import Axios from 'axios';
// eslint-disable-next-line import/no-unresolved
import { getToken } from '@/utils/token-builder';
// eslint-disable-next-line import/no-unresolved
import store from '@/store';
import { getRouterKey } from '../helpers/routerKey.js';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT
});

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = getToken();
    const params = config;
    if (token) {
      params.headers.Authorization = token;
    }

    return params;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    switch (error.response.status) {
      case 401:
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
        break;

      case 403:
        if (error.response.data.detail === 'fio') {
          store.commit('PROFILE_DATA', true);
        } else {
          store.dispatch('handleGetUser');
          store.commit('SUBSCRIBES', true);
          store.commit('SET_OBJECTS', []);
          store.commit('SET_PAGE', 1);
          store.commit('SET_COUNT', 0);
          store.commit('CLEAN_STORE');
        }
        store.commit('LOADER_OBJECT', false);
        return;

      case 429:
        return error.response;

      case 400:
      case 406:
      default:
        console.error(error, 'error');
        break;
    }

    return Promise.reject(error);
  }
);

export default {
  /**
   * Get microdistrict for address filter
   *
   * @returns {Promise}
   */
  handleGetMicroDistrict(params) {
    return axios.get('/reference/microdistricts/', { params });
  },

  /**
   * Get district for address filter
   *
   * @returns {Promise}
   */
  handleGetDistrict() {
    return axios.get('/reference/districts/');
  },

  /**
   * Get street for address filter
   *
   * @returns {Promise}
   */
  handleGetStreet(params) {
    return axios.get('/reference/streets/', { params });
  },

  /**
   * Get street for address filter
   *
   * @returns {Promise}
   */
  handleGetResidentialComplex(params) {
    return axios.get('/reference/residentials/', { params });
  },

  /**
   * Get filtered apartments
   * @param {string} paramsUrl
   * @returns {Promise}
   */
  async getObjects(paramsUrl = '', keyForApi) {
    return axios.get(`/${getRouterKey(keyForApi)}/filter/${paramsUrl}`);
  },

  /**
   * Get filtered apartments
   * @param {string} realtyId
   * @returns {Promise}
   */
  async getRealtyIById(paramsUrl = '') {
    return axios.get(`/global-search/${paramsUrl}`);
  },

  /**
   * Get recaptcha token
   * @param {string} token
   * @returns {Promise}
   */
  async getRecaptcha(token) {
    return axios.post('/recaptcha/', { token });
  },

  /**
   * Login user | get token
   *
   * @param {string} phone
   * @param {string} password
   * @returns {Promise}
   */
  login(credentials) {
    return axios.post('/get-token/', credentials);
  },

  /**
   * @param {string} phone
   * @returns {Promise}
   */
  smsRestore(phone) {
    return axios.get(`/sms_restore/${phone}/`);
  },

  /**
   * @param {string} phone
   * @returns {Promise}
   */
  smsForRegistration(phone) {
    return axios.get(`/sms/${phone}/`);
  },

  /**
   * @param {string} code
   * @param {bool} statusPhone
   * @returns {Promise}
   */
  smsRestoreVerify(code, statusPhone) {
    return axios.get(`/sms_restore/verify/${code}/${statusPhone}/`);
  },

  /**
   * @param {string} code
   * @param {bool} statusPhone
   * @returns {Promise}
   */
  smsRestoreVerifyForRegistration(code, statusPhone) {
    return axios.get(`/sms/verify/${code}/${statusPhone}/`);
  },

  /**
   * @param {object} statusPhone
   * @param {string} phone
   * @param {string} password
   * @returns {Promise}
   */
  passwordRestore(statusPhone, phone, password) {
    return axios.post(`/restore/${statusPhone.id}/${phone}/`, { password });
  },

  /**
   * @param {object} statusPhone
   * @param {object} data
   * @returns {Promise}
   */
  register(statusPhone, data) {
    return axios.post(`/register/${statusPhone.id}/`, data);
  },

  /**
   * @returns {Promise}
   */
  getMe() {
    return axios.get('/user/me/');
  },

  /**
   * @param {array} data
   * @returns {Promise}
   */
  updateUser(data) {
    return axios.post('/user/update/', { data });
  },

  /**
   * @param {string} type
   * @returns {Promise}
   */
  paymentEasycoins(type) {
    return axios.post('/user/easycoinspay/', {
      subscription_type: type
    });
  },

  /**
   * @returns {Promise}
   */
  getMyObjects(params, keyForApi) {
    return axios.get(`/${getRouterKey(keyForApi)}/get-objects/`, { params });
  },

  /**
   * @param {array} params
   * @returns {Promise}
   */
  createClient(params) {
    return axios.post('/clients/', params);
  },

  /**
   * @returns {Promise}
   */
  getClients(limit) {
    return axios.get(`/clients/?limit=${limit}`);
  },

  /**
   * @returns {Promise}
   */
  deleteClient(id) {
    return axios.delete(`/clients/${id}/`);
  },

  /**
   * @param {int} id
   * @param {object} data
   * @returns {Promise}
   */
  editClient(id, data) {
    return axios.put(`/clients/${id}/`, data);
  },

  /**
   *
   * @param {int} id
   * @param {string} type
   * @returns {Promise}
   */
  reportArchive(id, type, keyForApi) {
    return axios.post(`/${getRouterKey(keyForApi)}/${id}/report/`, {
      reason: type
    });
  },

  /**
   *
   * @param {int} id
   * @param {array} data
   * @returns {Promise}
   */
  handleEditApartment(id, data, keyForApi) {
    return axios.put(`/${getRouterKey(keyForApi)}/${id}/`, data);
  },

  handleExportImages(id, images, keyForApi) {
    return axios.post(`/${getRouterKey(keyForApi)}/${id}/images/`, images);
  },

  handleSortingImagesByApartmentsId({ realtyId, images }, keyForApi) {
    return axios.put(`/${getRouterKey(keyForApi)}/${realtyId}/images/`, images);
  },
  handleCreateImagesByApartmentsId({ realtyId, images }, keyForApi) {
    return axios.post(
      `/${getRouterKey(keyForApi)}/${realtyId}/images/`,
      images
    );
  },

  handleDeleteImageByApartmentsId({ realtyId, id }, keyForApi) {
    return axios.delete(`/${getRouterKey(keyForApi)}/${realtyId}/image/${id}/`);
  },

  /**
   *
   * @param {string} keyForApi
   * @param {array} data
   * @returns {Promise}
   */
  handleCreateApartment(data, keyForApi) {
    return axios.post(`/${getRouterKey(keyForApi)}/`, data);
  },

  /**
   *
   * @param {array} data
   * @returns {Promise}
   */
  handleAddCommentToShare(data, keyForApi) {
    return axios.post(`/${getRouterKey(keyForApi)}/${data.id}/comment/`, {
      text: data.comment
    });
  },

  /**
   *
   * @param {array} data
   * @returns {Promise}
   */
  updateApartmentLabels(data, keyForApi) {
    return axios.post(
      `/${getRouterKey(keyForApi)}/${data.params.id}/labels/`,
      data.data
    );
  },

  /**
   *
   * @param {string} phone
   * @returns {Promise}
   */
  sendPhoneReferral(phone) {
    return axios
      .post('/sms_friend/', { phone })
      .then((response) => response)
      .catch((e) => e.response);
  },

  /**
   * @returns {Promise}
   */
  getLastSavedFilter(type) {
    return axios.get(`/${getRouterKey(type)}/`);
  },

  /**
   * @returns {Promise}
   */
  getEasycoins() {
    return axios.get('/user/me/easycoins/');
  },

  /**
   * @returns {Promise}
   */
  getNotifications() {
    return axios.get('/notifications/popup/');
  },

  /**
   * @returns {Promise}
   *  @param {string} id
   */
  sendViewedNews(id) {
    return axios.patch(`/notifications/popup/${id}/`);
  },

  /**
   * @returns {Promise}

   */
  getCities() {
    return axios.get(`/reference/cities/`);
  },

  /**
   * @returns {Promise}

   */
  getBuildings(params = {}) {
    return axios.get(`/reference/buildings/`, { params });
  },

  /**
   * @returns {Promise}
   *  @param {string} id
   */
  getDistrictsByIdCities(id) {
    return axios.get(`/reference/districts/?city=${id}`);
  },

  /**
   * @returns {Promise}
   *  @param {array} ids
   */
  getDistrictsEntityByIds(ids) {
    return axios.get(`reference/districts/?identifiers=${ids}`);
  },

  /**
   * @returns {Promise}
   *  @param {array} id
   */
  getMicroDistrictsEntityByIds(id) {
    return axios.get(`/reference/microdistricts/?identifiers=${id}`);
  },

  /**
   * @returns {Promise}
   *  @param {array} id
   */
  getStreetsEntityByIds(id) {
    return axios.get(`/reference/streets/?identifiers=${id}`);
  }
};
