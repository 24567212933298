const getDefaultState = () => {
  return {
    form: {
      source: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterSource(state) {
    return !!state.form.source.length;
  }
};

export const mutations = {
  CHANGE_SOURCE(state, payload) {
    state.form.source = payload;
  },
  RESET_FILTER_SOURCE(state) {
    Object.assign(state, getDefaultState());
  }
};
