// eslint-disable-next-line import/no-unresolved
import api from '@/api';

export const state = {
  notifications: []
};

export const getters = {
  getNotifications: (state) => state.notifications
};

export const actions = {
  async handleGetNotifications({ commit }) {
    try {
      const {
        data: { results }
      } = await api.getNotifications();
      commit('SET', { type: 'notifications', value: results });
    } catch (e) {
      console.log(e, 'e');
    }
  },
  updateNotificationStatus({ state, commit }, id) {
    let notification = state.notifications.find(notification => notification.id === id);
    if(notification) {
      notification.is_seen = true;
      commit('SET', { type: 'notifications', value: state.notifications });
    }
  }
};

export const mutations = {
  SET(state, { type, value }) {
    state[type] = value;
  }
};
