/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_istochnic_icon3': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M500 126.4c73.5 0 128.6 55.1 128.6 128.6S573.5 383.6 500 383.6 371.4 328.5 371.4 255 426.5 126.4 500 126.4m0 551.2c183.7 0 373.6 91.9 373.6 128.6v67.4H126.4v-67.4c0-36.7 189.9-128.6 373.6-128.6M500 10c-134.7 0-245 110.2-245 245s110.3 245 245 245c134.8 0 245-110.3 245-245 0-134.8-110.2-245-245-245zm0 551.2c-165.4 0-490 79.6-490 245V990h980V806.2c0-165.3-324.6-245-490-245z"/>'
  }
})
