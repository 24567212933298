const getDefaultState = () => {
  return {
    form: {
      city: '',
      district: [],
      microdistrict: [],
      street: [],
      residential_complex: [],
      building: [],
      house_number_even: false,
      house_number_odd: false,
      house_number: '',
      house_letter: '',
      house_hull: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  getForm: (state) => state.form.city,
  existsTurnOnFilterAddress(state) {
    return !!(
      state.form.district.length ||
      state.form.microdistrict.length ||
      state.form.street.length ||
      state.form.residential_complex.length ||
      state.form.building.length ||
      state.form.house_number_even ||
      state.form.house_number_odd ||
      state.form.house_number ||
      state.form.house_letter ||
      state.form.house_hull
    );
  }
};

export const mutations = {
  CHANGE_CITY(state, value) {
    state.form.city = value;
  },

  CHANGE_DISTRICT(state, payload) {
    state.form.district = payload;
  },
  CHANGE_MICRODISTRICT(state, payload) {
    state.form.microdistrict = payload;
  },
  CHANGE_STREET(state, payload) {
    state.form.street = payload;
  },
  CHANGE_RESIDENTIAL_COMPLEX(state, payload) {
    state.form.residential_complex = payload;
  },
  CHANGE_BUILDING(state, payload) {
    state.form.building = payload;
  },
  CHANGE_HOUSE_NUMBER_EVEN(state, val) {
    state.form.house_number_even = val;
  },
  CHANGE_HOUSE_NUMBER_ODD(state, val) {
    state.form.house_number_odd = val;
  },
  CHANGE_HOUSE_NUMBER(state, val) {
    state.form.house_number = val;
  },
  CHANGE_HOUSE_LETTER(state, val) {
    state.form.house_letter = val;
  },
  CHANGE_HOUSE_HULL(state, val) {
    state.form.house_hull = val;
  },
  RESET_FILTER_ADDRESS(state) {
    const resetState = getDefaultState();
    resetState.form.city = state.form.city || '';
    Object.assign(state, resetState);
  }
};
