/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
  cadastral_number: {
    width: 16,
    height: 16,
    viewBox: '0 0 40 22',
    data:
      '<path id="Vector" d="M19.9743 0.966894C19.5053 0.970474 19.0574 1.07017 18.7259 1.24478L1.46164 10.3296C1.29423 10.4176 1.16144 10.5222 1.07083 10.6372C0.980228 10.7523 0.933594 10.8756 0.933594 11.0002C0.933594 11.1247 0.980228 11.2481 1.07083 11.3631C1.16144 11.4782 1.29423 11.5827 1.46164 11.6708L18.7259 20.7556C18.8932 20.8437 19.0919 20.9136 19.3105 20.9612C19.5292 21.0089 19.7636 21.0335 20.0003 21.0335C20.237 21.0335 20.4713 21.0089 20.69 20.9612C20.9086 20.9136 21.1073 20.8437 21.2746 20.7556L38.5389 11.6708C38.7063 11.5827 38.8391 11.4782 38.9297 11.3631C39.0203 11.2481 39.0669 11.1247 39.0669 11.0002C39.0669 10.8756 39.0203 10.7523 38.9297 10.6372C38.8391 10.5222 38.7063 10.4176 38.5389 10.3296L21.2746 1.24478C21.1042 1.15504 20.9014 1.08421 20.678 1.03648C20.4547 0.988743 20.2154 0.965082 19.9743 0.966894ZM20.0003 3.25583L23.3227 5.00434C22.8483 5.17798 22.3886 5.3864 21.9477 5.62777C20.8697 6.21645 19.8664 7.00771 19.2807 8.05418C16.9558 7.70317 14.5806 7.49182 12.2385 7.34008L20.0003 3.25583ZM25.8333 6.32542L34.7177 11.0002L31.8048 12.5326C28.8255 10.3899 25.1965 9.15404 21.341 8.40447C21.7292 7.97484 22.2756 7.55252 22.8907 7.21649C23.8173 6.71081 24.8876 6.4022 25.5458 6.37405C25.6437 6.37209 25.7406 6.35569 25.8333 6.32542ZM9.13417 8.97414C9.1973 8.98996 9.26196 8.99951 9.32712 9.00266C16.7346 9.3939 24.5994 10.0916 29.9466 13.5103L28.6753 14.1795C25.7803 12.3882 22.459 11.0883 18.3305 11.2938C15.8687 11.4163 13.1178 12.0781 10.0024 13.4833L5.28361 11.0002L9.13417 8.97414ZM19.5282 13.0255C22.3004 13.0759 24.5913 13.952 26.758 15.1886L20.0003 18.7445L12.0982 14.5861C14.968 13.4365 17.3909 12.9867 19.5282 13.0255Z" fill="#F5F5F5"/>'
  }
});
