/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_istochnic_icon2': {
    width: 16,
    height: 16,
    viewBox: '0 0 443.307 443.307',
    data: '<path pid="0" d="M370.987 357.973c0 23.573-19.093 42.667-42.667 42.667-6.08 0-12.053-1.28-16.32-3.2-15.04-8-25.92-18.88-36.48-50.88-10.987-33.173-31.36-48.853-51.093-64.107C207.573 269.44 190.08 256 175.04 228.587c-11.2-20.48-17.387-42.773-17.387-62.613 0-59.84 46.827-106.667 106.667-106.667s106.667 46.827 106.667 106.667h42.667c0-83.733-65.6-149.333-149.333-149.333s-149.333 65.6-149.333 149.333c0 26.987 8.107 56.533 22.72 83.2 19.413 35.307 42.347 52.907 60.8 67.2 17.28 13.333 29.76 22.933 36.587 43.627 12.8 38.72 29.333 60.587 58.133 75.733 11.093 5.013 22.827 7.573 35.093 7.573 47.04 0 85.333-38.293 85.333-85.333h-42.667z"/><path pid="1" d="M128.533 30.187L98.347 0C55.893 42.453 29.653 101.12 29.653 165.973s26.24 123.52 68.693 165.973l30.187-30.187C93.76 266.987 72.32 218.987 72.32 165.973S93.76 64.96 128.533 30.187zM210.987 165.973c0 29.44 23.893 53.333 53.333 53.333s53.333-23.893 53.333-53.333-23.893-53.333-53.333-53.333-53.333 23.893-53.333 53.333z"/>'
  }
})
