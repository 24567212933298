/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'double_down_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M41.8 98.2c8 0 16.1 3 22.3 9.1L497 532.5l433-425.1c12.5-12.3 32.7-12.1 45 .4 12.3 12.5 12.1 32.7-.4 45l-455.2 447c-12.4 12.1-32.2 12.1-44.6 0l-455.2-447c-12.5-12.3-12.7-32.4-.4-45 6.1-6.4 14.4-9.6 22.6-9.6z"/><path pid="1" d="M47.7 391.1c8 0 16.1 3 22.3 9.1l433 425.2 432.9-425.1c12.5-12.3 32.7-12.1 45 .4 12.3 12.5 12.1 32.7-.4 45l-455.2 447c-12.4 12.1-32.2 12.1-44.6 0l-455.2-447c-12.5-12.3-12.7-32.4-.4-45 6.2-6.4 14.4-9.6 22.6-9.6z"/>'
  }
})
