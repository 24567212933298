/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_icon3': {
    width: 357,
    height: 357,
    viewBox: '0 0 357 357',
    data: '<path pid="0" d="M51 229.5H0V357h127.5v-51H51v-76.5zm-51-102h51V51h76.5V0H0v127.5zM306 306h-76.5v51H357V229.5h-51V306zM229.5 0v51H306v76.5h51V0H229.5z"/>'
  }
})
