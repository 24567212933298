import constants from '../helpers/constants';
import getFilterMutationName from './get-filter-mutation-name';
import constantCommunications from '../components/app/filters/filter-comunication/ConstantComunications';
import api from '../api';

const fieldsIdentifiers = ['district', 'microdistrict', 'street'];

function filterMockData(fixture, value, searchField = 'id') {
  return fixture.filter((row) => {
    return value.indexOf(row[searchField].toString()) !== -1;
  });
}

async function getEntity(name, ids) {
  let response = {};

  // eslint-disable-next-line default-case
  switch (name) {
    case 'district':
      response = await api.getDistrictsEntityByIds(ids);
      break;
    case 'microdistrict':
      response = await api.getMicroDistrictsEntityByIds(ids);
      break;
    case 'street':
      response = await api.getStreetsEntityByIds(ids);
      break;
  }

  const {
    data: { results }
  } = response;

  return results;
}

async function setFilter(routeQuery, rootState, commit) {
  if (routeQuery && routeQuery.rooms && routeQuery.rooms.length) {
    routeQuery.rooms = routeQuery.rooms.map((i) =>
      i == 5 || i === '5+' || i === '5 ' || i === '5%2B' ? '5%2B' : i
    );
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in routeQuery) {
    if (getFilterMutationName[key]) {
      // exists filter type
      let value = routeQuery[key];
      let fixture = [];
      if (fieldsIdentifiers.includes(key)) {
        // eslint-disable-next-line no-await-in-loop
        fixture = await getEntity(key, value);
      }

      // eslint-disable-next-line default-case
      switch (key) {
        case 'residential_complex':
          value = filterMockData(rootState.addresses.residentialComplex, value);
          break;
        case 'district':
          value = filterMockData(rootState.addresses.districts, value);
          break;
        case 'microdistrict':
          value = filterMockData(rootState.addresses.microdistricts, value);
          break;
        case 'street':
          value = filterMockData(fixture, value);
          break;
        case 'city':
          value = filterMockData(rootState.addresses.cities, value);
          break;
        case 'water':
        case 'gas':
        case 'heating':
        case 'sewers':
          value = filterMockData(
            constantCommunications[`${key}_data`],
            value,
            'label'
          );
          break;
        case 'material_type':
          value = filterMockData(constants.material_array, value);
          break;
        case 'repair':
          value = filterMockData(constants.choises_label, value, 'label');
          break;
        case 'user_label':
          value = filterMockData(constants.user_labels_array, value);
          break;
      }

      commit(getFilterMutationName[key], value);
    }
  }
}

export default setFilter;
