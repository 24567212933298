<template>
  <div :class="$bem()">
    <login-form v-if="$route.path === '/login'" />
    <reset-password v-else-if="$route.path === '/reset-password'" />
  </div>
</template>

<script>
import component from '@/mixins/component';
import LoginForm from '@/components/auth/LoginForm.vue';
import ResetPassword from '../../../pages/ResetPassword.vue';

export default {
  components: { LoginForm, ResetPassword },
  mixins: [component]
};
</script>

<style lang="sass">
.form-container
    width: 40%
    margin-top: 100px
    @media screen and (max-width: 991px)
        width: 100%
        display: flex
        justify-content: center
        margin: auto
        margin-bottom: 50px
        margin-top: 30px
</style>
