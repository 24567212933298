<template>
  <div class="radio-additional-options" :class="{ _error: error }">
    <div v-if="error" class="wrapper-business-type__error-message">
      <span>Поле обов'язкове для заповнення</span>
    </div>

    <div style="font-size: 16px" class="mb-2">
      <span>{{ title }}</span>

      <span v-if="required" class="text-danger"> *</span>
    </div>

    <div class="d-flex">
      <label :for="title + 'yes'" class="app-radio mr-3">
        <input v-model="model" :value="true" type="radio" :id="title + 'yes'" />

        <div class="app-radio__mark" aria-hidden="true"></div>

        <div class="app-radio__text">Так</div>
      </label>

      <label :for="title + 'no'" class="app-radio">
        <input v-model="model" :value="false" type="radio" :id="title + 'no'" />

        <div class="app-radio__mark" aria-hidden="true"></div>

        <div class="app-radio__text">Ні</div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'additional-options',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
