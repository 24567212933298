<template>
  <button
    v-bind="{ type: 'button', ...$attrs }"
    v-on="$listeners"
    :class="[$bem(), { transparent, swap }, color, 'size-'.concat(swap ? 4 : size)]"
  >
    <slot />
  </button>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 't-btn-navigate',
  inheritAttrs: false,
  mixins: [component],
  props: {
    swap: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    color: {
      type: String, // primary
      default: undefined
    },
    size: {
      type: String,
      default: '3'
    }
  }
};
</script>
