<template>
  <div :class="$bem()">
    <e-multiselect
      v-on="$listeners"
      placeholder="Почніть вводити:"
      :close-on-select="false"
      :clear-on-select="false"
      :show-no-results="false"
      label="name"
      track-by="id"
      v-bind="$attrs"
      :show-labels="false"
      :options="streets"
      :multiple="multiple"
      :internalSearch="false"
      @search-change="findStreets"
    >
      <span slot="noResult">Немає результатів!!!</span>
    </e-multiselect>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import component from '../../../mixins/component';
import eMultiselect from '../../../elements/e-multiselect';

export default {
  name: 'add-object-streets',
  mixins: [component],
  components: { eMultiselect },
  data: {
      timer: null
  },
  methods: {
    ...mapActions(['handleGetStreet']),
    findStreets(name) {
      if (name.length > 0 && name.length < 3) return;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => { 
        this.handleGetStreet({ name })
      }, 300)
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters(['streets']),
  }
};
</script>
