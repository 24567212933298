<template>
  <b-modal
    id="terms-popup"
    modal-class="modal-add"
    :static="true"
    title-class="modal-add-title"
    hide-header-close
    title="Умови та правила"
  >
    <ul class="list-group">
      <li>
        <h6><b>1. Терміни, що використовуються у цих Правилах</b></h6>
        <ul class="list-group">
          <li>
            <b>1.1. Сайт</b> база нерухомості, спільнота ріелторів, представлена ​
            ​сукупністю електронних файлів, документів, кодів, мультимедіа компонентів
            та інших інформаційних ресурсів, об'єднаних у єдиний цілісний програмний
            продукт з унікальним фірмовим інтерфейсом та функціями, доступними в 
            мережі Інтернет виключно ріелторам за адресою
            <a href="http://easybase.com.ua/" target="_blank" rel="nofollow"
              >http://easybase.com.ua</a
            >(включаючи всі рівні вказаного домену).
          </li>
          <li>
            <b>11.2. Користувач (у множині — Користувачі)</b>
            — особа, що має статус ріелтора, яка уклала Угоду з 
            фвиконавцем, шляхом акцепту оферти, розташованої в 
            мережі Інтернет за адресою
            <a
              href="http://easybase.com.ua/profile"
              target="_blank"
              rel="nofollow"
              >http://easybase.com.ua/profile</a
            >, відвідувач Сайту, фізична особа, що взаємодіє з 
            Сайтом безпосередньо або через програми, додатки, 
            коди або будь-які інші способи взаємодії між 
            фізичними особами та інтернет-ресурсами.
          </li>
          <li>
            <b>1.3. Адміністрація </b> — ФОП Железняк С.А, зареєстроване 
            в Єдиному державному реєстрі юридичних осіб, фізичних 
            осіб-підприємців та громадських формувань України 28.04.2020 
            (номер запису 2 223 000 0000 027901, ідентифікаційний код 3350)

          </li>
          <li>
            <b>1.4. Правила</b> — публічна оферта Адміністрації, адресована 
            Користувачеві Сайту, текст якої одночасно є договором, який укладається 
            між Адміністрацією та Користувачем за допомогою інформаційно-телекомунікаційних 
            систем та прирівняний, згідно ст. 639 Цивільного кодексу України, до договору, 
            укладеного у письмовій формі.
          </li>
          <li><b>1.5. Закон</b> — чинне законодавство України.</li>
          <li>
            <b>1.6. Контент</b> — будь-яке інформаційне наповнення Сайту: елементи дизайну, 
            текст, графічні зображення, ілюстрації, відео, скрипти, програми, музика, звуки 
            та інші об'єкти інформації, їх похідні, різновиди та модифікації.
          </li>
          <li>
            <b>1.7. Обліковий запис</b> — запис в електронних файлах, документах, кодах, 
            мультимедіа компонентах та інших інформаційних ресурсах Сайту, що містить 
            інформацію про Користувача на Сайті, яку
          </li>
          <li>
            <b>1.8. Видалення Облікового запису</b> — деактивація Облікового запису Користувача 
            з одночасним приховуванням особистої інформації Користувача (ім'я, прізвище, по 
            батькові, компанія, вік, контактна інформація) та збереженням Контенту, створеного 
            Користувачем, який є власністю Адміністрації та може бути використаний Адміністрацією 
            для збереження функціоналу його структурної цілісності.
          </li>
          <li>
            <b>1.9. Персональна сторінка</b> — особиста сторінка Користувача на Сайті, що має 
            мережеву адресу виду https://easybase.com.ua/profile.
          </li>
          <li>
            <b>1.10. Пароль</b>— умовне слово або набір знаків і символів, або будь-яке їх поєднання, 
            що підтверджує особистість Користувача та придумане ним для реєстрації на Сайті та 
            подальшого входу на Сайт під своїм Обліковим записом.
          </li>
          <li>
            <b>1.11. Спільнота</b> — сукупність Користувачів сайтів з доменними 
            <a
              href="http://easybase.com.ua/profile"
              target="_blank"
              rel="nofollow"
              >http://easybase.com.ua</a>,
            що пройшли реєстрацію.
          </li>
          <li>
            <b>1.12. Статус Користувача</b> — тип Облікового запису Користувача з певним 
            набором функціоналу Сайту
          </li>
          <li>
            <b>1.13. Ріелтор</b> - фізична особа, яка працює в агентстві нерухомості; 
            Фізична особа - підприємець, який має відповідний КВЕД, що дає право займатися 
            діяльністю у сфері нерухомості.
          </li>
          <li>
            <b>1.13. Тарифи </b> - прайс-лист Адміністрації розміщений за мережевою адресою 
            <a
              href="http://easybase.com.ua/profile"
              target="_blank"
              rel="nofollow"
              >http://easybase.com.ua/profile</a
            >
          </li>
        </ul>
      </li>
      <li>
        <h6><b>2. Предмет Правил</b></h6>
        <ul class="list-group">
          <li>
            <b>2.1.</b> Ці Правила визначають умови використання Сайту Користувачами 
            та надання Адміністрацією доступу до його функціоналу. Крім цих Правил, 
            до договору між Користувачем та Адміністрацією належать усі спеціальні 
            документи та будь-яка текстова чи інша інформація Адміністрації, адресована 
            Користувачам за допомогою Сайту або будь-яким іншим способом, передбаченим Законом.
          </li>
          <li>
            <b> 2.2.</b>  Правила поширюються також на відносини, пов'язані з правами та інтересами 
            третіх осіб, які не є Користувачами Сайту, але чиї права та інтереси можуть бути порушені 
            внаслідок дій Користувача Сайту.
          </li>
          <li>
            <b>2.3.</b> Правила можуть бути змінені або доповнені Адміністрацією в односторонньому 
            порядку без спеціального повідомлення. Правила є відкритим та загальнодоступним 
            документом. Чинна редакція Правил розміщується в мережі Інтернет за адресою
            <a
              href="http://easybase.com.ua/profile"
              target="_blank"
              rel="nofollow"
              >http://easybase.com.ua/profile</a
            >
            Продовження використання Сайту Користувачем після внесення Адміністрацією змін або 
            доповнень до Правил означає прийняття Користувачем та його згоду з такими змінами чи 
            доповненнями. Адміністрація рекомендує Користувачам регулярно перевіряти актуальну 
            редакцію Правил щодо її зміни або доповнення.
          </li>
          <li>
            <b>2.4.</b> Реєстрація Користувача на Сайті означає повне та беззастережне 
            прийняття Користувачем Правил відповідно до ст. 642 Цивільного кодексу України.
          </li>
          <li>
            <b>2.5.</b> Погоджуючись з умовами Правил, Користувач підтверджує свою 
            правоздатність та дієздатність, а також, що він досяг 18-річного віку та 
            усвідомлює відповідальність за зобов'язання, що покладаються на нього умовами Правил.

          </li>
        </ul>
      </li>
      <li>
        <h6><b>3. Реєстрація на Сайті</b></h6>
        <ul class="list-group">
          <li>
            <b>3.1.</b> Реєстрація Користувача на Сайті є безкоштовною та добровільною.
          </li>
          <li>
            <b>3.2.</b> При реєстрації на Сайті Користувач зобов'язаний надати Адміністрації 
            достовірну та актуальну інформацію, необхідну для створення Облікового запису 
            Користувача та формування його Персональної сторінки.
          </li>
          <li>
            <b>3.3.</b> Користувач несе відповідальність за достовірність, актуальність, 
            повноту та відповідність Закону України інформації, наданої ним під час реєстрації, 
            а також чистоту цієї інформації від претензій третіх осіб.
          </li>
          <li>
            <b>3.4.</b> Користувач може зареєструватися на Сайті за допомогою мобільного номера 
            телефону та пароля або через гіперпосилання будь-якого Користувача зі Спільноти.

          </li>
          <li>
            <h6>
              <b>3.5. Реєстрація за допомогою мобільного номера телефону та паролю</b>
            </h6>
            <ul class="list-group">
              <li>
                <b>3.5.1.</b> При реєстрації Користувача за допомогою мобільного номера телефону 
                та Паролю Користувач зобов'язаний шляхом заповнення відповідних полів реєстраційної 
                форми Сайту повідомити Адміністрацію свій мобільний номер телефону та Пароль.
              </li>
              <li>
                <b>3.5.2.</b> Вказані Користувачем мобільний номер телефону та Пароль є необхідною 
                та достатньою інформацією для доступу Користувача до свого Облікового запису. 
                Користувач не має права повідомляти третім особам мобільний номер телефону та Пароль, 
                які використовуються для доступу на Сайт, і несе повну відповідальність за збереження 
                вищевказаної інформації, самостійно обираючи спосіб її зберігання. Користувач на 
                апаратно-програмному забезпеченні, що використовується, може дозволити зберігання 
                мобільного номера телефону та Пароля (з використанням файлів cookies) для подальшої 
                автоматичної авторизації на Сайті.
              </li>
              <li>
                <b>3.5.3.</b> Натискаючи на Сайті кнопку «Зареєструватися», Користувач повністю та 
                беззастережно приймає (акцептує) Правила відповідно до ст. 642 Цивільного кодексу 
                України. Підтвердженням натискання вищезгаданої кнопки є успішна реєстрація Користувача 
                зі створенням його Облікового запису.
              </li>
              <li>
                <b>3.5.4.</b> З метою безпеки, під час реєстрації, Користувач зобов'язаний підтвердити 
                свій мобільний номер телефону, ввівши одноразовий пароль зі смс. При невиконанні 
                Користувачем цієї умови після закінчення 24 годин з моменту реєстрації Користувача на 
                Сайті відбувається Видалення Облікового запису Користувача.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h6><b>4. Персональні дані Користувача</b></h6>
        <ul class="list-group">
          <li>
            <b>4.1. </b> Акцептуючи Правила, Користувач підтверджує свою згоду на збір, 
            використання та обробку Адміністрацією його персональних даних, наданих ним, 
            у тому числі, при реєстрації, а також добровільно розміщених ним на Сайті в 
            ході використання Сайту та будь-яких інших інтернет ресурсах з відкритим доступом.
          </li>
          <li>
            <b>4.2.</b> Обробка персональних даних Користувача, включаючи весь створений 
            Користувачем Контент, здійснюється відповідно до Закону.

          </li>
          <li>
            <b>4.3.</b> Збирання, обробка та використання персональних даних та Контенту 
            Користувача здійснюється Адміністрацією з метою надання Користувачеві доступу 
            до функціоналу Сайту, у тому числі з метою показу Користувачеві персоналізованої 
            (таргетованої) реклами; перевірки, дослідження та аналізу даних, що дозволяють 
            підтримувати та покращувати існуючий, а також розробляти новий функціонал Сайту.
          </li>
          <li>
            <b>4.4.</b> Збір, обробка та використання персональних даних та Контенту 
            Користувача може здійснюватися Адміністрацією з комерційною метою.

          </li>
          <li>
            <b>4.5</b> Адміністрація вживає всіх необхідних заходів для захисту персональних 
            даних Користувача від неправомірного доступу, зміни, розкриття чи знищення. 
            Адміністрація надає доступ до персональних даних Користувача лише тим співробітникам, 
            підрядникам, постачальникам, виконавцям та агентам Адміністрації, яким ця інформація 
            необхідна для забезпечення функціонування Сайту та надання Користувачеві доступу до 
            його використання. Адміністрація має право використовувати надану Користувачем інформацію, 
            у тому числі персональні дані, а також передавати її третім особам, з метою забезпечення 
            дотримання вимог Закону, захисту прав та інтересів Користувачів, Адміністрації, третіх 
            осіб (у тому числі з метою виявлення, перевірки/розслідування та// або припинення 
            протиправних дій). Розкриття наданої Користувачем інформації може бути здійснене лише 
            відповідно до Закону.
          </li>
          <li>
            <b>4.6.</b> Розміщуючи персональні дані та/або створюючи Контент на Сайті, Користувач 
            усвідомлює, що його персональні дані, Контент та Персональна сторінка будуть видні 
            іншим Користувачам.
          </li>
          <li>
            <b>4.7.</b> Користувач погоджується на отримання від Адміністрації за допомогою функціоналу
             Сайту та/або сервісів, будь-яких ресурсів третіх осіб електронних повідомлень, SMS та 
             інших видів розсилок інформаційного, зокрема рекламно-інформаційного змісту, у тому числі 
             від партнерів Адміністрації Сайту.
          </li>
          <li>
            <b>4.8.</b> 
            При реєстрації ви надаєте дозвіл на роботу з файлами cookies на вашому 
            пристрої. В процесі роботи Сайту https://easybase.com.ua (далі - Компанія) 
            може збирати певну інформацію за допомогою кукіс (cookie cookies), у Вашому 
            браузері або мобільному пристрої. Cookies - це невеликі текстові файли, які 
            зберігають інформацію безпосередньо на комп'ютері користувача, його мобільному 
            телефоні або іншому пристрої.
          </li>
        </ul>
      </li>
      <li>
        <p>
          За допомогою cookies веб-сервер може зберегти, наприклад, переваги та 
          налаштування на комп'ютері користувача, його мобільному телефоні або 
          іншому пристрої (ах), який потім автоматично відновлюється під час 
          наступного відвідування. Іншими словами, файли кукіс (cookies) призначені 
          для того, щоб зробити використання Сайту зручнішим, наприклад, щоб Користувач 
          не повторював процес входу до системи під час наступного візиту. Компанія 
          використовує як постійні, так і сесійні cookies.
        </p>
        <p>
          Постійні cookies зберігаються на жорсткому диску комп'ютера або пристрою 
          (що зазвичай залежить від часу зберігання cookies, встановленого за умовчанням). 
          Постійні cookies включають також розміщені сторонніми веб-сайтами - "cookies 
          третіх осіб". Сесійні cookies автоматично видаляються під час закриття вікна браузера.
        </p>
        <p>
          Компанія може дозволяти третім сторонам, таким як постачальники рекламних та/або 
          аналітичних послуг, збирати інформацію, використовуючи cookies безпосередньо на 
          сторінках Сайту або мобільного додатка для збору інформації про те, як Ви використовуєте 
          Сайт, з метою оптимізації Вашого досвіду як користувача, та метою показу реклами поза цим 
          Сайтом. Дані, які вони збирають, підлягають захисту відповідно до чинної політики 
          конфіденційності цих третіх сторін.
        </p>
        <p>
          Залежно від цілей, для яких використовуються cookies, вони зазвичай поділяються на такі 
          категорії: Обов'язкові cookies дозволяють Сам користуватися Сайтом та його основними 
          функціями. Вони зазвичай встановлюються у відповідь на Ваші дії, наприклад, вхід до 
          облікового запису на сайті. Ці файли необхідні для того, щоб скористатися Сайтом.
        </p>
        <p>
          Функціональні cookies використовуються, щоб запам'ятати Вас при повторному відвідуванні 
          Сайту та надавати покращений персоналізований сервіс. Наприклад, Сайт може запросити у 
          вас певну інформацію, або запам'ятати інформацію про Ваш вибір, наприклад, мову або 
          місцезнаходження. Ці cookies збирають інформацію анонімно і не можуть відслідковувати 
          відвідування Вами інших веб-сайтів.
        </p>
        <p>
          Аналітичні та експлуатаційні cookies дозволяють зберігати інформацію про те, як Ви 
          користуєтесь Сайтом та скільки разів заходите на нього. Наприклад, Компанія зберігає 
          інформацію про те, які сторінки Ви відвідуєте найчастіше та отримуєте повідомлення 
          про помилку. Це дозволяє покращувати роботу Сайту, в тому числі забезпечувати швидкий 
          доступ до важливої ​​інформації, яка Вам цікава. Ці cookies служать лише підвищення якості 
          роботи Сайту.
        </p>
        <p>
          Цільові або рекламні cookies створені для того, щоб запам'ятовувати кількість відвідувань 
          Сайту, а також перелік сторінок, які Ви відкриваєте та посилання, якими переходите. Ці cookies 
          створені для того, щоб Ви отримували лише релевантну рекламну інформацію, що відповідає 
          Вашим уподобанням. Вони служать лише для того, щоб обмежити кількість показів рекламних 
          повідомлень, а також допомагають оцінити ефективність рекламних кампаній. Також ми можемо 
          передавати цю інформацію третім особам (наприклад, рекламодавцям) для тих самих цілей.

        </p>
        <p>
          Ви можете будь-якої миті відмовитися від збереження файлів cookies на 
          Вашому пристрої. Для цього дотримуйтесь наведених нижче інструкцій.

        </p>
        <p>
          Зміна налаштувань вашого браузера. Якщо Ви хочете видалити файли cookie, 
          збережені на пристрої, і налаштувати Ваш веб-браузер на відмову від прийняття 
          файлів cookie, Ви можете зробити це в розділі налаштувань вашого браузера. 
          Зазвичай навігаційні параметри, пов'язані з файлами cookie, можна знайти в 
          розділах «Опції», «Інструменти» або «Налаштування» веб-браузера, який Ви 
          використовуєте для доступу до Сайту.
        </p>
        <p>
         Однак, залежно від браузера, який Ви використовуєте, для відключення файлів 
         cookies можуть використовуватися різні способи. Докладнішу інформацію див. 
         за наступними посиланнями:
        </p>
        <ul>
          <ol>
            <a href="https://privacy.microsoft.com/ru-ru/privacystatement"
              >Microsoft Internet Explorer</a
            >
          </ol>
          <ol>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=uk"
              >Google Chrome</a
            >
          </ol>
          <ol>
            <a
              href="https://support.apple.com/kb/PH19214?locale=fr_FR&viewlocale=uk_UA"
              >Safari</a
            >
          </ol>
          <ol>
            <a
              href="https://support.mozilla.org/ru/kb/kuki-informaciya-kotoruyu-veb-sajty-hranyat-na-vas"
              >Firefox</a
            >
          </ol>
          <ol>
            <a href="http://help.opera.com/Windows/10.20/en/cookies.html"
              >Opera</a
            >
          </ol>
        </ul>
        <p>
          Звертаємо Вашу увагу на те, що змінюючи налаштування Вашого браузера та 
          відмовляючись від використання та збереження файлів cookie на Вашому пристрої, 
          Ви можете переглядати Сайт, проте деякі опції або функції можуть не працювати.
        </p>
      </li>
      <!--todo other-->
      <li>
        <h6>
          <b>5. Права та обов'язки Користувача та Адміністрації </b>
        </h6>
        <ul class="list-group">
          <li>
            <h6><b>5.1. Права Користувача</b></h6>
            <ul class="list-group">
              <li>
                <b>5.1.1. </b>
                Користувач має право користуватися функціоналом Сайту в рамках чинних 
                Правил та Закону. Користування платним функціоналом Сайту допускається 
                за умови дотримання всіх умов Правил та повної оплати. Умови оплати 
                платного функціоналу наведені у відповідних розділах Сайту. Користувач 
                може оплатити платний функціонал Сайту будь-яким із передбачених Сайтом способом.
              </li>
              <li>
                <b>5.1.2.</b>
                Користувач має право будь-якої миті відмовитися від виконання умов Правил 
                та від самих Правил шляхом самостійного Видалення Облікового запису на 
                Сайті. Після видалення облікового запису користувач втрачає права доступу 
                до сайту. Обліковий запис видаляється за допомогою кнопки «Видалити обліковий 
                запис» у розділі «Мої налаштування».
              </li>
            </ul>
          </li>
          <li>
            <h6><b>5.2. Обов'язки Користувача</b></h6>
            <ul class="list-group">
              <li>
                <h6>
                  <b>5.2.1.</b> При використанні Сайту Користувач зобов'язаний:
                </h6>
                <ul class="list-group letters-block">
                  <li><b>а)</b> дотримуватися положень Правил та Закону;</li>
                  <li>
                    <b>б)</b>надавати при реєстрації на Сайті достовірні, повні та 
                    актуальні дані, стежити за їхньою актуалізацією;
                  </li>
                  <li>
                    <b>в) </b> інформувати Адміністрацію про несанкціонований 
                    доступ до Облікового запису Користувача та/або про 
                    несанкціонований доступ та/або використання мобільного 
                    номера телефону та Пароля Користувача;
                  </li>
                  <li>
                    <b>г) </b> розміщувати/публікувати на Сайті лише перевірену, 
                    актуальну, правдиву інформацію, включаючи реальні фотографії 
                    та зображення;
                  </li>

                  <li>
                    <b>ґ)</b> не розміщувати на Сайті інформацію (включаючи посилання на неї) 
                    та/або Контент, які можуть порушувати права та інтереси інших осіб;
                  </li>
                  <li>
                    <b>д) </b> зберігати в таємниці та не надавати іншим Користувачам та третім
                     особам, що стали йому відомими персональні дані (у тому числі контактні дані,
                     паспортні дані, банківську інформацію) та інформацію про приватне життя 
                     інших Користувачів та третіх осіб без отримання від них відповідного дозволу;
                  </li>
                  <li>
                    <b>е) </b> не надавати доступ третім особам та іншим Користувачам до свого 
                    Облікового запису, Персональної сторінки або до окремої інформації, що 
                    міститься на ній у випадку, якщо це може призвести до порушення Правил і Закону;
                  </li>
                  <li><b>є)</b>  не дублювати на Сайті інформацію;</li>
                  <li>
                    <b>ж) </b> заповнювати текстові поля та форми Сайту грамотно 
                    та відповідно до призначення/тематики;
                  </li>
                  <li>
                    <b>з) </b>  здійснювати резервне копіювання важливої ​​для Користувача інформації, 
                    що зберігається на Сайті;
                  </li>
                  <li>
                    <b>и) </b> не реєструватися на Сайті від імені або замість іншої особи;
                  </li>
                  <li>
                    <b>і) </b> не використовувати мобільний номер телефону та/або Пароль 
                    інших Користувачів (крім випадків, встановлених Законом)
                  </li>
                  <li>
                    <b>ї)</b> не завантажувати, не створювати, не зберігати, не публікувати/розміщувати, 
                    не розповсюджувати інформацію (включаючи посилання на неї), яка
                    <ul class="list-group square-group">
                      <li>
                        здобуде загрози, дискредитує, ображає, зачіпає честь та гідність чи ділову 
                        репутацію, порушує недоторканність приватного життя інших Користувачів чи 
                        третіх осіб;
                      </li>
                      <li>порушує права неповнолітніх осіб;</li>
                      <li>
                        є вульгарною чи непристойною, містить нецензурну лексику, містить 
                        порнографічні зображення та тексти чи сцени сексуального характеру;
                      </li>
                      <li>містить сцени насильства;</li>
                      <li>
                        містить опис засобів та/або способів суїциду, будь-яке підбурювання 
                        до його вчинення;
                      </li>
                      <li>
                        пропагує та/або сприяє розпалюванню расової, релігійної, етнічної 
                        ненависті чи ворожнечі, пропагує фашизм чи ідеологію расової переваги;
                      </li>
                      <li>містить екстремістські матеріали;</li>
                      <li>
                        пропагує злочинну діяльність або містить поради, інструкції або 
                        посібники щодо вчинення злочинних дій, містить інформацію обмеженого 
                        доступу, включаючи, але не обмежуючись державною та комерційною 
                        таємницею, інформацією про приватне життя третіх осіб;
                      </li>
                      <li>
                        містить рекламу або описує привабливість вживання наркотичних речовин, 
                        у тому числі «цифрових наркотиків» (звукових файлів, що впливають на мозок 
                        людини за рахунок бінауральних ритмів), інформацію про поширення наркотиків, 
                        рецепти їх виготовлення та поради щодо вживання;
                      </li>
                      <li>
                        носить шахрайський характер, а також порушує інші права та інтереси 
                        громадян та юридичних осіб або вимоги Закону;
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>й)</b> не здійснювати масові розсилки повідомлень Користувачам, 
                    використовуючи інші засоби, ніж ті, що надані Адміністрацією через Сайт;
                  </li>
                  <li>
                    <b>к)</b> не використовувати програмне забезпечення та не здійснювати дії, 
                    спрямовані на порушення нормального функціонування Сайту;
                  </li>
                  <li>
                    <b>л)</b> не завантажувати, не зберігати, не публікувати, не розповсюджувати 
                    або іншим чином не використовувати віруси, «трояни» та інші шкідливі 
                    програми (включаючи посилання на них);
                  </li>
                  <li>
                    <b>м)</b>не використовувати, без спеціального дозволу Адміністрації, 
                    автоматизовані скрипти/програми для збору інформації на Сайті 
                    та/або взаємодії з Сайтом;
                  </li>
                  <li>
                    <b>н)</b> будь-яким способом, у тому числі, але не обмежуючись, 
                    шляхом обману, зловживання довірою, злому, не намагатися отримати 
                    доступ за допомогою мобільного номера телефону та/або Пароля до 
                    Облікового запису іншого Користувача;

                  </li>
                  <li>
                    <b>о)</b>не здійснювати незаконні збір та обробку персональних даних інших осіб;
                  </li>
                  <li>
                    <b>п)</b>не здійснювати та не намагатися отримати доступ до будь-якого 
                    функціонала Сайту іншим способом, крім через інтерфейс, наданий 
                    Адміністрацією, за винятком випадків, коли такі дії були прямо 
                    дозволені Користувачеві відповідно до окремої угоди з Адміністрацією;
                  </li>
                  <li>
                    <b>р)</b>не відтворювати, не дублювати, не копіювати, не продавати, 
                    не здійснювати торгові операції та не перепродувати платні функції 
                    Сайту та доступ до них для будь-яких цілей, за винятком випадків, 
                    коли такі дії були прямо дозволені Користувачеві відповідно до 
                    умов окремого угоди з Адміністрацією;
                  </li>
                  <li>
                    <b>с)</b>не розміщувати комерційну та політичну рекламу поза 
                    спеціально розробленими для неї Адміністрацією розділами та функціоналом Сайту;
                  </li>
                  <li>
                    <b>т)</b>не розміщувати будь-яку іншу інформацію, яка, на особистий розсуд 
                    Адміністрації, є небажаною, не відповідає цілям створення Сайту, обмежує 
                    інтереси Користувачів або третіх осіб або з інших причин є небажаною 
                    для розміщення на Сайті.

                  </li>
                </ul>
              </li>
              <li>
                <b>5.2.2. </b> У разі незгоди Користувача з Правилами або їх 
                оновленнями/доповненнями, Користувач зобов'язаний відмовитися 
                від використання Сайту, самостійно видаливши свій Обліковий запис
                 за допомогою кнопки «Видалити обліковий запис», доступної 
                 Користувачеві в розділі «Мої налаштування».
              </li>
            </ul>
          </li>

          <li>
            <h6><b>5.3.</b> Права Адміністрації</h6>
            <ul class="list-group">
              <li>
                <h6><b>5.3.1</b> Адміністрація має право:</h6>
                <ul class="list-group square-group">
                  <li>
                    <b>а) </b>
                    модифікувати, видозмінювати, удосконалювати Сайт на власний розсуд;
                  </li>
                  <li>
                    <b>б)</b>надавати платний та безкоштовний функціонал Користувачам;
                  </li>
                  <li>
                    <b>в)</b>вносити зміни до Правил в односторонньому порядку;
                  </li>
                  <li>
                    <b>г)</b>змінювати ціну на будь-який функціонал Сайту, включаючи будь-які терміни дії/доступу;
                  </li>
                  <li>
                    <b>ґ)</b>зробити безкоштовний функціонал Сайту платним та навпаки;
                  </li>
                  <li>
                    <b>д)</b>редагувати або видаляти інформацію та/або Контент Користувача, якщо вони порушують Правила;
                  </li>
                  <li>
                    <b>е)</b>проводити технічні роботи, пов'язані з підтриманням працездатності Сайту та його удосконаленням;
                  </li>
                  <li>
                    <b>є)</b>розсилати службові повідомлення, корисну інформацію, рекламу, пов'язану з функціоналом Сайту, 
                    на вказаний Користувачем номер телефону та інші контакти;
                  </li>
                  <li>
                    <b>ж)</b>здійснити Видалення Облікового запису Користувача без попередження у випадку, якщо 
                    Користувач не користується своїм Обліковим записом протягом 6 (шости) місяців;
                  </li>
                  <li>
                    <b>з)</b>змінити Статус Користувача на Сайті без згоди Користувача. Зі зміною Статусу 
                    Користувача набір доступного Користувачеві функціоналу на Сайті може змінюватися.
                  </li>
                </ul>
              </li>
              <li>
                <b>5.3.2. </b>Для цілей організації функціонування, технічної підтримки, розвитку 
                Сайту та дотримання Правил, Адміністрація має право вносити зміни до Облікового 
                запису Користувача та редагувати Персональну сторінку Користувача, а також 
                будь-яку іншу інформацію на Сайті.
              </li>
              <li>
                <b>5.3.3.</b>Адміністрація має право надсилати Користувачеві службову, 
                технічну, рекламну та будь-яку іншу інформацію.
              </li>
              <li>
                <b>5.3.4.</b>
                Адміністрація зберігає за собою право в будь-який час змінювати оформлення Сайту, 
                його зміст та функціонал із попереднім повідомленням Користувача або без нього.

              </li>
              <li>
                <b>5.3.5.</b>У разі виявлення факту порушення Користувачем умов Правил або 
                Закону Адміністрація має право заблокувати доступ Користувача до Облікового 
                запису Користувача та/або зробити Видалення Облікового запису Користувача.

              </li>
            </ul>
          </li>
          <li>
            <h6><b>5.4.</b> Обов'язки Адміністрації</h6>
            <ul class="list-group">
              <li>
                <b>5.4.1.</b>Адміністрація зобов'язується робити все можливе для забезпечення 
                якісного та безперервного функціонування Сайту.
              </li>
              <li>
                <b>5.4.2.</b>  Адміністрація зобов'язується оперативно реагувати на звернення 
                Користувачів та своєчасно усувати несправності у функціонуванні Сайту.
              </li>
              <li>
                <b>5.4.3.</b> Адміністрація зобов'язується надавати доступ до функціоналу 
                Сайту в рамках умов Правил та Закону.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h6>
          <b>6. Пункт правил про запис та зберігання розмов </b>
        </h6>
        <ul class="list-group">
          <li>
            <b>6.1</b>
            Мета <br>

            Цей пункт правил встановлює порядок запису та зберігання розмов
            користувачів з адміністрацією месенджера/телефонної лінії.
          </li>
          <li>
            <b>6.2. </b>Запис та зберігання розмов
          </li>
          <li>
              <b>6.2.1. </b>Користувач, реєструючись на платформі, надає 
              адміністрації право записувати та зберігати розмови з ним у месенджерах та телефонних розмовах.
            </li>
          <li>
            <b>6.2.2 </b>Запис та зберігання розмов здійснюється з метою:
            <ul class="list-group square-group">
              <li>покращення якості обслуговування користувачів;</li>
              <li>вирішення спірних питань;</li>
              <li>запобігання шахрайству;</li>
              <li>виконання вимог законодавства України.</li>
            </ul>
          </li>
          <li>
            <b>6.2.3.</b> Розмови зберігаються протягом одного календарного року з моменту їх проведення.
          </li>
          <li>
            <b>6.2.4.</b> Користувач має право на доступ до своїх розмов, записаних адміністрацією.
          </li>
          <li>
            <b>6.2.5.</b> Користувач може вимагати видалення своїх розмов, написавши запит на support@easybase.com.ua
          </li>
          <li>
            <b>6.3. </b> Захист персональних даних
          </li>
          <li>
            <b>6.3.2.</b> Доступ до персональних даних користувачів мають лише уповноважені особи адміністрації.
          </li>
          <li>
            <b>6.4.1.</b> Згода користувача
          </li>
          <li>
            <b>6.5.</b> Внесення змін до правил
          </li>
          <li>
            <b>6.5.1.</b> Адміністрація має право вносити зміни до цих правил.
          </li>
          <li>
            <b>6.5.2. </b>Зміни до правил публікуються на платформі та набувають чинності з моменту їх публікації.
          </li>
          <li>
            <b>6.6.</b>Контактна інформація
          </li>
          <li>
            <b>6.6.1.</b> У разі виникнення будь-яких питань щодо запису та зберігання розмов, 
            користувачі можуть звертатися до адміністрації за адресою support@easybase.com.ua
          </li>
          <li>
            <b>6.7.</b> Прикінцеві положення
          </li>
          <li>
            <b>6.7.1.</b> Ці правила діють з моменту їх публікації на платформі.
          </li>
          <li>
            <b>6.7.2.</b> Ці правила регулюються та тлумачаться відповідно до законодавства України.
          </li>
          <li>
            <b>6.8.</b> Додаткові положення
          </li>
          <li>
            <b>6.8.1.</b> Адміністрація може встановлювати додаткові правила запису та зберігання розмов для певних категорій користувачів або для певних типів розмов.
          </li>
          <li>
            <b>6.8.2.</b> Додаткові правила публікуються на платформі та набувають чинності з моменту їх публікації.
          </li>
        </ul>
      </li>
      <li>
        <h6>
          <b>7. Умови про інтелектуальні права </b>
        </h6>
        <ul class="list-group">
          <li>
            <b>7.1</b>
            Розміщений на Сайті Контент є об'єктом інтелектуальної власності 
            Адміністрації, Користувачів Сайту та інших правовласників.
          </li>
          <li>
            <b>7.2.</b> Користувач, розміщуючи на Сайті належний йому на законних 
            підставах Контент, надає іншим Користувачам Сайту невиключне право 
            на його використання шляхом перегляду, відтворення, копіювання, 
            переробки, роздруківки копій та інших прав з метою особистого використання, 
            крім випадків, коли правовласник Контенту наклав обмеження на використання 
            його Контенту або коли таке використання завдає або може завдати шкоди 
            інтересам правовласника, що охороняється Законом.
          </li>
          <li>
            <b>7.3.</b>Крім свого власного Контенту, Користувач не має права завантажувати 
            або іншим чином доводити до загальної інформації або публікувати/розміщувати на 
            Сайті Контент інших сайтів, баз даних та інші результати інтелектуальної 
            діяльності за відсутності явно вираженої згоди правовласника на такі дії.
          </li>
          <li>
            <b>7.4.</b>Будь-яке використання Сайту або Контенту, крім дозволеної у Правилах 
            або у разі явно вираженої згоди правовласника на таке використання, 
            категорично заборонено.
          </li>
          <li>
            <b>7.5.</b> Користувач несе особисту відповідальність за будь-який Контент 
            або іншу інформацію, яку він завантажує або іншим чином доводить до загальної 
            інформації або публікує/розміщує на Сайті або за його допомогою. Користувач 
            не має права завантажувати, передавати або публікувати/розміщувати Контент 
            на Сайті, якщо він не має відповідних прав на вчинення таких дій, придбаних 
            або переданих йому відповідно до Закону.
          </li>
          <li>
            <b>7.6.</b> Адміністрація може, але не зобов'язана, переглядати Сайт на 
            наявність забороненого Контенту та має право видаляти чи переміщати без 
            попередження будь-який Контент на власний розсуд з будь-якої причини.
          </li>
          <li>
            <b>7.7.</b> Адміністрація має право видаляти або переміщати будь-який Контент, 
            який, на думку Адміністрації, порушує умови Правил, Закону та/або може 
            порушувати права, заподіяти шкоду або загрожувати безпеці інших Користувачів 
            або третіх осіб.
          </li>
          <li>
            <b>7.8.</b>Розміщуючи свій Контент на Сайті, Користувач передає Адміністрації 
            право робити копії такого Контенту з метою упорядкування, полегшення публікації 
            та зберігання на Сайті.
          </li>
          <li>
            <b>7.9.</b>Розміщуючи Контент на Сайті, Користувач автоматично безоплатно 
            надає Адміністрації невиключне право на його використання шляхом копіювання, 
            публічного виконання, відтворення, переробки, перекладу та розповсюдження для 
            цілей Сайту або у зв'язку з ними, у тому числі для його популяризації. Для 
            зазначених цілей Адміністрація може виготовляти похідні твори або вставляти 
            Контент Користувача як складові у відповідні збірники, здійснювати інші дії, 
            що служать досягненню зазначених цілей.
          </li>
          <li>
            <b>7.10.</b> Якщо Користувач видаляє свій Контент із Сайту, права, 
            згадані у п. 6.8. - 6.9. Правил, будуть автоматично відкликані, однак 
            Адміністрація має право зберігати архівні копії Контенту користувача 
            протягом невизначеного терміну.
          </li>
          <li>
            <b>7.11.</b>Треті особи та їх Контент не перевіряються Адміністрацією на 
            відповідність тим чи іншим вимогам (достовірності, повноти, сумлінності 
            тощо). Адміністрація не несе відповідальності за будь-яку інформацію, 
            розміщену на сайтах третіх осіб, доступ до яких Користувач отримує через 
            посилання, розміщені на Сайті.
          </li>
          <li>
            <b>7.12. </b>Розміщені на Сайті посилання або посібники зі скачування файлів 
            та/або встановлення програм третіх осіб не означають підтримки чи схвалення 
            цих дій з боку Адміністрації.
          </li>
          <li>
            <b>7.13.</b>Посилання на будь-який сайт, продукт, послугу, будь-яку інформацію 
            комерційного чи некомерційного характеру, розміщену на Сайті, не є схваленням 
            або рекомендацією цих продуктів/послуг з боку Адміністрації.
          </li>
          <li>
            <b>7.14.</b> Якщо Користувач вирішив залишити Сайт і перейти до сайтів третіх осіб 
            або використовувати або встановити програми третіх осіб, він робить це на свій ризик.
          </li>
        </ul>
      </li>
      <li>
        <h6>
          <b>8. Відповідальність Адміністрації та Користувача</b>
        </h6>
        <ul class="list-group">
          <li>
            <b>8.1.</b>Користувач несе особисту відповідальність за будь-яку інформацію, яку розміщує 
            на Сайті, повідомляє іншим Користувачам, а також за будь-які взаємодії з іншими Користувачами.
          </li>
          <li>
            <b>8.2.</b> Користувач несе відповідальність за власні дії у зв'язку зі створенням та 
            розміщенням інформації на Сайті відповідно до Правил та Закону. Порушення умов Правил 
            та Закону тягне за собою цивільно-правову, адміністративну та кримінальну відповідальність.
          </li>
          <li>
            <b>8.3.</b> Користувач несе відповідальність за чистоту наданої Адміністрації інформації 
            від претензій з боку третіх осіб.
          </li>
          <li>
            <b>8.4.</b>Адміністрація не бере участі у формуванні та створенні Користувачем Контенту, 
            не контролює та не несе відповідальності за дії чи бездіяльність будь-яких осіб щодо 
            використання Сайту або створення, формування та використання Контенту, що створюється 
            на Сайті Користувачами.
          </li>
          <li>
            <b>8.5.</b>Адміністрація не займається попередньою модерацією/перевіркою або 
            цензурою Контенту, створюваного Користувачами, та вживає дій щодо захисту прав 
            та інтересів осіб та забезпечення дотримання вимог Правил та Закону лише після 
            звернення зацікавлених осіб до Адміністрації в установленому порядку.
          </li>
          <li>
            <b>8.6.</b> Адміністрація не несе відповідальності за порушення Користувачем 
            умов Правил та Закону і залишає за собою право змінювати/модерувати або видаляти 
            будь-яку інформацію, що публікується/розміщується Користувачем, включаючи особисті 
            повідомлення, що порушує умови Правил та Закону; зупиняти, обмежувати або припиняти 
            доступ Користувача до всіх або до будь-якого розділу Сайту в будь-який час з будь-якої 
            причини без попереднього повідомлення, не відповідаючи за будь-яку шкоду, яка може 
            бути заподіяна Користувачеві такими діями.
          </li>
          <li>
            <b>8.7.</b> Адміністрація закріплює за собою право зробити Видалення Облікового 
            запису Користувача та/або призупинити/обмежити/припинити доступ Користувача до 
            будь-якого розділу Сайту, якщо виявить, що на її думку, Користувач становить загрозу 
            для Сайту та/або його Користувачів. Адміністрація не несе відповідальності за здійснене 
            відповідно до умов Правил тимчасове блокування або видалення інформації Користувача, 
            Видалення Облікового запису Користувача, його Персональної сторінки або припинення 
            реєстрації Користувача.
          </li>
          <li>
            <b>8.8. </b> Адміністрація не несе відповідальності за тимчасові збої та перерви в 
            роботі Сайту та спричинені ними незручності, втрату інформації. Адміністрація не 
            несе відповідальності за будь-які збитки комп'ютеру Користувача або іншої особи, 
            мобільним пристроям, будь-якому іншому обладнанню або програмному забезпеченню, 
            викликаний або пов'язаний зі скачуванням матеріалів з Сайту або за посиланнями, 
            розміщеними на Сайті.
          </li>
        </ul>
      </li>
      <li>
        <h6>
          <b>9. Заключні положення</b>
        </h6>
        <ul class="list-group">
          <li>
            <b>9.1.</b> Правила визначають умови використання Сайту та замінюють собою 
            усі попередні угоди між Користувачем та Адміністрацією.
          </li>
          <li>
            <b>9.2.</b> Правила регулюються та тлумачаться відповідно до Закону. Питання, 
            які не врегульовані Правилами, підлягають вирішенню відповідно до Закону.
          </li>
          <li>
            <b>9.3.</b> У разі виникнення будь-яких спорів чи розбіжностей, пов'язаних з 
            виконанням умов Правил, Користувач та Адміністрація дозволяють їх шляхом 
            проведення переговорів, а у разі неможливості вирішення спорів шляхом 
            переговорів, спори підлягають вирішенню у порядку, встановленому Законом.
          </li>
          <li>
            <b>9.4.</b>Правила набирають чинності з моменту акцептування їх Користувачем у 
            встановленому Правилами порядку та діють протягом невизначеного строку до 
            дати Видалення Облікового запису Користувача.
          </li>
          <li><b>9.5</b>Правила складено українською мовою.</li>
          <li>
            <b>9.6.</b> Якщо з тих чи інших причин одне або кілька положень/пунктів/умов Правил 
            будуть визнані недійсними або не мають юридичної сили, це не впливає на 
            дійсність чи застосовність інших положень/пунктів/умов Правил.
          </li>
          <li>
            <b>9.7</b> Щодо функціонування та розвитку Сайту Адміністрація керується Правилами та Законом.
          </li>
          <li>
            <b>9.8.</b> В інформаційній системі Сайту та його програмному забезпеченні 
            відсутні технічні рішення, що здійснюють автоматичні цензуру та контроль 
            дій та інформаційних відносин Користувачів щодо використання Сайту.
          </li>
          <li>
            <b>9.9.</b>Всі права на Сайт в цілому, а також на будь-які його частини або фрагменти, 
            у тому числі електронні файли, документи, коди, мультимедіа компоненти та інші 
            інформаційні ресурси, розміщені Адміністрацією або Користувачем на Сайті, а також 
            права на використання мережевої адреси ( доменного імені) 
            <a href="http://easybase.com.ua" target="_blank" rel="nofollow"
              >http://easybase.com.ua</a>
            належать Адміністрації. Адміністрація надає доступ до Сайту 
            всім заінтересованим особам відповідно до Правил та Закону.
          </li>
          <li>
            <b>9.10.</b> Ніякі положення/пункти/умови Правил не надають Користувачеві право 
            на використання фірмової назви, товарних знаків, доменних імен та інших відмітних 
            знаків Адміністрації. Право використання фірмового найменування, товарних знаків, 
            доменних імен та інших відмітних знаків Адміністрації може бути надано виключно 
            за письмовою угодою Адміністрації.
          </li>
          <li>
            <b>9.11. </b> Звернення, пропозиції та претензії фізичних та юридичних осіб 
            до Адміністрації у зв'язку з цими Правилами та всіма питаннями щодо 
            функціонування Сайту, порушеннями прав та інтересів Користувачів та/або 
            третіх осіб при його використанні можуть бути направлені на електронну 
            адресу Адміністрації easybase.com.ua@gmail .com або будь-яким іншим, 
            передбаченим функціоналом Сайту, у спосіб. Запити уповноважених Законом 
            осіб та органів можуть бути направлені на поштову адресу: 51909, Дніпропетровська 
            обл., місто Кам'янське, ВУЛИЦЯ 40 РОКІВ ПОБІГИ, будинок 13, кв 45
          </li>
        </ul>
      </li>
    </ul>

    <div slot="modal-footer">
      <button
        type="button"
        class="btn btn-filter-transparent"
        @click="$bvModal.hide('terms-popup')"
      >
        Закрити
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'TermsConditionsPopup'
};
</script>

<style scoped>
.square-group {
  margin-left: 30px;
}

.letters-block {
  margin-left: 15px;
}
</style>
