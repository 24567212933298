const getDefaultState = () => {
  return {
    form: {
      floor_from: '',
      floor_to: '',
      floors_from: '',
      floors_to: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterNumberOfStoreys(state) {
    return !!(
      state.form.floor_from ||
      state.form.floor_to ||
      state.form.floors_from ||
      state.form.floors_to
    );
  }
};

export const mutations = {
  CHANGE_FLOOR_FROM(state, payload) {
    state.form.floor_from = payload;
  },
  CHANGE_FLOOR_TO(state, payload) {
    state.form.floor_to = payload;
  },
  CHANGE_FLOORS_FROM(state, payload) {
    state.form.floors_from = payload;
  },
  CHANGE_FLOORS_TO(state, payload) {
    state.form.floors_to = payload;
  },
  RESET_FILTER_NUMBER_OF_STOREYS(state) {
    Object.assign(state, getDefaultState());
  }
};
