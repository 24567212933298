/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'partners_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 28.832 28.832',
    data: '<path pid="0" d="M11.98 5.373a2.869 2.869 0 115.738 0 2.869 2.869 0 01-5.738 0zm3.736 10.248c.616-.658 1.479-1.075 2.575-1.245a8.58 8.58 0 01.786-.077c.088-.003.171-.012.259-.012h1.707a3.476 3.476 0 01-2.141-3.203c0-.575.143-1.12.394-1.596-.89-.325-1.711-.32-1.711-.32h-5.753c-.643.015-1.183.096-1.636.23.281.5.442 1.076.442 1.686a3.474 3.474 0 01-2.142 3.203h1.412c.017-.002.033-.002.051-.002.1 0 .209.004.319.012.235.017.492.053.758.107 1.444.303 3.186 1.253 3.379 3.527l.002.051v1.774h.362l.002-1.774c-.002-.025-.052-1.307.935-2.361zm-8.723-1.638a2.87 2.87 0 000-5.74 2.87 2.87 0 000 5.74zm2.735.925H3.976c-4.118.076-3.975 3.103-3.975 3.103L0 25.067h.004c0 .017-.003.035-.003.05 0 .67.545 1.211 1.213 1.211.669 0 1.209-.541 1.209-1.211a.292.292 0 00-.005-.05h.005v-6.532h.758l-.006 6.958 7.266.006-.006-6.996h.786v6.563h.004v.01a1.21 1.21 0 102.418 0V18.01c-.265-3.149-3.915-3.102-3.915-3.102zm12.457-.926a2.87 2.87 0 100-5.74 2.87 2.87 0 000 5.74zm2.736.926h-5.754c-4.119.076-3.973 3.103-3.973 3.103l-.002 7.056h.005c0 .017-.003.035-.003.05 0 .67.544 1.211 1.212 1.211.669 0 1.208-.541 1.208-1.211 0-.016-.003-.033-.004-.05h.004v-6.532h.76l-.009 6.958 7.266.006-.006-6.996h.786v6.563h.002v.01a1.21 1.21 0 102.419 0V18.01c-.262-3.149-3.911-3.102-3.911-3.102z"/>'
  }
})
