<template>
  <div :class="$bem()">
    <div class="landing-container">
      <div :class="$bem('inner')">
        <h2 id="about">Про нас</h2>

        <div :class="$bem('container')">
          <img
            src="@/assets/landing/about-1.jpg"
            alt="about-1"
            loading="lazy"
          />

          <p>
            <span>
              Ми - успішна, молода та амбіційна команда, яка вкладає свої
              зусилля для того, щоб зекономити час рієлторів у пошуку
              нерухомості.
            </span>

            <br />

            <span>
              Наша мета — забезпечити максимально повне й актуальне інформаційне
              поле від усіх учасників ринку нерухомості.
            </span>
          </p>
        </div>

        <template v-if="showMore">
          <div :class="$bem('container')">
            <p :class="$bem('mb-invisible')">
              Ми завжди прагнемо захищати інтереси наших клієнтів та зробити
              ринок нерухомості та рієлторський бізнес ще привабливішими.
              Дослухатися до вас і задовольнити ваші потреби - наш пріоритет. Ми
              пильно слідкуємо за вашими зауваженнями та постійно вдосконалюємо
              наш сайт та інструменти.
            </p>

            <img
              src="@/assets/landing/about-2.jpg"
              alt="about-2"
              loading="lazy"
            />

            <p :class="$bem('dt-invisible')">
              Ми завжди прагнемо захищати інтереси наших клієнтів та зробити
              ринок нерухомості та рієлторський бізнес ще привабливішими.
              Дослухатися до вас і задовольнити ваші потреби - наш пріоритет. Ми
              пильно слідкуємо за вашими зауваженнями та постійно вдосконалюємо
              наш сайт та інструменти.
            </p>
          </div>

          <div :class="$bem('container')">
            <img
              src="@/assets/landing/about-3.jpg"
              alt="about-3"
              loading="lazy"
            />

            <p>
              Разом з нами ви отримуєте доступ до передових рієлторських
              інструментів, які спрощують вашу роботу та підвищують
              ефективність. Ми пишаємось тим, що можемо бути вашими надійними
              партнерами на шляху до успіху.
            </p>
          </div>

          <div :class="$bem('container')">
            <img
              src="@/assets/landing/about-4.jpg"
              alt="about-4"
              loading="lazy"
              :class="$bem('dt-invisible')"
            />

            <p>
              Приєднуйтесь до нас сьогодні та переконуйтесь: ми завжди досягаємо
              найкращих результатів для вас і вашого бізнесу. Разом створюємо
              майбутнє нерухомості!
            </p>

            <img
              src="@/assets/landing/about-4.jpg"
              alt="about-4"
              loading="lazy"
              :class="$bem('mb-invisible')"
            />
          </div>
        </template>

        <button :class="$bem('more')" @click="showMore = !showMore">
          {{ showMore ? 'Показати менше' : 'Показати більше' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'landing-about-screen',
  mixins: [component],
  data() {
    return {
      showMore: false
    };
  }
};
</script>
