const getDefaultState = () => {
  return {
    form: {
      length_from: '',
      length_to: '',
      sortable: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterLandsLength(state) {
    return !!(state.form.length_from || state.form.length_to);
  },
  getSortable(state) {
    return state.form.sortable;
  }
};

export const mutations = {
  CHANGE_FILTER_LANDS_LENGTH_FROM(state, payload) {
    state.form.length_from = payload;
  },
  CHANGE_FILTER_LANDS_LENGTH_TO(state, payload) {
    state.form.length_to = payload;
  },
  CHANGE_SORTABLE(state, payload) {
    state.form.sortable = payload;
  },
  RESET_FILTER_LANDS_LENGTH(state) {
    state.form.length_from = '';
    state.form.length_to = '';
  }
};
