<template>
  <div :class='$bem()'>
    <b-sidebar
      class="custom-sidebar"
      v-model='value'
      :id="id"
      lazy
      no-header='true'
      text-variant='light'
      :right='right'
      shadow>
      <div class="px-3 py-2">
        <slot></slot>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import component from '@/mixins/component';
export default {
  name: 'e-sidebar',
  mixins: [component],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: true
    }
  }
};
</script>