/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M920 430H570V80c0-38.7-31.3-70-70-70-38.6 0-70 31.3-70 70v350H80c-38.6 0-70 31.3-70 70s31.4 70 70 70h350v350c0 38.6 31.4 70 70 70 38.7 0 70-31.4 70-70V570h350c38.7 0 70-31.3 70-70s-31.3-70-70-70z"/>'
  }
})
