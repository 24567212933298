import Vue from 'vue';
import VueRouter from 'vue-router';
import StockPage from '@/pages/StockPage';
import ObjectsPage from '@/pages/ObjectsPage';
import ProfilePage from '@/pages/ProfilePage';
import store from '@/store';
import HomePage from '@/pages/HomePage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';
import ArchivePage from '@/pages/ArchivePage.vue';
import MiddleClientComponent from '@/pages/MiddleClientComponent.vue';
import ClientFormPage from '@/pages/client-form-page';
import manageObjectFormPage from '@/pages/manageObjectFormPage';
import UsefulPage from '@/pages/UsefulPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'home' }
  },
  {
    path: '/logout',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Вхід',
      fullPage: true
    },
    component: LoginPage
  },
  {
    path: '/r/',
    name: 'register',
    meta: {
      title: 'Реєстрація',
      fullPage: true
    },
    component: RegisterPage
  },
  {
    path: '/base',
    name: 'home',
    meta: {
      title: 'База',
      requiresAuth: true
    },
    component: HomePage
  },
  {
    path: '/stock',
    name: 'stock',
    meta: {
      title: 'Акції',
      requiresAuth: true
    },
    component: StockPage
  },
  {
    path: '/useful',
    name: 'useful',
    meta: {
      title: 'Корисне',
      requiresAuth: true
    },
    component: UsefulPage
  },
  {
    path: '/archive',
    name: 'archive',
    meta: {
      title: 'Архів',
      requiresAuth: true
    },
    component: ArchivePage
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: 'Мої пошуки',
      requiresAuth: true
    },
    component: MiddleClientComponent
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'Мої дані',
      requiresAuth: true
    },
    component: ProfilePage
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      title: 'Забув пароль',
      fullPage: true
    },
    component: LoginPage
  },
  {
    path: '/objects',
    name: 'objects',
    meta: {
      title: "Мої об'єкти",
      requiresAuth: true
    },
    component: ObjectsPage
  },
  {
    path: '/client',
    name: 'client',
    meta: {
      title: 'Клієнт',
      requiresAuth: true
    },
    component: ClientFormPage
  },
  {
    path: '/manage-object/:id?',
    name: 'manage-object',
    meta: {
      title: 'Керувати об`єктом',
      requiresAuth: true
    },
    component: manageObjectFormPage
  },
  {
    path: '*',
    meta: {
      title: '404',
      fullPage: true
    },
    component: NotFoundPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title =
    `${to.meta.title} - ${process.env.VUE_APP_TITLE}` ||
    process.env.VUE_APP_TITLE;
  document.body.classList = [`page-${to.name}`];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  }

  next();
});

export default router;
