/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo_add_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M821 127.2H483.1c0-55.9-45.5-101.4-101.4-101.4h-67.6c-55.9 0-101.4 45.5-101.4 101.4H111.4C55.5 127.2 10 172.7 10 228.6v540.7c0 55.9 45.5 101.4 101.4 101.4h441.3c9.6 0 18.8-4.1 25.2-11.3 6.4-7.2 9.5-16.7 8.4-26.3-1-8.9-1.7-17.8-1.7-27 0-149.6 139.6-269 295.6-228.8 10.1 2.5 20.9.4 29.1-6 8.2-6.4 13.1-16.3 13.1-26.7v-316c0-55.9-45.5-101.4-101.4-101.4zM449.3 701.7c-111.8 0-202.8-90.9-202.8-202.8 0-111.8 90.9-202.8 202.8-202.8s202.8 91 202.8 202.9c0 111.8-91 202.7-202.8 202.7zm321-337.9c-28 0-50.7-22.7-50.7-50.7 0-28 22.7-50.7 50.7-50.7s50.7 22.7 50.7 50.7c0 28-22.7 50.7-50.7 50.7z"/><path pid="1" d="M821 636.2c-93.3 0-169 75.6-169 169s75.6 169 169 169c93.3 0 169-75.6 169-169s-75.6-169-169-169zm67.6 200.7h-33.8v33.8c0 18.7-15.1 33.8-33.8 33.8-18.7 0-33.8-15.1-33.8-33.8v-33.8h-33.8c-18.7 0-33.8-15.1-33.8-33.8 0-18.7 15.1-33.8 33.8-33.8h33.8v-33.8c0-18.7 15.1-33.8 33.8-33.8 18.7 0 33.8 15.1 33.8 33.8v33.8h33.8c18.7 0 33.8 15.1 33.8 33.8 0 18.7-15.1 33.8-33.8 33.8z"/>'
  }
})
