/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_icon2': {
    width: 533.333,
    height: 533.334,
    viewBox: '0 0 533.333 533.334',
    data: '<path pid="0" d="M133.333 166.667c0 73.638 59.696 133.333 133.333 133.333C340.304 300 400 240.304 400 166.667S340.305 33.334 266.667 33.334c-73.638-.001-133.334 59.695-133.334 133.333zM400 333.333H133.333C59.696 333.333 0 393.029 0 466.667V500H533.333v-33.333c0-73.638-59.696-133.334-133.333-133.334z"/>'
  }
})
