export default {
  methods: {
    $bem(suffixes = [], root = null, delimiter = '__') {
      const { name, _componentTag } = this.$options;

      return [root || name || _componentTag || '']
        .concat(suffixes || [])
        .filter((row) => row)
        .join(delimiter);
    },
    $hasSlot(names = 'default') {
      return !![]
        .concat(names)
        .filter((name) => !!this.$scopedSlots[name] || !!this.$slots[name])
        .length;
    }
  }
};
