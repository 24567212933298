<template>
  <div class="form-group form-group-filter">
    <e-multiselect
      v-on="$listeners"
      placeholder="Почніть вводити:"
      :close-on-select="false"
      :clear-on-select="false"
      :show-no-results="false"
      label="name"
      track-by="id"
      v-bind="$attrs"
      :show-labels="false"
      :multiple="multiple"
      :options="options"
      @search-change="findResidentialComplex"
    >
      <span slot="noResult">Немає результатів!!!</span>
    </e-multiselect>
  </div>
</template>

<script>
import component from '@/mixins/component';
import eMultiselect from '@/elements/e-multiselect';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'add-object-residential-complex',
  mixins: [component],
  components: { eMultiselect },
  methods: {
    ...mapActions(['handleGetResidentialComplex', 'handleGetBuildings']),
  },
   props: {
    isBuilding: {
      type: Boolean,
      default: false
    },
     multiple: {
       type: Boolean,
       default: false
     }
  },
  mounted() {
    this.handleGetResidentialComplex()
  },
  computed: {
    ...mapGetters(['residentialComplex', 'buildings']),
    options() {
      return this.isBuilding ? this.buildings : this.residentialComplex;
    }
  }
};
</script>
