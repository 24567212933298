/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M990 900.9c0 89.1-89.1 89.1-89.1 89.1L646 735.1c-66.1 48-147.2 76.7-235.1 76.7C189.5 811.8 10 632.3 10 410.9 10 189.5 189.5 10 410.9 10c221.4 0 400.9 179.5 400.9 400.9 0 88-28.7 169-76.7 235.1L990 900.9zM410.9 99.1c-172.2 0-311.8 139.6-311.8 311.8s139.6 311.8 311.8 311.8 311.8-139.6 311.8-311.8c0-172.2-139.6-311.8-311.8-311.8z"/>'
  }
})
