/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon1_1': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M10 500v490h667.24V802.34h83.4V990H990V114.26H489.57V10H10v490zm190.27-312.77v72.98H93.4V114.25h106.86v72.98zm205.9 0v72.98H299.31V114.25h106.86v72.98zm542.13 364.9V948.3H802.34V760.64h-166.8V948.3H489.58V155.96H948.3v396.17zM200.27 395.74v72.98H93.4V322.76h106.86v72.98zm205.9 0v72.98H299.31V322.76h106.86v72.98zm-205.9 208.52v72.98H93.4V531.28h106.86v72.98zm205.9 0v72.98H299.31V531.28h106.86v72.98zm-205.9 208.51v72.98H93.4V739.79h106.86v72.98zm205.9 0v72.98H299.31V739.79h106.86v72.98z"/><path pid="1" d="M135.11 187.23v31.28h23.46v-62.56h-23.46v31.28zM341.01 187.23v31.28h23.46v-62.56h-23.46v31.28zM559.95 278.46v70.37h119.9V208.09h-119.9v70.37zm78.19 1.3V310l-18.77-.78-19.03-.78-.78-29.45-.78-29.19h39.36v29.96zM758.03 278.46v70.37h119.9V208.09h-119.9v70.37zm80.28.52l-.78 29.45-18.77.78-19.03.78v-60.2h39.36l-.78 29.19zM559.95 460.9v70.37h119.9V390.53h-119.9v70.37zm78.19 1.31v30.23l-18.77-.78-19.03-.78-.78-29.45-.78-29.19h39.36v29.97zM758.03 460.9v70.37h119.9V390.53h-119.9v70.37zm80.28.53l-.78 29.45-18.77.78-19.03.78v-60.2h39.36l-.78 29.19zM559.95 643.35v70.37h119.9V572.98h-119.9v70.37zm78.19 1.3v30.23l-18.77-.78-19.03-.78-.78-29.45-.78-29.19h39.36v29.97zM758.03 643.35v70.37h119.9V572.98h-119.9v70.37zm79.5 1.3v28.67l-18.77.78-19.03.78v-60.46l19.03.78 18.77.78v28.67zM135.11 395.74v31.28h23.46v-62.56h-23.46v31.28zM341.01 395.74v31.28h23.46v-62.56h-23.46v31.28zM135.11 604.26v31.28h23.46v-62.56h-23.46v31.28zM341.01 604.26v31.28h23.46v-62.56h-23.46v31.28zM135.11 812.77v31.28h23.46v-62.56h-23.46v31.28zM341.01 812.77v31.28h23.46v-62.56h-23.46v31.28z"/>'
  }
})
