<template>
  <component :is="tag" :class="[$bem(), { 'placeholder-animated': loading }]">
    <slot />
  </component>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 't-text',
  inheritAttrs: false,
  mixins: [component],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'span'
    }
  }
};
</script>
