// eslint-disable-next-line import/no-unresolved
import api from '@/api';

export const state = {
  userMe: {},
  defaultPhoto: '',
  profileData: false,
  subscribe: false,
  showVideo: false
};

export const mutations = {
  USER_ME(state, payload) {
    if (payload.phone) {
      state.defaultPhoto = payload.defaultPhoto;
    } else {
      state.userMe = payload;
    }
  },
  SUBSCRIBES(state, payload) {
    state.subscribe = payload;
  },
  VIDEO(state, payload) {
    state.showVideo = payload;
  },
  PROFILE_DATA(state, payload) {
    state.profileData = payload;
  }
};
export const actions = {
  async handleGetUser({ commit }) {
    try {
      const response = await api.getMe();

      if (response.status === 200) {
        commit('USER_ME', { phone: true, defaultPhoto: response.data.photo });
        commit('USER_ME', response.data);
      }
    } catch (e) {
      console.log('errorMessage', e);
    }
  },
  async handleUpdateUser(context, data) {
    try {
      const response = await api.updateUser(data);

      if (response.status === 200) {
        context.dispatch('handleGetUser');
        context.commit('USER_ME', response.data);
      }
      return response;
    } catch (e) {
      console.log('errorMessage', e);
    }
  }
};
