export function getRouterKey(value) {
  const routers = {
    apts: 'flats',
    flat: 'flats',
    house: 'houses',
    commerce: 'commerces',
    land: 'lands'
  };

  if (value) {
    return routers[value] || value;
  }

  return 'realty';
}
