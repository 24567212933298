/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter_icon': {
    width: 60,
    height: 41,
    viewBox: '0 0 60 41',
    data: '<defs><filter id="flt1"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.161 0 0 0 0 0.671 0 0 0 0 0.729 0 0 0 1 0"/></filter></defs><g id="filter-icon"><path pid="0" id="Rectangle 3" class="shp0" d="M9.5 2.5v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-6c-1.1 0-2 .9-2 2z"/><path pid="1" id="Rectangle 2 copy 3" class="shp1" d="M22.06 9h35.69c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H22.06z"/><path pid="2" id="Rectangle 2" class="shp1" d="M0 6v1c0 1.1.9 2 2 2h6.03V4H2C.9 4 0 4.9 0 6z"/><path pid="3" id="Rectangle 3 copy" class="shp1" d="M40.25 32.5v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-6c-1.1 0-2 .9-2 2z"/><path pid="4" id="Rectangle 2 copy 4" class="shp1" d="M0 36v1c0 1.1.9 2 2 2h35.69v-5H2c-1.1 0-2 .9-2 2zM51.72 39h6.03c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2h-6.03z"/><path pid="5" id="Rectangle 3 copy 2" class="shp1" d="M25.25 17.5v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-6c-1.1 0-2 .9-2 2z"/><path pid="6" id="Rectangle 2 copy 5" class="shp1" d="M0 21v1c0 1.1.9 2 2 2h20.91v-5H2c-1.1 0-2 .9-2 2zM36.59 24h21.16c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2H36.59z"/></g>'
  }
})
