<template>
  <div
    v-bind="$attrs"
    class="union"
  >
    <div v-if="view === 'label'">
      <span class="union-label" :id="tooltipId">
          <img class="union-label-img" :src="logo ? logo : require('@/assets/images/union-image.png')" />
      </span>
      <b-tooltip :target="tooltipId" triggers="hover">
        <slot />
      </b-tooltip>
    </div>
    <div v-else>
      <div class="union-input-holder d-flex">
        <slot />
        <img class="ml-2 union-label-img" :src="logo ? logo : require('@/assets/images/union-image.png')" />
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'union-label',
  mixins: [component],
  props: {
    view: {
      type: String,
      default: 'label'
    },
    logo: {
      type: String,
      default: null
    }
  },
  computed: {
    tooltipId() {
      return `union-${Math.random().toString(36).slice(2, 11)}`;
    }
  }
};
</script>