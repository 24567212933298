<template>
  <div :class="$bem()">
    <div :class="$bem('rating')">
      <img src="@/assets/landing/reviews/star.svg" alt="start" />
      <img src="@/assets/landing/reviews/star.svg" alt="start" />
      <img src="@/assets/landing/reviews/star.svg" alt="start" />
      <img src="@/assets/landing/reviews/star.svg" alt="start" />
      <img src="@/assets/landing/reviews/star.svg" alt="start" />
    </div>
    <div :class="$bem('controls')">
      <img
        src="@/assets/landing/prev.svg"
        alt="prev"
        @click="prev"
        :class="$bem('prev')"
      />
      <img
        src="@/assets/landing/next.svg"
        alt="next"
        @click="next"
        :class="$bem('next')"
      />
    </div>

    <vue-slick-carousel v-bind="settings" ref="slick">
      <div v-for="(slide, index) in slides" :key="index">
        <div :class="$bem('slide')">
          <img
            :src="require(`@/assets/landing/reviews/${index + 1}.jpg`)"
            alt="review"
          />
          <h3 :class="$bem('name')">{{ slide.name }}</h3>
          <p v-if="slide.company" :class="$bem('company')">
            {{ slide.company }}
          </p>
          <p :class="$bem('date')">{{ slide.date }}</p>
          <p :class="$bem('review')">{{ slide.text }}</p>
        </div>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import component from '@/mixins/component';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  mixins: [component],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      slides: [
        {
          name: 'Вячеслав',
          company: 'АН Материк',
          date: '17.05.2023',
          text:
            'Якісний професіональний сервіс, який робить пошук об‘єктів простим і швид-ким, взаємодію з клієнтом максимально ефективною, а співпрацю серед рієлторів стрімкою і результативною. При цьому, за останній час є відчутний прогрес у розвитку ресурсу, і ще існує величезний потенціал до зростання, бо галузь стрімко трансформується у бік цифрових технологій. Тому, хто ще не приєднався - раджу це зробити якомого скоріше і отримати усі переваги! Бажаю розвитку!'
        },
        {
          name: 'Руслана Бабіч',
          company: 'АН ACADEMY',
          date: '17.05.2023',
          text:
            'Велика вдячність за дуже очікуваний професійний ресурс для рієлторів. Особливо цінна мобільна версія. 💪  Працює швидко, реальні об‘єкти, фото, багато ексклюзивів, зручний пошук. Гнучка оплата! Бонуси! Очікуємо інші міста країни. 🫶'
        },
        {
          name: 'Тетяна Блінкова',
          company: 'АН Град',
          date: '10.04.2021',
          text:
            'Большое спасибо. Я хочу ещё поблагодарить вас за вашу базу. Мне она очень нравится. С ней легко и хорошо и в добавок оперативно работать. И в результате пошли задатки. Спасибо большое.'
        },
        {
          name: 'Євген Суділовський',
          company: 'АН Gold Estate',
          date: '18.05.2023',
          text:
            'Це надзвичайно зручний інструмент. Завдяки вам ми відмовились від CRM. Дякую!'
        },
        {
          name: 'Джульєтта',
          company: 'АН Дніпро',
          date: '17.05.2023',
          text:
            'Очень удобно работать! Ведь с Изи всё Изи) Благодаря удобным интсрументам!'
        },
        {
          name: 'Олена',
          company: '',
          date: '17.05.2023',
          text: 'Крута дуже зручна база'
        }
      ],
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    }
  }
};
</script>

<style lang="sass">
.reviews-slider
    padding: 34px 40px
    border-radius: 10px
    background-color: #F5F5F5
    position: relative
    @media screen and (max-width: 991px)
        padding: 34px 16px
    .slick-list
        display: flex !important
        max-height: 700px !important
    &__rating
        display: flex
        justify-content: center
        gap: 8px
        img
            width: 21px
    &__slide
        padding: 15px 0
        text-align: center
        img
            width: 43px
            border-radius: 100%
    &__controls
        display: flex
        position: absolute
        width: calc(100% - 96px)
        margin: auto
        justify-content: space-between
        left: 0
        right: 0
        top: 80px
        z-index: 10
        img
            width: 29px
            cursor: pointer
            transition: .3s ease-in-out
            opacity: .7
            &:hover
                opacity: 1
    &__name
        font-size: 12px
        line-height: 16px
        margin-top: 10px
        margin-bottom: 5px
    &__company
        color: #959595
        font-size: 12px
        line-height: 16px
        margin-bottom: 2px
    &__date
        color: #959595
        font-size: 12px
    &__review
        font-size: 13px
        line-height: 19px
        @media screen and (max-width: 991px)
            text-align: left
</style>
