/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 267.918 267.918',
    data: '<path pid="0" d="M33.713 112.872h13.921v.996H33.713z"/><path pid="1" d="M0 246.655h81.346V21.263H0v225.392zM21.779 41.27h37.788v24.864H21.779V41.27zm0 29.834h37.788v24.864H21.779V71.104zm0 29.835h37.788v24.864H21.779v-24.864zm0 95.469h37.788v37.788H21.779v-37.788z"/><path pid="2" d="M33.713 208.342h13.921v13.921H33.713zM33.713 53.204h13.921v.996H33.713zM33.713 83.038h13.921v.996H33.713zM126.999 112.872h13.921v.996h-13.921zM126.999 53.204h13.921v.996h-13.921zM126.999 83.038h13.921v.996h-13.921zM126.999 208.342h13.921v13.921h-13.921z"/><path pid="3" d="M93.286 246.655h81.352V21.263H93.286v225.392zM115.065 41.27h37.788v24.864h-37.788V41.27zm0 29.834h37.788v24.864h-37.788V71.104zm0 29.835h37.788v24.864h-37.788v-24.864zm0 95.469h37.788v37.788h-37.788v-37.788zM186.572 21.263v225.393h81.346V21.263h-81.346zm59.567 212.934h-37.794v-37.788h37.794v37.788zm0-108.4h-37.794v-24.864h37.794v24.864zm0-29.835h-37.794V71.098h37.794v24.864zm0-29.834h-37.794V41.264h37.794v24.864z"/><path pid="4" d="M220.279 53.204h13.927v.996h-13.927zM220.279 112.872h13.927v.996h-13.927zM220.279 208.342h13.927v13.921h-13.927zM220.279 83.038h13.927v.996h-13.927z"/>'
  }
})
