/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list_add_icon': {
    width: 792,
    height: 792,
    viewBox: '0 0 792 792',
    data: '<path pid="0" d="M216 684h216c0-12.42 1.584-24.48 4.536-36H216v36zM612 792v-72h72v-72h-72v-72h-72v72h-72v72h72v72zM576 540H216v36h264.744c25.38-22.392 58.752-36 95.256-36zM216 216h360v36H216zM216 432h360v36H216z"/><path pid="1" d="M684 158.4L526.896 0H125.46S108 0 108 17.604V774.36C108 792 125.46 792 125.46 792h355.284a145.143 145.143 0 01-29.484-36H144V36h360v108c0 36 36 36 36 36h108v379.26a145.165 145.165 0 0136 29.484V158.4z"/><path pid="2" d="M216 108h216v36H216zM216 324h360v36H216z"/>'
  }
})
