<template>
  <div :class="[$bem(), 'rel']">
    <slot name="activator" v-bind="listeners" />

    <div class="hidden" ref="content">
      <slot />
    </div>
  </div>
</template>

<script>
import componentMixin from '../../mixins/component';

export default {
  name: 't-tooltip-v4',
  inheritAttrs: false,
  mixins: [componentMixin],
  data: ({ disabled, show, hide }) => ({
    container: undefined,
    listeners: !disabled
      ? {
          mouseenter: show,
          touchstart: show,
          focus: show,
          blur: hide
        }
      : {}
  }),
  methods: {
    show() {
      if (!this.container) this.getContainer();
      if (this.container.style.display === 'block') {
        this.hide();
        return;
      }
      if (!document.contains(this.container))
        document.body.appendChild(this.container);

      if (this.container.style.display === 'block') return;

      this.fillContainer();
      this.calculate();
    },
    hide() {
      const { container } = this;

      container.removeAttribute('style');
      container.style.display = 'none';
    },
    calculate() {
      const { $el, container, minWidth, maxWidth } = this;

      container.style.opacity = 0;
      container.style.display = 'block';

      const { top: scrolledHeight, width: bodyWidth } =
        document.body.getBoundingClientRect() || {};
      const { height: containerHeight, width: containerWidth } =
        container.getBoundingClientRect() || {};
      const { top: activatorTop, width: activatorWidth, left: activatorLeft } =
        $el.getBoundingClientRect() || {};

      // count container top
      const topHeight =
        activatorTop - containerHeight - 10 > 10
          ? activatorTop - containerHeight - 10
          : activatorTop + containerHeight;
      const containerTop = Math.abs(scrolledHeight) + topHeight - 30;

      // count container left
      const leftLength =
        activatorLeft + activatorWidth / 2 - containerWidth / 2;
      const leftOffset = leftLength > 0 ? leftLength : 0;
      const rightOffset = leftOffset + containerWidth - bodyWidth;

      const containerLeft =
        leftOffset + containerWidth < bodyWidth
          ? leftOffset
          : leftOffset - rightOffset;

      container.style.top = containerTop.toString().concat('px');
      container.style.left = containerLeft.toString().concat('px');
      container.style.minWidth = minWidth;
      container.style.maxWidth = maxWidth;
      container.style.opacity = 1;
    },
    fillContainer() {
      const { $refs: { content } = {}, container } = this;

      container.textContent = '';
      content.childNodes.forEach((node) =>
        container.appendChild(node.cloneNode(true))
      );
    },
    getContainer() {
      const name = 'tooltip-container';
      this.container =
        document.getElementById(name) || document.createElement('div');

      if (!this.container.id) this.container.id = name;
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: undefined
    },
    maxWidth: {
      type: String,
      default: undefined
    }
  }
};
</script>
