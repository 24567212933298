export const state = {
  activeSection: 'home'
};

export const getters = {
  activeSection: (state) => state.activeSection
};

export const mutations = {
  SET_ACTIVE_SECTION(state, section) {
    state.activeSection = section;
  }
};

export const actions = {
  setActiveSection({ commit }, section) {
    commit('SET_ACTIVE_SECTION', section);
  }
};
