const getDefaultState = () => {
  return {
    form: {
      repair: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterCondition(state) {
    return !!state.form.repair.length;
  }
};

export const mutations = {
  CHANGE_CONDITION(state, payload) {
    state.form.repair = payload;
  },
  RESET_FILTER_CONDITION(state) {
    Object.assign(state, getDefaultState());
  }
};
