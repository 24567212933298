<template>
  <b-modal
    id="profile-data-popup"
    modal-class="modal-add"
    :static="true"
    :no-close-on-backdrop="true"
    v-model="modalProfileData"
    aria-labelledby="profile-data-popup___BV_modal_title_"
  >
    <div slot="modal-header">
      <h4>Вітаю в EasyBase!</h4>
    </div>
    <div slot="default">
      <p class="text-subsc">
        Для початку роботи треба заповнити свої дані, нажміть ОК, щоб перейти до заповнення!
      </p>
    </div>
    <div slot="modal-footer">
      <button
        type="button"
        class="btn btn-filter-transparent"
        @click="redirectProfile"
      >
        ок
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ProfileDataPopup.vue',
  data: () => {
    return {
      modalProfileData: false
    };
  },
  computed: {
    ...mapState({
      userMe: (state) => state.profile.userMe,
      profileData: (state) => state.profile.profileData
    }),
    statusModal() {
      if (this.profileData === true) {
        return this.profileData;
      }
      return false;
    }
  },
  watch: {
    statusModal(newVal) {
      if (newVal === true) {
        this.showModal();
      }
    }
  },

  methods: {
    ...mapMutations(['PROFILE_DATA']),
    redirectProfile() {
      this.PROFILE_DATA(false);
      this.modalProfileData = false;
      this.$store.dispatch('PROFILE_DATA', false);
      if (this.$route.name != 'profile' && this.$route.name != 'login') {
        this.$router.push('/profile');
      }
    },
    showModal() {
      this.modalProfileData = true;
    }
  },
  mounted() {
    this.PROFILE_DATA(false);
  }
};
</script>

<style scoped>
.cabinet-pay-btn {
  width: 100%;
}
.text-subsc {
  text-align: center;
  font-size: 14px;
}
</style>
