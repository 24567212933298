const WATER = [
  { label: 'central', name: 'центральна' },
  { label: 'central_only_cold', name: 'центральна (тільки холодна)' },
  { label: 'well', name: 'свердловина' },
  { label: 'sump', name: 'колодязь' },
  { label: 'on_street', name: 'по вулиці' },
  { label: 'column_in_yard', name: 'колонка у дворі' },
  { label: 'not', name: 'немає' }
];
const GAS = [
  { label: 'there_is', name: 'так' },
  { label: 'gasification_possibility', name: 'можливість газифікувати' },
  { label: 'not', name: 'немає' }
];
const SEWERS = [
  { label: 'central', name: 'центральна' },
  { label: 'local', name: 'місцева' },
  { label: 'not', name: 'немає' }
];
const HEATING = [
  { label: 'central', name: 'центральне' },
  { label: 'autonomous', name: 'автономне' },
  { label: 'gas', name: 'газове' },
  { label: 'electric', name: 'електричне' },
  { label: 'convector', name: 'конвектор' },
  { label: 'solid_fuel_boiler', name: 'твердопаливний котел' },
  { label: 'oil_boiler', name: 'рідкопаливний котел' },
  { label: 'fireplace_or_stove', name: 'камін або пічне' },
  { label: 'not_heating', name: 'без опалення' }
];

export default Object.freeze({
  water_data: WATER,
  gas_data: GAS,
  sewers_data: SEWERS,
  heating_data: HEATING
});
