<template>
  <e-input
    v-model="model"
    label="Літера"
    :show-error-marker="false"
    :required="required"
  />
</template>

<script>
import component from '../../../mixins/component';
import EInput from '../../../elements/inputs/e-input/e-input';
export default {
  name: 'house-letters',
  components: { EInput },
  mixins: [component],
  computed: {
      model: {
        get() {
          return this.value.replace(/[0-9]/g,'');
        },
        set(val) {
          this.$emit('input', val)
        }
      }
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  }
};
</script>
