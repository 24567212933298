var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:[_vm.$bem(), { open: _vm.value, overlay: _vm.overlay && _vm.value }],on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return _vm.close($event)}}},Object.assign({}, _vm.$listeners, {input: _vm.open})),[_vm._t("activator",null,null,{ open: _vm.open, close: _vm.close }),(_vm.value)?_c('div',{ref:"content",class:[
      _vm.$bem('content'),
      { top: _vm.top, bottom: _vm.bottom, left: _vm.left, right: _vm.right, overlay: _vm.overlay && _vm.value, fullscreen: _vm.fullscreen },

      _vm.contentClass
    ],style:({
      top: _vm.top,
      bottom: _vm.bottom,
      left: _vm.left,
      right: _vm.right,
      width: _vm.fullscreen ? undefined : _vm.width,
      height: _vm.fullscreen ? undefined : _vm.height,
      minWidth: _vm.minWidth,
      maxWidth: _vm.maxWidth
    })},[_vm._t("default")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }