/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon8': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M710 10H290c-38.7 0-70 31.3-70 70v840c0 38.7 31.3 70 70 70h420c38.6 0 70-31.3 70-70V80c0-38.7-31.4-70-70-70zM447.5 62.5h105c9.7 0 17.5 7.8 17.5 17.5s-7.8 17.5-17.5 17.5h-105c-9.7 0-17.5-7.8-17.5-17.5s7.8-17.5 17.5-17.5zM500 955c-29.1 0-52.5-23.4-52.5-52.5S470.9 850 500 850s52.5 23.4 52.5 52.5S529.1 955 500 955zm227.5-140h-455V150h455v665zM62.5 500c0-69.8 21.6-136.4 62.5-192.7 8.5-11.8 5.9-28.2-5.8-36.7-11.8-8.5-28.2-5.8-36.7 5.8C35.1 341.7 10 419 10 500s25.1 158.3 72.6 223.7c5.1 7 13.1 10.8 21.3 10.8 5.3 0 10.7-1.6 15.4-5.1 11.7-8.5 14.3-24.9 5.8-36.6-41-56.4-62.6-123-62.6-192.8z"/><path pid="1" d="M154.4 500c0-44 12.1-86.9 35.1-124.1 7.6-12.3 3.8-28.5-8.5-36.1-12.4-7.7-28.5-3.8-36.1 8.5-28.1 45.5-43 98-43 151.7 0 54.3 15.1 107.2 43.8 152.9 5 8 13.5 12.4 22.3 12.4 4.8 0 9.6-1.3 13.9-4 12.3-7.7 16-23.9 8.3-36.2-23.5-37.4-35.8-80.7-35.8-125.1zM917.4 276.4c-8.5-11.6-24.8-14.3-36.7-5.8-11.7 8.5-14.3 25-5.8 36.7 40.9 56.3 62.5 122.9 62.5 192.7 0 69.8-21.6 136.4-62.5 192.8-8.5 11.8-5.9 28.2 5.8 36.6 4.7 3.4 10.1 5.1 15.4 5.1 8.1 0 16.1-3.8 21.3-10.8C964.9 658.3 990 581 990 500s-25.1-158.3-72.6-223.6z"/><path pid="2" d="M855.2 348.3c-7.6-12.3-23.7-16.1-36.1-8.5-12.3 7.6-16.1 23.8-8.5 36.1 23 37.2 35.1 80.1 35.1 124.1 0 44.4-12.4 87.7-35.8 125.1-7.7 12.3-4 28.5 8.3 36.2 4.3 2.7 9.1 4 13.9 4 8.8 0 17.3-4.4 22.3-12.4 28.6-45.7 43.8-98.6 43.8-152.9-.1-53.8-14.9-106.2-43-151.7z"/>'
  }
})
