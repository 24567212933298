<template>
  <div :class="$bem()">
    <button v-b-modal.bot-popup :class="$bem('open-bot-btn')">
      <img
        height="60"
        width="60"
        src="@/assets/icons/bot-btn-new.svg"
        alt="bot-icon"
      />
    </button>

    <b-modal
      id="bot-popup"
      hide-footer
      hide-header
      :static="true"
      :class="$bem()"
    >
      <template #modal-title>
        <h3>My New Modal</h3>
      </template>
      <h3>🌟 Ласкаво просимо до нашого телеграм-бота!</h3>
      <p>
        ✉️ Отримуй повідомлення про нові об'єкти для своїх клієнтів. Просто
        створи запит у базі даних, додай клієнта, і ми будемо надсилати тобі
        актуальну інформацію, поки ти займаєшся своїми справами.
      </p>
      <p>
        📆 Оновлюй дату актуальності об'єкту кожні 7 днів і зберігай базу даних
        упорядкованою. Легко видаляй неактуальні об'єкти в архів.
      </p>
      <p>
        📨 Отримуй повідомлення щодо заявок в архів, щоб підтвердити їх
        актуальність. Крім того, якщо твоє рекламне оголошення на OLX стає
        неактивним, ми повідомимо про його
        видалення.
      </p>
      <p>
        ✅ Авторизуйся в нашому боті та займи перевагу над конкурентами - це
        абсолютно БЕЗКОШТОВНО! 🥳
      </p>
      <a
        href="https://t.me/Easyhata_bot"
        target="_blank"
        :class="$bem('link-img')"
      >
        <img src="@/assets/icons/telegram.svg" alt="telegram" />
        Перейти в телеграм бот
      </a>
      <img
        src="@/assets/landing/close.svg"
        alt="close"
        :class="$bem('close')"
        @click="$bvModal.hide('bot-popup')"
      />
    </b-modal>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  mixins: [component]
};
</script>
