<template>
  <catalog />
</template>

<script>
export default {
  name: 'HomePage',
  components: { Catalog: () => import('@/components/catalog/catalog.vue') }
};
</script>
