const getDefaultState = () => {
  return {
    form: {
      cadastral_number: '',
      sortable: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterCadastralNumber(state) {
    return !!state.form.cadastral_number;
  },
  getSortable(state) {
    return state.form.sortable;
  }
};

export const mutations = {
  CHANGE_FILTER_CADASTRAL_NUMBER(state, payload) {
    state.form.cadastral_number = payload;
  },
  CHANGE_SORTABLE(state, payload) {
    state.form.sortable = payload;
  },
  RESET_FILTER_CADASTRAL_NUMBER(state) {
    state.form.cadastral_number = '';
  }
};
