/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'caret_down': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 5.6L11 16.4 1 5.6h20z"/>'
  }
})
