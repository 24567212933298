/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 7.5a2.5 2.5 0 102.5 2.5A2.5 2.5 0 0010 7.5zm0 7a4.5 4.5 0 114.5-4.5 4.5 4.5 0 01-4.5 4.5zM10 3C3 3 0 10 0 10s3 7 10 7 10-7 10-7-3-7-10-7z"/>'
  }
})
