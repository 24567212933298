<template>
  <div :class="$bem()">
    <div :class="[$bem('inner'), 'row']">
      <e-text tag="h5" :class="$bem('label')">{{ title }}</e-text>
      <slot/>
    </div>

  </div>
</template>

<script>
import component from '../../mixins/component';
import EText from '../../elements/e-text';

export default {
  name: 'context-wrapper',
  mixins: [component],
  components: {
    EText
  },

  props: {
    title: {
      type: String,
      default: 'Name'
    }
  }
};
</script>