/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_icon1': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M778.8 222.6l-88.6 88.6c-48.1-48.1-114.6-77.8-187.9-77.8-146.8 0-265.8 119.1-265.8 265.9v-.1l103.3.1-165.1 178.9L10 499.2h101.1c0-216 175.1-391.2 391.1-391.2 108.1 0 205.8 43.8 276.6 114.6zM990 500.8L824.3 323.1l-164 177.7h103.3v-.1c0 146.9-119 265.9-265.8 265.9-73.4 0-139.9-29.8-187.9-77.8l-88.6 88.6C291.9 848.2 389.7 892 497.7 892c216 0 391.1-175.2 391.1-391.2H990z"/>'
  }
})
