/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon0': {
    width: 510,
    height: 510,
    viewBox: '0 0 510 510',
    data: '<path pid="0" d="M255 0C114.75 0 0 114.75 0 255s114.75 255 255 255 255-114.75 255-255S395.25 0 255 0zm0 306L153 204h204L255 306z"/>'
  }
})
