/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user_plus_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M377.5 714.4c0-144.7 91.8-268.4 220.1-316 15.6-35 24.9-73.9 24.9-112.7 0-152.2 0-275.6-183.8-275.6S255 133.4 255 285.6c0 94.8 55 189.6 122.5 227.6v50.5C169.7 580.7 10 682.8 10 806.3h380.2c-8.2-29.3-12.7-60.1-12.7-91.9z"/><path pid="1" d="M714.4 438.8c-152.2 0-275.6 123.4-275.6 275.6S562.2 990 714.4 990 990 866.6 990 714.4 866.6 438.8 714.4 438.8zM867.5 745H745v122.5h-61.3V745H561.3v-61.3h122.5V561.3H745v122.5h122.5V745z"/>'
  }
})
