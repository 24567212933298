/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon4': {
    width: 16,
    height: 16,
    viewBox: '0 0 445.527 445.527',
    data: '<path pid="0" d="M331.418 32.579l-23.044 23.02 74.815 74.791H117.06v32.564h266.121l-74.791 74.791 23.028 23.06 114.109-114.133zM137.137 207.783l-23.036-23.053L0 298.855l114.101 114.093 23.052-23.028-74.815-74.791h266.13v-32.555H62.346z"/>'
  }
})
