<template>
  <input-wrapper
    :class="$bem()"
    v-bind="{ ...$attrs, showErrorMarker: false }"
    v-on="$listeners"
  >
    <template #input="{ isError }">
      <label :class="[$bem('label'), { '_no-margins': noMargin }]">
        <input
          type="radio"
          :class="[$bem('input'), 'hidden']"
          v-bind="{
            ...$attrs,
            required,
            disabled,
            checked: equal(modelValue, value)
          }"
          v-on="{ ...$listeners, input: (e) => e }"
          @input="
            $emit('input', value);
            $emit('close');
          "
        />
        <span :class="$bem('icon')" />

        <span :class="[$bem('text'), { required }]">
          <span v-if="!!label" v-html="label" />

          <slot v-bind="{ isError }" />
        </span>
      </label>
    </template>
  </input-wrapper>
</template>

<script>
import component from '@/mixins/component';
import { objectEqual as equal } from '@/utils/object-equal';
import inputWrapper from '../input-wrapper';

export default {
  name: 'e-radio',
  inheritAttrs: false,
  mixins: [component],
  components: { inputWrapper },
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  data: () => ({ equal }),
  props: {
    value: undefined,
    modelValue: undefined,
    label: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  }
};
</script>
