<template>
  <div :class="$bem()">
    <e-multiselect
      placeholder="Почніть вводити:"
      :close-on-select="false"
      :clear-on-select="false"
      :show-no-results="false"
      label="name"
      track-by="id"
      :show-labels="false"
      :multiple="false"
      v-on="$listeners"
      v-bind="$attrs"
      :options="[
        ...cities,
        { id: 3, is_city: false, name: 'АР Kрим (скоро)', $isDisabled: true }
      ]"
    >
      <span slot="noResult">Немає результатів!!!</span>
    </e-multiselect>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import component from '../../../mixins/component';
import eMultiselect from '../../../elements/e-multiselect';

export default {
  name: 'add-object-location',
  mixins: [component],
  components: { eMultiselect },
  computed: {
    ...mapGetters(['cities'])
  }
};
</script>
