const getDefaultState = () => {
  return {
    form: {
      is_children_allowed: false,
      is_foreigners_allowed: false,
      is_pets_allowed: false,
      is_seller_pays_the_commission: false,
      is_used_for_advertising: false,
      exclude_unmoderated: false,
      is_cashless_payment_available: false
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterOption(state) {
    return state.form.is_children_allowed
      || state.form.is_foreigners_allowed
      || state.form.is_pets_allowed
      || state.form.is_seller_pays_the_commission
      || state.form.is_used_for_advertising
      || state.form.exclude_unmoderated
      || state.form.is_cashless_payment_available
  }
};

export const mutations = {
  CHANGE_IS_CHILDREN(state, payload) {
    state.form.is_children_allowed = payload;
  },
  CHANGE_FOREIGNERS(state, payload) {
    state.form.is_foreigners_allowed = payload;
  },
  CHANGE_PETS(state, payload) {
    state.form.is_pets_allowed = payload;
  },
  CHANGE_SELLER_PAYS(state, payload) {
    state.form.is_seller_pays_the_commission = payload;
  },
  CHANGE_ADVERTISING(state, payload) {
    state.form.is_used_for_advertising = payload;
  },
  CHANGE_EXCLUDE_UNMODERATED(state, payload) {
    state.form.exclude_unmoderated = payload;
  },
  CHANGE_CASHLESS_PAYMENT(state, payload) {
    state.form.is_cashless_payment_available = payload;
  },
  RESET_FILTER_OPTION(state) {
    Object.assign(state, getDefaultState());
  }
};
