import api from '@/api';

export const state = {
  microdistricts: [],
  districts: [],
  streets: [],
  residentialComplex: [],
  cities: [],
  buildings: []
};

export const getters = {
  cities: state => state.cities,
  districts: state => state.districts,
  microdistricts: state => state.microdistricts,
  streets: state => state.streets,
  residentialComplex: state => state.residentialComplex,
  buildings: state => state.buildings
}

export const mutations = {
  SET(state, {type, value }) {
    state[type] = value
  }
};
export const actions = {
  async handleGetMicroDistrict({ commit }, params) {
    const response = await api.handleGetMicroDistrict(params);

    if (response.status === 200) {

      commit('SET', {type: 'microdistricts', value: response.data});
    }
  },
  async handleGetCities({commit}) {
    const response = await api.getCities();
    if (response.status === 200) {
      commit('SET', {type: 'cities', value: response.data.results});
    }
  },
  async handleGetDistrict({commit}) {
    const response = await api.handleGetDistrict();

    if (response.status === 200) {
      commit('SET', {type: 'districts', value: response.data});
    }
  },
  async handleGetDistrictByCities({commit}, id) {
    const response = await api.getDistrictsByIdCities(id);
    if (response.status === 200) {
      commit('SET', {type: 'districts', value: response.data});
    }
  },
  async handleGetStreet({commit}, params) {
    const response = await api.handleGetStreet(params);

    if (response.status === 200) {
      commit('SET', {type: 'streets', value: response.data.results});
    }
  },
  async handleGetResidentialComplex({ commit }, params) {
    const response = await api.handleGetResidentialComplex(params);
    if (response.status === 200) {
      commit('SET', {type: 'residentialComplex', value: response.data});
    }
  },
  async handleGetBuildings({ commit }, params) {
    const response = await api.getBuildings(params);

    if (response.status === 200) {
      commit('SET', {type: 'buildings', value: response.data.results});
    }
  }
};
