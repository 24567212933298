<template>
  <b-modal
    id="try-free-popup"
    hide-footer
    hide-header
    :static="true"
    :class="$bem()"
  >
    <img
      src="@/assets/landing/close.svg"
      alt="close"
      :class="$bem('close')"
      @click="$bvModal.hide('try-free-popup')"
    />
    <div :class="$bem('content')">
      <div :class="$bem('content-inner')">
        <img
          src="@/assets/landing/try-image.svg"
          alt="try-image"
          :class="$bem('mb-invisible')"
        />
        <div>
          <p>
            Ми закрита система, тільки для рієлторів. Прямої реєстрації на сайті
            немає, Вас можуть запросити колеги, які вже користуються сайтом, або
            надішліть нам номер телефону, і після верифікації Вас, як рієлтора,
            ми надішлемо запрошення!
          </p>
          <div :class="$bem('social')">
            <!-- <a :href="VIBER_LINK" target="_blank">
              <img src="@/assets/landing/viber-white.svg" alt="viber" />
            </a> -->
            <a :href="TELEGRAM_LINK" target="_blank">
              <img src="@/assets/landing/telegram-white.svg" alt="telegram" />
            </a>
          </div>
          <img
            src="@/assets/landing/try-image-mobile.svg"
            alt="try-image"
            :class="$bem('dt-invisible') + ' ' + $bem('mobile-image')"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import component from '@/mixins/component';
import { TELEGRAM_LINK, VIBER_LINK } from '@/helpers/constants';

export default {
  name: 'try-free-popup',
  mixins: [component],
  data() {
    return {
      TELEGRAM_LINK,
      VIBER_LINK
    };
  }
};
</script>
