<template>
  <div
    class="d-flex"
  >
    <union-label
      v-for="union in list"
      :key="union.name"
      class="ml-2"
      :logo="union.logo"
      :view="view"
    >
      Учасник спілки {{ union.name }}
    </union-label>
  </div>
</template>

<script>
import UnionLabel from '@/elements/union-label';
import component from '@/mixins/component';

export default {
  name: 'union-list',
  mixins: [component],
  props: {
    list: {
      type: Array,
      default: []
    },
    view: {
      type: String,
      default: 'label'
    },
  },
  components: {
    UnionLabel
  },
};
</script>