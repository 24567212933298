const getDefaultState = () => {
  return {
    form: {
      square_common_from: '',
      square_common_to: '',
      square_kitchen_from: '',
      square_kitchen_to: '',
      square_area_from: '',
      square_area_to: '',
      square_living_from: '',
      square_living_to: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterSquare(state) {
    return !!(
      state.form.square_common_from ||
      state.form.square_common_to ||
      state.form.square_kitchen_from ||
      state.form.square_kitchen_to ||
      state.form.square_area_from ||
      state.form.square_area_to ||
      state.form.square_living_from ||
      state.form.square_living_to
    );
  }
};

export const mutations = {
  CHANGE_SQUARE_COMMON_FROM(state, payload) {
    state.form.square_common_from = payload;
  },
  CHANGE_SQUARE_COMMON_TO(state, payload) {
    state.form.square_common_to = payload;
  },
  CHANGE_SQUARE_KITCHEN_FROM(state, payload) {
    state.form.square_kitchen_from = payload;
  },
  CHANGE_SQUARE_KITCHEN_TO(state, payload) {
    state.form.square_kitchen_to = payload;
  },
  CHANGE_SQUARE_AREA_FROM(state, payload) {
    state.form.square_area_from = payload;
  },
  CHANGE_SQUARE_AREA_TO(state, payload) {
    state.form.square_area_to = payload;
  },
  CHANGE_SQUARE_LIVING_FROM(state, payload) {
    state.form.square_living_from = payload;
  },
  CHANGE_SQUARE_LIVING_TO(state, payload) {
    state.form.square_living_to = payload;
  },
  RESET_FILTER_SQUARE(state) {
    Object.assign(state, getDefaultState());
  }
};
