/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon6': {
    width: 26.361,
    height: 26.362,
    viewBox: '0 0 26.361 26.362',
    data: '<path pid="0" d="M24.362 0H2a2 2 0 00-2 2v22.36a2 2 0 002 2h22.361a2 2 0 002-2V2c.001-1.104-.894-2-1.999-2zm-6.346 15.182H8.348a2 2 0 010-4h9.667a2 2 0 01.001 4z"/>'
  }
})
