<template>
  <div :class="$bem()">
    <e-input
      v-bind="{ label: 'Додатково' }"
      v-model="selectedAdditions"
      @click="opened = true"
    />
    <e-menu v-bind="{ right: true, top: true }" v-model="opened">
      <div :class="$bem('item')" v-for="(item, i) in options" :key="i" class="w-100 pl-2">
        <e-checkbox v-model="selected" :value="item.value" :label="item.label" class="w-100" />
      </div>
    </e-menu>
  </div>
</template>

<script>
import { objectToArray } from '../../../../../../utils/object-to-array';
import eInput from '../../../../../../elements/inputs/e-input';
import component from '../../../../../../mixins/component';
import eCheckbox from '../../../../../../elements/inputs/e-checkbox';
import eMenu from '../../../../../../elements/e-menu';
import { ADDITIONS } from '@/helpers/constants';
const LABELS_ICON =  [
  'mdi-thumb-up-outline',
  'mdi-thumb-down-outline',
  'mdi-account',
  'mdi-flash-outline',
  'mdi-autorenew',
  'mdi-checkbox-marked',
  'mdi-minus-box',
  'mdi-account',
  'mdi-cellphone-arrow-down',
  'mdi-briefcase'
];

export default {
  name: 'add-object-additions',
  components: { eCheckbox, eInput, eMenu },
  mixins: [component],
  props: {
    value: {
      type: Array,
      required: true
    },
    apt_type: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    opened: false
  }),
  computed: {
    options() {
      const array =  objectToArray(ADDITIONS);
      switch (this.apt_type){
        case 'restaurant_or_cafe_or_bar':
        case 'beauty_object':
        case 'health_object':
        case 'sport_object':
        case 'event_object':
        case 'shop': return [array[0], array[1], array[3]];
        case 'office_room': return [array[0], array[1], array[2], array[3]];
        case 'warehouse_or_hangar': return [array[0], array[3], array[4], array[5]];
        case 'part_of_the_building': 
        case 'detached_building': return [array[0], array[1]];
        case 'recreation_center_or_hotel': return [array[6], array[7], array[8], array[9]];
        case 'industrial_premise': 
        case 'production_object': 
        case 'production_object': 
        case 'free_appointment_premise': return [array[0], array[4]];
        case 'beauty_object':
        case 'health_object':
        case 'sport_object':
        case 'event_object':
        case 'autoservice': return [array[10], array[11], array[12], array[13], array[14]];
      }
    },
    
    selectedAdditions() {
      try{
        return this.selected.map(i => ADDITIONS[i]).join(',')
      }catch(e) {
        return ''
      }
    },
    selected: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
