<template>
  <a class="useful-btn" :href="to" :target="target">
    <slot>Відкрити</slot>
  </a>
</template>

<script>
export default {
  name: 'UsefulBtn',
  props: {
    to: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: () => ''
    }
  }
};
</script>

<style lang="sass" scoped>
.useful-btn
  width: 100%
  max-width: 132px
  padding: 0.875rem 0.5rem
  background-color: var(--color-white-smoke)
  border-radius: var(--border-radius-10)
  text-decoration: none
  font-weight: 600
  font-size: 0.875rem
  font-variant: small-caps
  line-height: 1.4
  text-align: center
  color: var(--color-black-sheep)
  transition: all .3s ease

  &:hover
    background-color: var(--color-aquarius)
    box-shadow: 0 2px 10px 0 var(--color-green-roof-tile)
    color: var(--color-white-smoke)

  &:focus
    outline: none
    background-color: var(--color-aquarius)
    box-shadow: 0 2px 10px 0 var(--color-green-roof-tile)
    color: var(--color-white-smoke)

  &:active
    background-color: var(--color-aquarius)
    box-shadow: 0 2px 10px 0 var(--color-green-roof-tile)
    color: var(--color-white-smoke)
</style>
