<template>
  <div :class="$bem()">
    <img :src="iconSrc" alt="icon" />
    <span v-html="itemText"></span>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'item-component',
  mixins: [component],
  props: {
    iconSrc: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="sass">
.item-component
  display: flex
  align-items: center
  margin-bottom: 20px
  img
    margin-right: 20px
  span
    font-size: 14px
</style>
