const filterAddress = {
  district: 'CHANGE_DISTRICT',
  microdistrict: 'CHANGE_MICRODISTRICT',
  street: 'CHANGE_STREET',
  house_number_even: 'CHANGE_HOUSE_NUMBER_EVEN',
  house_number_odd: 'CHANGE_HOUSE_NUMBER_ODD',
  house_number: 'CHANGE_HOUSE_NUMBER',
  house_letter: 'CHANGE_HOUSE_LETTER',
  house_hull: 'CHANGE_HOUSE_HULL',
  city: 'CHANGE_CITY',
  residential_complex: 'CHANGE_RESIDENTIAL_COMPLEX'
};

const filterType = {
  material_type: 'CHANGE_MATERIAL_TYPE',
  apt_type: 'CHANGE_APT_TYPE',
  rooms: 'CHANGE_ROOMS',
  is_angled: 'CHANGE_ANGLED'
};

const filterNumberOfStoreys = {
  floor_from: 'CHANGE_FLOOR_FROM',
  floor_to: 'CHANGE_FLOOR_TO',
  floors_from: 'CHANGE_FLOORS_FROM',
  floors_to: 'CHANGE_FLOORS_TO'
};

const filterSquare = {
  square_common_from: 'CHANGE_SQUARE_COMMON_FROM',
  square_common_to: 'CHANGE_SQUARE_COMMON_TO',
  square_kitchen_from: 'CHANGE_SQUARE_KITCHEN_FROM',
  square_kitchen_to: 'CHANGE_SQUARE_KITCHEN_TO',
  square_area_from: 'CHANGE_SQUARE_AREA_FROM',
  square_area_to: 'CHANGE_SQUARE_AREA_TO',
  square_living_from: 'CHANGE_SQUARE_LIVING_FROM',
  square_living_to: 'CHANGE_SQUARE_LIVING_TO'
};

const filterCondition = {
  repair: 'CHANGE_CONDITION'
};

const filterCommunication = {
  water: 'CHANGE_WATER',
  gas: 'CHANGE_GAS',
  heating: 'CHANGE_HEATING',
  sewers: 'CHANGE_SEWERS'
};

const filterPrice = {
  price_from: 'CHANGE_PRICE_FROM',
  price_to: 'CHANGE_PRICE_TO',
  sortable: 'CHANGE_SORTABLE'
};

const filterPhone = {
  phone: 'CHANGE_PHONE'
};

const filterLabel = {
  user_label: 'CHANGE_LABELS'
};

const filterDate = {
  updated_from: 'CHANGE_VALUE_FROM',
  updated_to: 'CHANGE_VALUE_TO'
};

const filterSource = {
  source: 'CHANGE_SOURCE'
};

const filterOption = {
  is_children_allowed: 'CHANGE_IS_CHILDREN',
  is_foreigners_allowed: 'CHANGE_FOREIGNERS',
  is_pets_allowed: 'CHANGE_PETS',
  is_seller_pays_the_commission: 'CHANGE_SELLER_PAYS',
  is_used_for_advertising: 'CHANGE_ADVERTISING',
  exclude_unmoderated: 'CHANGE_EXCLUDE_UNMODERATED',
  is_cashless_payment_available: 'CHANGE_CASHLESS_PAYMENT'
};

export default Object.freeze({
  type: 'SET_BUILDING_TYPE',
  business_type: 'SET_BUSINESS_TYPE',
  ...filterAddress,
  ...filterType,
  ...filterNumberOfStoreys,
  ...filterSquare,
  ...filterCondition,
  ...filterCommunication,
  ...filterPrice,
  ...filterPhone,
  ...filterLabel,
  ...filterDate,
  ...filterSource,
  ...filterOption
});
