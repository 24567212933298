import { objectToArray } from '../utils/object-to-array';
import { APT_TYPE_CONSTANTS_DOUBLE, CHOISES_LABEL_OBJECT, HOUSE_TYPES, OBJECT_TYPES } from '../helpers/constants';

const options = (type) =>  {
  switch(type) {
    case 'house': return objectToArray(HOUSE_TYPES);
    case 'flat': return objectToArray(APT_TYPE_CONSTANTS_DOUBLE);
    case 'commerce': return objectToArray(OBJECT_TYPES);
    default: []
  }
};

export const createClientEntity = (data) => {
  const client = {
    id: data.id,
    name: data.client_name,
    phone: data.client_phone,
    comment: data.comment,
    businessType: data.business_type,
    floorFrom: data.floor_from,
    floorTo: data.floor_to,
    floorsFrom: data.floors_from,
    floorsTo: data.floors_to,
    squareAreaFrom: data.square_area_from,
    squareAreaTo: data.square_area_to,
    houseNumber: data.house_number,
    priceFrom: data.price_from,
    priceTo: data.price_to,
    type: data.type,
    city: data.city,
    rooms: data.rooms,
    microdistrict:  data.microdistrict,
    district: data.district,
    street: data.street,
    residentialComplex: data.residential_complex,
    squareCommonFrom: data.square_common_from,
    squareCommonTo: data.square_common_to,
    repair: data.repair,
    exclude_unmoderated: data.exclude_unmoderated,

    is_cashless_payment_available: data.is_cashless_payment_available,
    is_children_allowed: data.is_children_allowed,
    is_foreigners_allowed: data.is_foreigners_allowed,
    is_pets_allowed: data.is_pets_allowed,
    is_seller_pays_the_commission: data.is_seller_pays_the_commission,
    is_used_for_advertising: data.is_used_for_advertising
  }

  client.aptType = options(data.type).filter((item) => {
    return data.apt_type.includes(item.value);
  });

  client.repair = objectToArray(CHOISES_LABEL_OBJECT).filter((item) => {
    return data.repair.includes(item.value);
  });

  return client;
}