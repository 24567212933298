import api from '@/api';

export const actions = {
  async handleAddCommentToShare(context, data) {
    try {
      const response = await api.handleAddCommentToShare(data);
      if (response.status == 200) {
        context.commit('NEW_COMMENT', { ...response.data, ...{ id: data.id } });
      }
    } catch (e) {
      console.log('errorMessage', e);
    }
  }
};
