/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'builds_icon': {
    width: 28.245,
    height: 28.246,
    viewBox: '0 0 28.245 28.246',
    data: '<path pid="0" d="M0 26.365h8.752V8.844H0v17.521zm4.834-15.674H6.63v1.795H4.834v-1.795zm0 2.714H6.63V15.2H4.834v-1.795zm-2.713-2.714h1.795v1.795H2.121v-1.795zm0 2.714h1.795V15.2H2.121v-1.795zM9.747 26.365h8.751V1.88H9.747v24.485zm4.834-22.636h1.797v1.796h-1.797V3.729zm0 2.712h1.797v1.795h-1.797V6.441zm-2.713-2.712h1.795v1.796h-1.795V3.729zm0 2.712h1.795v1.795h-1.795V6.441zM19.493 11.628v14.736h8.752V11.628h-8.752zm3.917 6.356h-1.796v-1.795h1.796v1.795zm0-2.713h-1.796v-1.794h1.796v1.794zm2.714 2.713h-1.797v-1.795h1.797v1.795zm0-2.713h-1.797v-1.794h1.797v1.794z"/>'
  }
})
