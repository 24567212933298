<template>
  <div class="stock-item" :class="{ 'stock-item--fluid': fluid }">
    <img
      :src="item.img"
      alt=""
      width="50"
      height="50"
      class="stock-item__img"
    />

    <h3 class="stock-item__title">
      {{ item.title }}
    </h3>

    <p class="stock-item__description">
      {{ item.description }}
    </p>

    <small v-if="item.disclaimer" class="stock-item__disclaimer">
      *{{ item.disclaimer }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'StockItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    fluid: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
.stock-item
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  margin-bottom: 42px
  border-radius: 20px
  width: 100%
  max-width: 240px
  min-height: 228px
  padding: 30px 20px
  background-color: var(--color-white-smoke)
  color: var(--color-black-sheep)
  font-weight: 300
  text-align: center

  @media (max-width: 768px)
    margin-bottom: 1rem
    max-width: calc(50% - 8px)
    min-height: 240px
    padding: 10px 14px

  &--fluid
    max-width: 100%
    margin-top: 42px

    @media (max-width: 768px)
      margin-top: 1.875rem
      margin-bottom: 1.875rem
      min-height: 100px
      padding-top: 1rem

  &__img
    display: block
    margin: 0 auto
    margin-bottom: 0.5rem
    width: 100%
    max-width: 3.125rem
    height: auto
    object-fit: contain

    @media (max-width: 768px)
      max-width: 2.25rem

  &__title
    font-size: 1.5rem
    font-weight: 300
    line-height: 140%
    font-variant: small-caps

    @media (max-width: 768px)
      font-size: 17px

  &__description
    font-size: 1rem
    line-height: 150%
    text-align: center

  &__disclaimer
    display: block
    font-size: 0.75rem
    font-weight: 300
    line-height: 1.2
</style>
