<template>
  <b-modal
    :class="[$bem(), 'modal-add']"
    id="referral-popup"
    :static="true"
    aria-labelledby="referral-popup___BV_modal_title_"
  >
    <div slot="modal-header" :class="$bem('header')">
      <h5 class="modal-add-title" id="referral-popup___BV_modal_title_">
        Запросити друга
      </h5>
      <div class="circle-close" @click="$bvModal.hide('referral-popup')"></div>
    </div>
    <div :class="[$bem('container'), 'container']">
      <div>
        <p>
          <b :class="$bem('title')"
            >Запроси колегу - отримайте по 50 EasyCoin на рахунок</b>
          <br />
        </p>
        <p>
          Коли твій колега, пройде реєстрацію і оплатить досту до бази, ви 
          обидва отримаєте по 50 EasyCoin на свій рахунок.
        </p>
      </div>

      <div :class="$bem('phone-block')">
        <label :class="$bem('phone-label')">Введіть номер</label>
        <div class="d-flex">
          <div class="d-flex align-items-end block-phone">
            <input
              value="+380"
              type="text"
              disabled
              class="form-input required"
            />
            <input
              v-model="phone"
              type="number"
              :class="[$bem('input'), 'form-input']"
            />
          </div>

          <button
            :disabled="phone.length < 9"
            :class="[{ disabled: phone.length < 9 }, 'btn-filter ml-1']"
            @click="sendPhone(phone)"
          >
            Запросити
          </button>
        </div>
      </div>
    </div>
    <div slot="modal-footer"></div>
  </b-modal>
</template>

<script>
import api from '../../../api';
import component from '../../../mixins/component';
// import component from "@mixins/component"; // прописати аліаси вебпака.
export default {
  name: 'popup-referral',
  mixins: [component],
  data: () => ({
    phone: ''
  }),
  methods: {
    async sendPhone(phone) {
      const data = await api.sendPhoneReferral(`0${phone}`);
      if (data.data.is_sent)
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'success',
          text: 'СМС на номер вашего друга успешно отправлено!'
        });

      this.$bvModal.hide('referral-popup');

      if (data.status !== 200) {
        const errors = Object.values(data.data);
        errors.forEach((message) => {
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: message
          });
        });
      }
    }
  }
};
</script>
