/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon4': {
    width: 16,
    height: 16,
    viewBox: '0 0 289.334 289.334',
    data: '<path pid="0" d="M144.807.002a4.668 4.668 0 00-4.143 2.264l-30 50a4.667 4.667 0 004.002 7.066h25v50.334h-20c-5.454 0-10 4.546-10 10v20H59.333v-25A4.667 4.667 0 0054.665 110l-.135.002a4.665 4.665 0 00-2.264.662l-50 30a4.667 4.667 0 000 8.004l50 30a4.667 4.667 0 007.066-4.002v-25h50.334v20c0 5.454 4.546 10 10 10h20V230h-25a4.667 4.667 0 00-4.002 7.066l30 50a4.667 4.667 0 008.004 0l30-50a4.667 4.667 0 00-4.002-7.066h-25v-50.334h20c5.454 0 10-4.546 10-10v-20H230v25a4.667 4.667 0 007.066 4.002l50-30a4.667 4.667 0 000-8.004l-50-30a4.667 4.667 0 00-7.063 3.817c-.002.062-.004.123-.004.185v25h-50.334v-20c0-5.454-4.546-10-10-10h-20V59.333h25a4.667 4.667 0 004.002-7.066l-30-50a4.66 4.66 0 00-3.86-2.265zm-.14 13.735l21.758 36.264h-43.516l21.758-36.264zm.127 12.402a2.5 2.5 0 00-2.246 1.203l-10 16a2.5 2.5 0 104.238 2.649l10-16a2.5 2.5 0 00-1.992-3.852zm-25.127 93.528h50v50h-50v-50zm-69.666 3.242v43.516l-36.264-21.758 36.264-21.758zm189.332 0l36.264 21.758-36.264 21.758v-43.516zm-112.666 1.258a2.5 2.5 0 00-2.5 2.5v20a2.5 2.5 0 105 .07v-17.571h17.5a2.5 2.5 0 10.07-5h-20.07zm134.023 17.98a2.51 2.51 0 00-1.348.4l-16 10a2.5 2.5 0 102.648 4.239l16-10a2.5 2.5 0 00-1.3-4.639zm-232.121.002a2.5 2.5 0 00-1.227 4.637l16 10a2.5 2.5 0 102.649-4.238l-16-10a2.496 2.496 0 00-1.422-.399zm94.34 97.184h43.516l-21.758 36.264-21.758-36.264zm31.885 2.806a2.5 2.5 0 00-2.246 1.203l-10 16a2.5 2.5 0 104.238 2.649l10-16a2.5 2.5 0 00-1.992-3.852z"/>'
  }
})
