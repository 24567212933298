/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M7 9L5.268 7.484.316 11.729c.18.167.423.271.691.271h11.986c.267 0 .509-.104.688-.271L8.732 7.484 7 9z"/><path pid="1" d="M13.684 2.271A1.007 1.007 0 0012.993 2H1.007c-.267 0-.509.104-.689.273L7 8l6.684-5.729zM0 2.878v8.308l4.833-4.107zM9.167 7.079L14 11.186V2.875z"/>'
  }
})
