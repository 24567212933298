/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon3': {
    width: 475.084,
    height: 475.085,
    viewBox: '0 0 475.084 475.085',
    data: '<path pid="0" d="M362.296 125.055c-2.851-2.853-6.092-4.28-9.706-4.287-.77 0-1.902.193-3.432.572L236.1 149.319 284.917 17.13c.951-1.903 1.431-3.614 1.431-5.139 0-3.234-1.242-6.04-3.713-8.42C280.161 1.188 277.115 0 273.501 0h-93.645c-3.045 0-5.708.855-7.994 2.568-2.284 1.715-3.809 3.905-4.568 6.567l-57.386 235.546c-.953 4.564.284 8.277 3.711 11.135 2.473 2.099 5.421 3.139 8.848 3.139 1.524 0 2.666-.089 3.427-.281l115.914-28.838-56.244 230.691c-.76 3.045-.333 5.899 1.287 8.562 1.619 2.669 4.047 4.476 7.281 5.427 1.903.377 3.239.568 3.999.568 5.52 0 9.514-2.375 11.991-7.136l154.172-330.332c2.095-4.567 1.434-8.756-1.998-12.561z"/>'
  }
})
