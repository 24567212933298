/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon5': {
    width: 459,
    height: 459,
    viewBox: '0 0 459 459',
    data: '<path pid="0" d="M408 0H51C22.95 0 0 22.95 0 51v357c0 28.05 22.95 51 51 51h357c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zM178.5 357L51 229.5l35.7-35.7 91.8 91.8L372.3 91.8l35.7 35.7L178.5 357z"/>'
  }
})
