<template>
  <div class="login-form">
    <div
      v-if="this.$store.getters.authStatus === 'loading'"
      class="container-loading"
    >
      <img :src="require('@/assets/images/loading.gif')" alt="Авторизация..." />
    </div>

    <div class="form-error" v-if="errors.login">{{ errors.login }}</div>
    <form @submit.prevent="login">
      <div class="form-group">
        <div class="form-required-container">
          <icon name="required_icon" class="form-required-icon" />
          <div class="d-flex align-items-end block-phone">
            <input
              v-model="countryCode"
              type="text"
              disabled
              class="form-input required country-code"
            />
            <input
              v-model="username"
              type="number"
              class="form-input required username"
            />
          </div>
        </div>
      </div>
      <div class="input-error" v-if="errors.username">
        {{ errors.username[0] }}
      </div>
      <div class="form-group">
        <div class="form-required-container">
          <icon name="required_icon" class="form-required-icon" />
          <input
            v-model="password"
            type="password"
            placeholder="Пароль"
            class="form-input required"
          />
        </div>
      </div>

      <div class="input-error" v-if="errors.password">
        {{ errors.password[0] }}
      </div>
      <div class="input-error" v-if="errors.non_field_errors">
        {{ errors.non_field_errors[0] }}
      </div>

      <button type="submit" class="btn-filter btn">Увійти</button>
    </form>
    <div class="d-flex justify-content-between bottom-line">
      <!--      <router-link active-class="active" to="/register" class="btn-filter btn btn-router">Реєстрація</router-link>-->
      <router-link to="/reset-password" class="bottom-link">
        Забув/ла пароль
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'LoginForm',
  data() {
    return {
      countryCode: '+380',
      username: '',
      password: '',
      errors: []
    };
  },
  methods: {
    login() {
      this.errors = [];

      this.$store
        .dispatch('login', {
          username: `0${this.username}`.replace(/\s+/g, ''),
          password: this.password
        })
        .then((response) => {
          if (!response.token) {
            this.errors = response.response.data;
            Object.values(this.errors).forEach((val) => {
              Vue.prototype.$notify({ group: 'app', type: 'error', text: val });
            });

            return;
          }

          this.$router.push('/');
        });
    }
  }
};
</script>

<style lang="sass">
.local-errors
  position: absolute
  font-size: 9px
  color: $error_color
.login-form
  padding: 1.5rem
  width: 300px
  position: relative
  overflow: hidden

  box-shadow: 5px 7px 30px rgba(0, 0, 0, 0.38)
  background-color: #fff
  color: #aec0c8
  border-radius: 10px

  .container-loading
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(0, 0, 0, 0.3)
    z-index: 1
    img
      width: 2rem
      height: 2rem

  .form-error
    color: $error_color

  form
    display: flex
    flex-flow: column

    *:not(:last-child)
      margin-bottom: 1rem

    .form-group

      + .input-error
        margin-top: -.8rem
        margin-bottom: .6rem
        font-size: smaller
        color: $error_color

    button
      min-height: 38px
      &:hover
        color: white

  .form-required-container
    .form-input
      min-height: 38px
      padding-left: 20px
      background-color: #fff
      border: 1px solid #308B9D
      color: $body_color
      border-radius: 30px
      &.country-code
        border-right: none
      &.username
        border-left: none
        padding-left: 5px

.btn
  :hover
    color: #fff

.bottom-line
  margin-top: 20px

.btn-filter
  color: white !important

.bottom-link
  color: $menu_color
  width: fit-content
  display: block
  margin: auto
</style>
