/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="15" cy="8" r="4"/><path pid="1" d="M15 14c-6.1 0-8 4-8 4v2h16v-2s-1.9-4-8-4z"/><path pid="2" stroke-miterlimit="10" stroke-width="2" d="M5 7v8M9 11H1"/>'
  }
})
