<template>
  <div class="search-results-counter">
    <span class="search-results-counter__label">{{ label }}</span>

    <div class="search-results-counter__value">{{ count }}/{{ general }}</div>
  </div>
</template>

<script>
export default {
  name: 'SearchResultsCounter',
  props: {
    label: {
      type: String,
      default: 'Знайдено'
    },
    count: {
      type: Number,
      default: 0
    },
    general: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="sass">
.search-results-counter
  display: flex
  align-items: center
  justify-content: flex-start
  gap: 0.625rem
  font-size: 13px
  font-weight: 700
  line-height: 1.2
  color: var(--color-havasupai-falls)

  &__value
    border-radius: var(--border-radius-8)
    padding: 0 7px
    background-color: #12b9c3
    font-size: 0.875rem
    font-weight: 400
    line-height: 1.2
    vertical-align: text-top
    color: var(--color-white)
    overflow: hidden
</style>
