<template>
  <div class="useful-page">
    <MobileHeader
      v-if="isMobile"
      :objectsCount="objects.results.length"
      :totalCount="resultsCount"
    />

    <div class="useful-page__container">
      <div class="useful-page__wrap">
        <ul class="useful-page__list">
          <li v-for="(item, index) in usefulItems" :key="index">
            <UsefulCard :item="item" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ResponsiveMixin from '@/mixins/responsive';
import UsefulCard from '@/components/useful/UsefulCard.vue';
import MobileHeader from '@/components/app/mobile-header/mobile-header.vue';
import { mapState } from 'vuex';

import UsefulItem00Img from '@/assets/images/useful/item-0.webp';
import UsefulItem01Img from '@/assets/images/useful/item-1.webp';
import UsefulItem02Img from '@/assets/images/useful/item-2.webp';
import UsefulItem03Img from '@/assets/images/useful/item-3.webp';
import UsefulItem04Img from '@/assets/images/useful/item-4.webp';

export default {
  name: 'UsefulPage',
  mixins: [ResponsiveMixin],
  components: {
    MobileHeader,
    UsefulCard
  },
  data() {
    return {
      usefulItems: [
        {
          img: UsefulItem00Img,
          title: 'Відео посібник',
          href:
            'https://www.youtube.com/playlist?list=PLzFo6Bgw8Y95fIYS8WA_zhmYJZZt8a-rT',
          target: '_blank'
        },
        {
          img: UsefulItem03Img,
          title: 'Iнновації у нерухомості №1',
          href: 'https://www.youtube.com/watch?v=8CbJBSV96ZI',
          target: '_blank'
        },
        {
          img: UsefulItem04Img,
          title: 'Iнновації у нерухомості №2',
          href: 'https://www.youtube.com/watch?v=YEZ3tUC7O7U',
          target: '_blank'
        },
        {
          img: UsefulItem01Img,
          title: 'Гайд по фото',
          href: '/EasyPhoto.pdf',
          target: '_blank'
        },
        {
          img: UsefulItem02Img,
          title: 'Дія. Освіта',
          href: 'https://osvita.diia.gov.ua/courses/real-estate-agent',
          target: '_blank'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      objects: (state) => state.my_objects.objects
    }),
    resultsCount() {
      return this.objects.count || 0;
    }
  }
};
</script>

<style lang="sass" scoped>
.useful-page
  width: 100%
  flex-grow: 0
  min-width: 0

  &__container
    margin: 0 auto
    flex-grow: 1
    width: 100%
    max-width: 1140px
    min-width: 0
    padding: 0 1rem

  &__wrap
    padding: 112px 0

    @include media-breakpoint-down(md)
      padding: 5rem 0

  &__list
    --col-width: 100%
    --col-gap: 1rem

    display: flex
    flex-wrap: wrap
    justify-content: space-around
    gap: var(--col-gap) 0
    margin: 0 calc(var(--col-gap) / 2 * -1)
    min-width: 0
    list-style: none
    padding-left: 0

    @include media-breakpoint-up(sm)
      --col-width: 50%
      --col-gap: 1.25rem

    @include media-breakpoint-up(lg)
      --col-width: 33.33%
      --col-gap: 1.25rem

    > li
      flex: 0 0 var(--col-width)
      max-width: var(--col-width)
      padding: 0 calc(var(--col-gap) / 2)

  .mobile-header
    top: 0
    right: 0
    z-index: 100
</style>
