/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_istochnic_icon1': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans" d="M5 7c-2.8 0-5 2.2-5 5s2.2 5 5 5h3c1.6 0 3.1-.8 4-2 .9 1.2 2.4 2 4 2h3c2.8 0 5-2.2 5-5s-2.2-5-5-5h-3c-1.6 0-3.1.8-4 2-.9-1.2-2.4-2-4-2H5zm0 2h3c.9 0 1.688.4 2.188 1h3.624c.5-.6 1.288-1 2.188-1h3c1.7 0 3 1.3 3 3s-1.3 3-3 3h-3c-.9 0-1.688-.4-2.188-1h-3.624c-.5.6-1.288 1-2.188 1H5c-1.7 0-3-1.3-3-3s1.3-3 3-3zm2.813 2A1.001 1.001 0 008 13h8a1 1 0 100-2H8a1 1 0 00-.094 0 1.001 1.001 0 00-.093 0z" overflow="visible" font-family="Bitstream Vera Sans"/>'
  }
})
