/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon2': {
    width: 16,
    height: 16,
    viewBox: '0 0 433.5 433.5',
    data: '<path pid="0" d="M153 382.5h127.5v-153H153v153zm-153 0h127.5V51H0v331.5zm306 0h127.5v-153H306v153zM153 51v153h280.5V51H153z"/>'
  }
})
