<template>
  <div :class="[$bem(), 'mr-auto', 'ml-auto']">
    <e-text is="h3" :class="$bem('title')">Додати підписку</e-text>

    <div ref="formContainer" :class="$bem('form-container')">
      <!-- Дані клієнта -->
      <context-wrapper
        :class="['row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Дані клієнта"
      >
        <!-- Ім'я -->
        <div :class="['mt-4', calculateClassForBigComponent]">
          <e-input
            v-model="form.name"
            label="Ім'я"
            v-bind="{ required: true, error: errors.name }"
            :class="{ _error: errors.name }"
          />
        </div>

        <!-- Номер телефону -->
        <div :class="['mt-4', calculateClassForBigComponent]">
          <numeric-input
            v-model="form.phone"
            type="text"
            label="Номер телефону"
          />
        </div>

        <div :class="[calculateClassForFullWidthMobileComponent, 'mt-4']">
          <e-text>
            Авторизуйся у телеграм боті, та отримуй сповіщення про надходження
            нових об'єктів для ваших клієнтів
          </e-text>
        </div>
      </context-wrapper>

      <!-- Адреса -->
      <context-wrapper
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Адреса"
      >
        <!-- Локація -->
        <div :class="[calculateClassForBigComponent, 'mt-4']">
          <location
            v-model="modelCity"
            v-bind="{ title: 'Локація', required: true, error: errors.city }"
            :class="{ _error: errors.city }"
          />
        </div>

        <!-- ЖК -->
        <div :class="[calculateClassForBigComponent, 'mt-4']">
          <residential-complex-select
            v-model="form.residentialComplex"
            v-bind="{ title: 'ЖК', multiple: true }"
            :is-building="false"
          />
        </div>

        <!-- Район -->
        <div :class="[calculateClassForBigComponent, 'mt-4']">
          <district
            v-model="form.district"
            v-bind="{ title: 'Район', multiple: true }"
          />
        </div>

        <!-- Мікрорайон -->
        <div :class="[calculateClassForBigComponent, 'mt-4']">
          <microdistrict
            v-model="form.microdistrict"
            v-bind="{
              title: 'Мікрорайон',
              multiple: true
            }"
          />
        </div>

        <!-- Вулиця -->
        <div :class="[calculateClassForBigComponent, 'mt-4']">
          <streets-select
            v-model="form.street"
            v-bind="{
              title: 'Вулиця',
              multiple: true
            }"
          />
        </div>

        <!-- Номер будинку -->
        <div :class="[calculateClassForBigComponent, 'mt-4']">
          <numeric-input
            v-model.number="form.houseNumber"
            v-bind="{ label: 'Номер будинку' }"
          />
        </div>
      </context-wrapper>

      <!-- Параметри -->
      <context-wrapper
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Параметри"
      >
        <!-- Продам/Здам -->
        <div :class="[calculateClassForMediumComponent, 'mt-4']">
          <business-type
            :required="true"
            :show-error-marker="true"
            v-model="form.businessType"
            v-bind="{ error: errors.businessType }"
          />
        </div>

        <!-- Тип нерухомості -->
        <div :class="[calculateClassForMediumComponent, 'mt-4']">
          <type-real-estate
            v-model="form.type"
            v-bind="{
              required: true,
              error: errors.type,
              isEnabledCommerce: false,
              isEnabledLands: false
            }"
          />
        </div>

        <!-- Тип будинку/квартири -->
        <div v-if="!isLand" :class="[calculateClassForMediumComponent, 'mt-4']">
          <e-multiselect
            v-model="form.aptType"
            label="label"
            track-by="value"
            :options="options"
            multiple
            :title="isHouses ? 'Тип будинку' : 'Тип квартири'"
          >
          </e-multiselect>
        </div>

        <!-- Тип ділянки (Земельні ділянки) -->
        <div v-if="isLand" :class="[calculateClassForMediumComponent, 'mt-4']">
          <e-multiselect
            v-model="form.aptType"
            label="label"
            track-by="value"
            :options="options"
            multiple
            :title="'Тип ділянки'"
          >
          </e-multiselect>
        </div>

        <!-- Кільк. кабінетів/кімнат -->
        <div
          v-if="!isCommerce && !isLand"
          :class="[calculateClassForMediumComponent, 'mt-4']"
        >
          <e-multiselect
            v-model="form.rooms"
            :error="errors.rooms"
            multiple
            required
            :options="['1', '2', '3', '4', '5+']"
            :title="
              form.aptType === 'office_room'
                ? 'Кільк. кабінетів'
                : 'Кільк. кімнат'
            "
          />
        </div>
      </context-wrapper>

      <!-- Додатково -->
      <context-wrapper
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Додатково"
      >
        <!-- Загальна площа від (М²) -->
        <div v-if="!isLand" :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.squareCommonFrom"
            v-bind="{ label: 'Загальна площа від (М²)' }"
          />
        </div>

        <!-- Загальна площа до (М²) -->
        <div v-if="!isLand" :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.squareCommonTo"
            v-bind="{ label: 'Загальна площа до (М²)' }"
          />
        </div>

        <!-- Площа ділянки від (М²) -->
        <div
          v-if="!isApartments"
          :class="['mt-4', calculateClassForMediumComponent]"
        >
          <numeric-input
            v-model.number="form.squareAreaFrom"
            v-bind="{ label: 'Площа ділянки від (М²)' }"
          />
        </div>

        <!-- Площа ділянки до (М²) -->
        <div
          v-if="!isApartments"
          :class="['mt-4', calculateClassForMediumComponent]"
        >
          <numeric-input
            v-model.number="form.squareAreaTo"
            v-bind="{ label: 'Площа ділянки до (М²)' }"
          />
        </div>

        <!-- Поверховість від -->
        <div v-if="!isLand" :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.floorsFrom"
            v-bind="{ label: 'Поверховість від' }"
          />
        </div>

        <!-- Поверховість до -->
        <div v-if="!isLand" :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.floorsTo"
            v-bind="{ label: 'Поверховість до' }"
          />
        </div>

        <!-- Поверх від -->
        <div
          v-if="!isHouses && !isLand"
          :class="['mt-4', calculateClassForMediumComponent]"
        >
          <numeric-input
            v-model.number="form.floorFrom"
            v-bind="{ label: 'Поверх від' }"
          />
        </div>

        <!-- Поверх до -->
        <div v-if="!isLand" :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.floorTo"
            v-bind="{ label: 'Поверх до' }"
          />
        </div>

        <!-- Бюджет від -->
        <div :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.priceFrom"
            v-bind="{ label: 'Бюджет від' }"
          />
        </div>

        <!-- Бюджет до -->
        <div :class="['mt-4', calculateClassForMediumComponent]">
          <numeric-input
            v-model.number="form.priceTo"
            v-bind="{
              label: 'Бюджет до',
              required: true,
              error: errors.priceTo
            }"
            :class="{ _error: errors.priceTo }"
          />
        </div>

        <!-- Стан -->
        <div v-if="!isLand" :class="[calculateClassForMediumComponent, 'mt-4']">
          <e-multiselect
            v-model="form.repair"
            label="label"
            track-by="value"
            :options="repairOptions"
            multiple
            title="Стан"
          >
          </e-multiselect>
        </div>

        <div class="col-12 mt-4">
          <div class="row row-checkboxes">
            <div v-if="isFlatAndRent" class="col-12 col-md-4">
              <!-- item -->
              <div
                id="checkers"
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
              >
                <label class="btn">
                  <input
                    type="checkbox"
                    v-model="form.is_pets_allowed"
                    class="form-input"
                  />
                  <div class="btn-check-border">
                    <icon name="check_icon" class="btn-check-icon" />
                  </div>
                  <span>Можна з тваринами</span>
                  <img
                    src="@/assets/images/allow_pets.svg"
                    alt="Allow pets"
                    class="ml-2"
                    width="20"
                    height="20"
                  />
                </label>
              </div>
              <!-- item -->
              <div
                id="checkers"
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
              >
                <label class="btn">
                  <input
                    type="checkbox"
                    v-model="form.is_children_allowed"
                    class="form-input"
                  />
                  <div class="btn-check-border">
                    <icon name="check_icon" class="btn-check-icon" />
                  </div>
                  <span>Можна з дітьми</span>
                  <img
                    src="@/assets/images/allow_children.svg"
                    alt="Allow children"
                    class="ml-2"
                    width="20"
                    height="20"
                  />
                </label>
              </div>
              <!-- item -->
              <div
                id="checkers"
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
              >
                <label class="btn">
                  <input
                    type="checkbox"
                    v-model="form.is_foreigners_allowed"
                    class="form-input"
                  />
                  <div class="btn-check-border">
                    <icon name="check_icon" class="btn-check-icon" />
                  </div>
                  <span>Беруть іноземців</span>
                  <img
                    src="@/assets/images/allow-foreigners.svg"
                    alt="Allow foreigners"
                    class="ml-2"
                    width="20"
                    height="20"
                  />
                </label>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <!-- item -->
              <div
                id="checkers"
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
              >
                <label class="btn">
                  <input
                    type="checkbox"
                    v-model="form.is_used_for_advertising"
                    class="form-input"
                  />
                  <div class="btn-check-border">
                    <icon name="check_icon" class="btn-check-icon" />
                  </div>
                  <span>Можна використовувати в рекламі</span>
                  <img
                    src="@/assets/images/not_show_without_moderation.svg"
                    alt="Not show without moderation"
                    class="ml-2"
                    width="20"
                    height="20"
                  />
                </label>
              </div>

              <!-- item -->
              <div
                id="checkers"
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
              >
                <label class="btn">
                  <input
                    type="checkbox"
                    v-model="form.is_seller_pays_the_commission"
                    class="form-input"
                  />
                  <div class="btn-check-border">
                    <icon name="check_icon" class="btn-check-icon" />
                  </div>
                  <span>Продавець оплачує комісію</span>
                  <img
                    src="@/assets/images/seller_pays_commissitions.svg"
                    alt="Seller pays commissitions"
                    class="ml-2"
                    width="20"
                    height="20"
                  />
                </label>
              </div>
            </div>

            <div v-if="form.businessType === 'sell'" class="col-12 col-md-4">
              <!-- item -->
              <div
                id="checkers"
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
              >
                <label class="btn">
                  <input
                    type="checkbox"
                    v-model="form.is_cashless_payment_available"
                    class="form-input"
                  />
                  <div class="btn-check-border">
                    <icon name="check_icon" class="btn-check-icon" />
                  </div>
                  <span>Можливий безготівковий розрахунок</span>
                  <img
                    src="@/assets/images/cashless_payments_filter.png"
                    alt="Cashless payments filter"
                    class="ml-2"
                    width="20"
                    height="20"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <e-textarea
            :class="[$bem('comments')]"
            v-model="form.comment"
            label="Коментар"
          />
        </div>
      </context-wrapper>
    </div>

    <div class="row mt-4">
      <div class="flex-center">
        <e-btn
          :disabled="disabledButton"
          :class="['btn-large', $bem('btn-submit'), disabledButton]"
          @click="submit(form)"
          >{{ isEditingClient ? 'Редагувати' : 'Додати' }}</e-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import component from '../mixins/component';

import { objectToArray } from '../utils/object-to-array';
import {
  HOUSE_TYPES,
  APT_TYPE_CONSTANTS_DOUBLE,
  OBJECT_TYPES,
  CHOISES_LABEL_OBJECT
} from '../helpers/constants';

import EText from '../elements/e-text';
import ECounter from '../elements/e-counter';
import ETextarea from '../elements/e-textarea';
import EInput from '../elements/inputs/e-input';
import InputWrapper from '../elements/inputs/input-wrapper';
import NumericInput from '../elements/inputs/numeric-input/numeric-input';
import ResidentialComplexSelect from '../components/address/residential-complex';
import Location from '../components/address/location';
import District from '../components/address/districts';
import Microdistrict from '../components/address/microdistricts';
import StreetsSelect from '../components/address/streets';
import ContextWrapper from '../components/context-wrapper';
import BusinessType from '../components/business-type/business-type';
import TypeRealEstate from '../components/estate/type-real-estate/type-real-estate';
import TypeApartments from '../components/estate/type-apartments';
import EBtn from '../elements/buttons/e-btn/e-btn';
import EMultiselect from '../elements/e-multiselect/e-multiselect';
import { clientValidate } from '../components/popups/add-object-popup/v2/helpers';
import { mapActions, mapState } from 'vuex';
import eCheckbox from '@/elements/inputs/e-checkbox';

const initialForm = {
  type: 'flat',
  aptType: [],
  city: null,
  district: [],
  microdistrict: [],
  street: [],
  name: '',
  comment: '',
  phone: null,
  rooms: [],
  houseNumber: null,
  residentialComplex: [],
  businessType: 'sell',
  squareCommonFrom: null,
  squareCommonTo: null,
  squareAreaFrom: null,
  squareAreaTo: null,
  floorsFrom: null,
  floorsTo: null,
  floorFrom: null,
  floorTo: null,
  priceFrom: null,
  priceTo: null,
  repair: [],
  is_pets_allowed: false,
  is_children_allowed: false,
  is_foreigners_allowed: false,
  is_used_for_advertising: false,
  is_seller_pays_the_commission: false,
  is_cashless_payment_available: false
};

export default {
  name: 'client-form-page',
  mixins: [component],
  components: {
    EMultiselect,
    ECounter,
    EBtn,
    TypeRealEstate,
    BusinessType,
    ContextWrapper,
    Microdistrict,
    District,
    ResidentialComplexSelect,
    StreetsSelect,
    NumericInput,
    InputWrapper,
    Location,
    EText,
    EInput,
    ETextarea,
    TypeApartments,
    eCheckbox
  },
  data() {
    return {
      isError: false,
      loading: false,
      form: {
        ...initialForm
      },
      errors: {
        name: '',
        city: '',
        priceTo: '',
        type: '',
        rooms: '',
        businessType: '',
        aptType: ''
      },
      isEditingClient: false,
      disabledButtonSubmit: false,
      formContainerEl: null
    };
  },
  computed: {
    isHouses: ({ form }) => form.type === 'house',
    isApartments: ({ form }) => form.type === 'flat',
    isCommerce: ({ form }) => form.type === 'commerce',
    calculateClassForBigComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-12 col-sm-6 col-md-4' : 'col-12',
    calculateClassForFullWidthMobileComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-12 col-sm-6 col-md-4' : 'col-12',
    calculateClassForMediumComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-12 col-sm-6 col-lg-3' : 'col-12',
    ...mapState({
      activeClient: (state) => state.clients.activeClient,
      microdistricts: (state) => state.addresses.microdistricts,
      districts: (state) => state.addresses.districts,
      streets: (state) => state.addresses.streets,
      residentialComplex: (state) => state.addresses.residentialComplex,
      cities: (state) => state.addresses.cities
    }),
    isFlatAndRent() {
      return this.form.type === 'flat' && this.form.businessType === 'rent';
    },
    modelCity: {
      get() {
        return this.form.city;
      },
      set(val) {
        this.form.city = val;
        this.handleGetDistrictByCities(val.id);
      }
    },
    options() {
      switch (this.form.type) {
        case 'house':
          return objectToArray(HOUSE_TYPES);
        case 'flat':
          return objectToArray(APT_TYPE_CONSTANTS_DOUBLE);
        case 'commerce':
          return objectToArray(OBJECT_TYPES);
        case 'lands':
          return objectToArray(LAND_TYPES);
        default:
          [];
      }
    },
    repairOptions() {
      return objectToArray(CHOISES_LABEL_OBJECT);
    },
    disabledButton() {
      return this.disabledButtonSubmit ? 'disabled' : false;
    },
    requiredFields() {
      const baseRequiredFields = {
        name: '',
        city: '',
        priceTo: '',
        type: '',
        businessType: null
      };

      if (this.isApartments) {
        return {
          ...baseRequiredFields,
          rooms: ''
        };
      }

      if (this.isCommerce) {
        return {
          ...baseRequiredFields,
          aptType: ''
        };
      }

      return baseRequiredFields;
    },
    isRentFlat() {
      return this.form.type === 'flat' && this.form.businessType === 'rent';
    },
    typeClient() {
      return this.form.type;
    }
  },
  methods: {
    ...mapActions([
      'handleGetDistrictByCities',
      'handleCreateClient',
      'handleEditClient'
    ]),
    async submit() {
      this.errors = clientValidate(this.form, this.requiredFields);
      this.isError = this.isEmptyError(this.errors);
      if (!this.isError) {
        this.disabledButtonSubmit = true;
        if (this.isEditingClient) {
          await this.submitEditingClient();
        } else {
          await this.submitCreateClient();
        }
        this.disabledButtonSubmit = false;
      } else {
        this.scrollToErrorsFields();
      }
    },

    async submitEditingClient() {
      try {
        const response = await this.handleEditClient(this.form);
        if (response.status === 200) {
          this.$router.push('/search');
        }
      } catch ({ response }) {
        Object.values(response.data).forEach((val) => {
          this.$notify({ group: 'app', type: 'error', text: val });
        });
      }
    },
    async submitCreateClient() {
      try {
        const response = await this.handleCreateClient(this.form);
        if (response.status === 201) {
          this.$router.push('/search');
        }
      } catch ({ response }) {
        Object.values(response.data).forEach((val) => {
          this.$notify({ group: 'app', type: 'error', text: val });
        });
      }
    },
    isEmptyError(errors) {
      return !!Object.values(errors).filter((a) => a?.length).length;
    },

    scrollToErrorsFields() {
      if (!this.formContainerEl) return;

      setTimeout(() => {
        const firstErrorField = this.formContainerEl.querySelector('._error');
        if (firstErrorField) {
          firstErrorField.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 100);
    }
  },
  watch: {
    typeClient(newValue, oldValue) {
      if (newValue !== oldValue) {
        // this.form.aptType = [];
      }
    },

    form: {
      handler(newValue, oldValue) {
        if (!this.isEditingClient) {
          if (this.isError) {
            this.errors = clientValidate(this.form, this.requiredFields);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.activeClient) {
      this.isEditingClient = true;
      this.form = JSON.parse(JSON.stringify(this.activeClient));
    } else {
      this.form = { ...initialForm };
    }

    this.formContainerEl = this.$refs.formContainer;
  }
};
</script>

<style lang="sass" src="../assets/styles/pages/client-form-page.sass" />
