/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 490 490',
    data: '<path pid="0" d="M.1 0H490v87.9H.1zM0 112.4V490h490V112.4H0zm116.7 316.9H60.3v-64.9h56.4v64.9zm0-101.9H60.3v-64.9h56.4v64.9zm0-101.5H60.3v-65.3h56.4v65.3zm313 175.4h-245c-7 0-12.4-5.4-12.4-12.4s5.4-12.4 12.4-12.4h244.9c7 0 12.4 5.4 12.4 12.4s-5.3 12.4-12.3 12.4zm0-93.7h-245c-7 0-12.4-5.4-12.4-12.4s5.4-12.4 12.4-12.4h244.9c7 0 12.4 5.4 12.4 12.4s-5.3 12.4-12.3 12.4zm0-94.1h-245c-7 0-12.4-5.4-12.4-12.4s5.4-12.4 12.4-12.4h244.9c7 0 12.4 5.4 12.4 12.4s-5.3 12.4-12.3 12.4z"/>'
  }
})
