/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'build_add_icon': {
    width: 93.565,
    height: 93.565,
    viewBox: '0 0 93.565 93.565',
    data: '<path pid="0" d="M80.889 11.341v27.31H13.593v52.664h79.972V11.341H80.889zm7.039 65.18h-8.879v8.88h-6.316v-8.88h-8.885v-6.315h8.885v-8.884h6.314v8.884h8.881v6.315z"/><path pid="1" d="M74.719 2.25H0v30.014h74.719V2.25zm-28.687 5v20.014h-18V7.25h18zM5 7.25h18.032v20.014H5V7.25zm64.719 20.013H51.032V7.25h18.687v20.013z"/>'
  }
})
