/**
 * Building filters string url
 * @param {string} payload
 * @returns {string}
 */
export const requestParamsGenerateUrl = (payload) => {
  let url = '';
  const template = (field, value) => `&${field}=${value}`;
  const map = {
    number: {
      checkLength: false,
      fn: (payload, property) => template(property, payload[property])
    },
    boolean: {
      checkLength: false,
      fn: (payload, property) => template(property, payload[property])
    },
    string: {
      checkLength: true,
      fn: (payload, property) => template(property, payload[property])
    },
    object: {
      checkLength: true,
      fn: (payload, property) => {
        const key =
          ['repair', 'gas', 'heating', 'sewers', 'water'].indexOf(property) ===
          -1
            ? 'id'
            : 'label';

        if (typeof payload[property][0] === 'object') {
          return template(
            property,
            payload[property].map((i) => i[key]).join(',')
          );
        }

        return template(property, payload[property].join(','));
      }
    }
  };

  for (const property in payload) {
    const instance = map[typeof payload[property]];

    if (instance.checkLength && !payload[property]?.length) {
      // string, array
      continue;
    }

    if (!payload[property]) {
      // boolean
      continue;
    }

    url += instance.fn(payload, property);
  }

  return url;
};
