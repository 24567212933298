<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    :class="[
      $bem(),
      size ? '_size-' + size : '',
      color ? '_' + color : '',
      { _icon: icon }
    ]"
  >
    <slot />
  </button>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'e-btn',
  mixins: [component],
  props: {
    size: {
      // size - xs = 32, s = 40px, m = 64px, l = 56px, xl = 72px
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      // colors - primary, white
      type: String,
      default: ''
    }
  }
};
</script>
