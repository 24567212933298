const getDefaultState = () => {
  return {
    form: {
      phone: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterPhone(state) {
    return !!state.form.phone.length;
  }
};

export const mutations = {
  CHANGE_PHONE(state, payload) {
    state.form.phone = payload;
  },
  RESET_FILTER_PHONE(state) {
    Object.assign(state, getDefaultState());
  }
};
