<template>
  <div class="login-form reset-page">
    <div
      v-if="this.$store.getters.authStatus === 'loading'"
      class="container-loading"
    >
      <img :src="require('@/assets/images/loading.gif')" alt="Авторизация..." />
    </div>

    <form class="form-register" @submit.prevent="resetPassword">
      <div class="form-group">
        <!-- Write code -->
        <div class="form-required-container" style="margin-bottom: 0">
          <icon name="required_icon" class="form-required-icon" />
          <div
            class="d-flex align-items-end block-phone"
            v-if="!statusPhone.phone"
          >
            <input
              v-model="countryCode"
              class="form-input required country-code"
              type="text"
              disabled
            />
            <input
              v-model="phone"
              type="text"
              class="form-input required reset-input"
            />
          </div>
        </div>

        <div v-if="!statusPhone.phone" class="current-phone">
          <span>Введіть Ваш номер телефону</span>
        </div>

        <div
          class="form-required-container"
          style="margin-bottom: 0"
          v-if="statusPhone.phone"
        >
          <icon name="required_icon" class="form-required-icon" />
          <input
            maxlength="4"
            v-if="!statusCode"
            v-model="code"
            placeholder="Код"
            class="form-input required"
          />
        </div>

        <div v-if="statusPhone.phone">
          <!-- <span>Введите код из СМС</span> -->
        </div>

        <!-- Write password -->
        <div
          class="form-required-container"
          style="margin-bottom: 0"
          v-if="statusCode"
        >
          <icon name="required_icon" class="form-required-icon" />
          <input
            v-model="password"
            minlength="6"
            type="password"
            placeholder="Пароль"
            class="form-input required"
          />
        </div>

        <div v-if="statusCode" class="statusCode">
          <span>Введіть новий пароль щонайменше 6 символів</span>
        </div>
      </div>

      <div class="input-error" v-if="errorsAuth.phone_error">
        {{ errorsAuth.phone_error }}
      </div>
      <div class="input-error" v-if="errorsAuth.phone">
        {{ errorsAuth.phone[0] }}
      </div>
      <div class="input-error" v-if="errorsAuth.password">
        {{ errorsAuth.password[0] }}
      </div>
      <div class="form-error" v-if="errorsAuth.username">
        {{ errorsAuth.username[0] }}
      </div>
      <div class="input-error" v-if="errorsAuth.non_field_errors">
        {{ errorsAuth.non_field_errors[0] }}
      </div>
      <div class="input-error" v-if="errorsAuth.error_codes">
        {{ errorsAuth.error_codes }}
      </div>

      <div
        @click.prevent="sendPhone"
        v-if="!statusPhone.phone"
        class="btn-filter btn getCode"
        :class="phone.length <= 8 ? 'disabled ' : ''"
      >
        Отримати код
      </div>
      <div
        v-if="statusPhone.phone && !statusCode"
        @click="confirmCode"
        class="btn-filter btn"
      >
        Підтвердити код
      </div>
      <button type="submit" class="btn-filter btn" v-if="statusCode">
        Оновити пароль
      </button>
      <span class="form-error" v-if="!$v.phone.numeric"
        >В поле "телефон" можно вводить только числовые значения</span
      >
    </form>
    <div class="d-flex justify-content-between bottom-line">
      <router-link to="/login" class="bottom-link">
        ← Авторизація
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';

export default {
  name: 'ResetPassword',
  data() {
    return {
      countryCode: '+380',
      phone: '',
      password: '',
      code: ''
    };
  },
  validations: {
    phone: {
      required,
      numeric,
      minLength: minLength(7)
    },
    code: {
      required,
      numeric,
      minLength: minLength(4)
    }
  },
  computed: {
    ...mapState({
      errorsAuth: (state) => state.auth.errorsAuth,
      statusCode: (state) => state.auth.statusCode,
      statusPhone: (state) => state.auth.statusPhone
    })
  },
  beforeMount() {
    this.code = '';
  },
  methods: {
    ...mapActions(['handleSendPhone', 'handleSendCode', 'handleResetPassword']),
    ...mapMutations(['RESET_REGISTER']),
    sendPhone() {
      const phoneSend = `0${this.phone}`;
      const payload = {
        path: 'sms_restore',
        phone: phoneSend.replace(/\s+/g, '')
      };
      this.handleSendPhone(payload);
    },
    confirmCode() {
      if (this.code.length >= 4 && this.statusPhone.phone == true) {
        const payload = {
          code: this.code,
          path: 'sms_restore'
        };
        this.handleSendCode(payload);
      }
    },
    resetPassword() {
      const data = {
        phone: `0${this.phone}`,
        password: this.password
      };
      this.handleResetPassword(data);
    }
  },
  destroyed() {
    this.RESET_REGISTER();
  }
};
</script>
<style scoped lang="sass">
.form-input
  &.country-code
    border-right: none
  &.reset-input
    border-left: none !important
.current-phone, .statusCode
  margin-top: 5px
.btn-filter
  height: 38px
  padding: 10px 0
</style>
