/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon6': {
    width: 1792,
    height: 1792,
    viewBox: '0 0 1792 1792',
    data: '<path pid="0" d="M384 448q0-53-37.5-90.5T256 320t-90.5 37.5T128 448t37.5 90.5T256 576t90.5-37.5T384 448zm1067 576q0 53-37 90l-491 492q-39 37-91 37-53 0-90-37L27 890q-38-37-64.5-101T-64 672V256q0-52 38-90t90-38h416q53 0 117 26.5T699 219l715 714q37 39 37 91zm384 0q0 53-37 90l-491 492q-39 37-91 37-36 0-59-14t-53-45l470-470q37-37 37-90 0-52-37-91L859 219q-38-38-102-64.5T640 128h224q53 0 117 26.5t102 64.5l715 714q37 39 37 91z"/>'
  }
})
