<template>
  <div :class="$bem()">
    <e-input
      v-bind="{ label: 'Тип матеріалу', readonly: true,}"
      v-model="selectedLabel"
      @click="opened = true"
    />
    <e-menu v-bind="{ right: true, top: true }" v-model="opened">
      <div v-for="(item, i) in options" :key="i">
        <e-radio
          v-model="selected"
          v-bind="{ value: item, label: item.label }"
          @input="setValue"
          @close="opened = undefined"
          style="line-height: 1.2"
        />
      </div>
    </e-menu>
  </div>
</template>

<script>
import { APT_MATERIAL_TYPE_CONSTANTS } from '@/helpers/constants';
import eMenu from '@/elements/e-menu';
import { objectToArray } from '@/utils/object-to-array';
import eRadio from '@/elements/inputs/e-radio';
import eInput from '@/elements/inputs/e-input';
import component from '@/mixins/component';

export default {
  name: 'add-object-type-material',
  components: { eInput, eRadio, eMenu },
  mixins: [component],
  data: () => ({
    selected: {},
    opened: false
  }),

  computed: {
    options() {
      return objectToArray(APT_MATERIAL_TYPE_CONSTANTS);
    },
    selectedLabel({ value }) {
      return value ? APT_MATERIAL_TYPE_CONSTANTS[this.value] : '';
    }
  },

  methods: {
    setValue({ value }) {
      this.$emit('input', value);
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  }
};
</script>
