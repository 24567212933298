/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon3': {
    width: 16,
    height: 16,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M19 22h10v20H19zM6 12h10v30H6zM32 6h10v36H32z"/>'
  }
})
