<template>
  <div :class="$bem()">
    <e-input
      v-bind="{ label: 'Тип нерухомості', error, required: true }"
      readonly
      v-model="selectedLabel"
      :disabled="disabled"
      @click="handleOpen"
    />

    <e-menu v-bind="{ right: true, top: true }" v-model="opened" class="p-2">
      <ul class="list-unstyled m-0 p-0">
        <li
          v-for="(option, optionIndex) in enabledOptions"
          :key="optionIndex"
          :class="{ 'mb-1': optionIndex !== enabledOptions.length - 1 }"
        >
          <div class="d-flex align-items-center">
            <e-radio
              v-model="selected"
              v-bind="{
                value: option.value,
                label: option.label,
                noMargin: true
              }"
              :class="{ 'flex-grow-1': option.value !== 'land' }"
              style="line-height: 1.2"
              @close="opened = undefined"
            />

            <span v-if="option.value === 'land'" class="new-label">Нове</span>
          </div>
        </li>
      </ul>
    </e-menu>
  </div>
</template>

<script>
import { TYPE_CONSTANTS } from '../../../helpers/constants';
import eMenu from '@/elements/e-menu';
import { objectToArray } from '@/utils/object-to-array';
import eRadio from '@/elements/inputs/e-radio';
import eInput from '@/elements/inputs/e-input';
import component from '@/mixins/component';

export default {
  name: 'type-real-estate',
  components: { eInput, eRadio, eMenu },
  mixins: [component],
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    isAptsItem: {
      type: Boolean,
      default: false
    },
    isEnabledCommerce: {
      type: Boolean,
      default: true
    },
    isEnabledLands: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      opened: false
    };
  },
  computed: {
    isHouses: ({ form }) => form.type === 'house',
    options() {
      return objectToArray(TYPE_CONSTANTS).slice(0, 4);
    },
    enabledOptions() {
      return this.options.filter((item) => {
        if (item.value === 'commerce') return this.isEnabledCommerce;

        if (item.value === 'land') return this.isEnabledLands;

        return true;
      });
    },
    selectedLabel({ value }) {
      return value ? TYPE_CONSTANTS[this.value] : '';
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    handleOpen() {
      this.opened = true;
    }
  }
};
</script>
