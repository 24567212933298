const getDefaultState = () => {
  return {
    form: {
      water: [],
      gas: [],
      heating: [],
      sewers: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterCommunication(state) {
    return !!(
      state.form.water.length ||
      state.form.gas.length ||
      state.form.heating.length ||
      state.form.sewers.length
    );
  }
};

export const mutations = {
  CHANGE_WATER(state, payload) {
    state.form.water = payload;
  },
  CHANGE_GAS(state, payload) {
    state.form.gas = payload;
  },
  CHANGE_HEATING(state, payload) {
    state.form.heating = payload;
  },
  CHANGE_SEWERS(state, payload) {
    state.form.sewers = payload;
  },
  RESET_FILTER_COMMUNICATION(state) {
    Object.assign(state, getDefaultState());
  }
};
