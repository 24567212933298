<template>
  <div :class="$bem()">
    <div v-for="(item, index) in items" :key="index" :class="$bem('item')">
      <div @click="toggle(index)" :class="$bem('title')">
        <h3>{{ item.title }}</h3>
        <img
          :class="arrowDirection(index)"
          src="@/assets/landing/angle.svg"
          alt="arrow"
        />
      </div>
      <div v-show="activeItem === index">
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'accordion',
  mixins: [component],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeItem: null
    };
  },
  methods: {
    toggle(index) {
      this.activeItem = this.activeItem === index ? null : index;
    }
  },
  computed: {
    arrowDirection() {
      return (index) => {
        return this.activeItem === index ? 'arrow-top' : 'arrow-down';
      };
    }
  }
};
</script>
