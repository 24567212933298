// src/services/responsive.js

import Vue from 'vue';

const state = Vue.observable({
  windowWidth: window.innerWidth
});

function updateWindowWidth() {
  state.windowWidth = window.innerWidth;
}

let componentCount = 0;

function addResizeListener() {
  if (componentCount === 0) {
    window.addEventListener('resize', updateWindowWidth);
  }
  componentCount++;
}

function removeResizeListener() {
  componentCount--;
  if (componentCount === 0) {
    window.removeEventListener('resize', updateWindowWidth);
  }
}

export default {
  state,
  addResizeListener,
  removeResizeListener
};
