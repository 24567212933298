/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_icon6': {
    width: 16,
    height: 16,
    viewBox: '0 0 426.667 426.667',
    data: '<path pid="0" d="M384 0H42.667C19.093 0 .213 19.093.213 42.667l-.213 384 85.333-85.333H384c23.573 0 42.667-19.093 42.667-42.667v-256C426.667 19.093 407.573 0 384 0zM234.667 256H192v-42.667h42.667V256zm0-85.333H192V85.333h42.667v85.334z"/>'
  }
})
