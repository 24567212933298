<template>
  <div class="image-carousel">
    <div class="image-carousel__sliders">
      <div class="image-carousel__main-slider">
        <div class="swiper" ref="mainSwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(image, imageIndex) in images"
              :key="imageIndex"
            >
              <div class="image-carousel__img">
                <div v-if="noDownload" class="image-carousel__watermark"></div>

                <a
                  :data-src="image"
                  data-fancybox="gallery"
                  :class="{ _clickable: imgClickable }"
                >
                  <img :src="image" alt="" loading="lazy" />
                </a>
              </div>

              <div class="swiper-lazy-preloader"></div>
            </div>
          </div>

          <button class="swiper-button-prev"></button>
          <button class="swiper-button-next"></button>
        </div>
      </div>

      <div class="image-carousel__thumbs-slider">
        <div class="swiper thumbs-swiper" ref="thumbsSwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(image, imageIndex) in images"
              :key="'thumb-' + imageIndex"
            >
              <div class="image-carousel__thumb-img">
                <img :src="image" alt="" class="image-carousel__thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Thumbs]);

export default {
  name: 'ImageCarousel',
  props: {
    images: {
      type: Array,
      required: true
    },
    noDownload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      containerEl: null,
      mainSwiperInstance: null,
      thumbsSwiperInstance: null,
      imgClickable: false
    };
  },
  mounted() {
    // Init Container
    this.containerEl = this.$refs.mainSwiper;

    // Init Sliders
    this.initSwiper();

    // Init Fancybox
    if (!Fancybox) {
      console.warn('Fancybox is not loaded');
    } else {
      this.bindFancybox();
    }

    // Prevent Img Download
    if (this.noDownload) {
      this.protectImgDownload();
    }
  },
  updated() {
    Fancybox.unbind(this.containerEl);
    Fancybox.close();

    this.bindFancybox();
  },
  beforeDestroy() {
    if (this.mainSwiperInstance) {
      this.mainSwiperInstance.destroy(true, true);
      this.mainSwiperInstance = null;
    }

    if (this.thumbsSwiperInstance) {
      this.thumbsSwiperInstance.destroy(true, true);
      this.thumbsSwiperInstance = null;
    }

    Fancybox.destroy();
    this.imgClickable = false;
  },
  methods: {
    initSwiper() {
      if (this.$refs.thumbsSwiper) {
        if (!this.thumbsSwiperInstance) {
          this.thumbsSwiperInstance = new Swiper(this.$refs.thumbsSwiper, {
            slidesPerView: 4,
            spaceBetween: 8,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            centeredSlides: true,
            breakpoints: {
              768: {
                slidesPerView: 5
              },
              992: {
                slidesPerView: 7
              }
            }
          });
        }
      }

      if (this.$refs.mainSwiper) {
        if (!this.mainSwiperInstance) {
          this.mainSwiperInstance = new Swiper(this.$refs.mainSwiper, {
            slidesPerView: 1,
            spaceBetween: 8,
            speed: 600,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            lazy: true,
            thumbs: {
              swiper: this.thumbsSwiperInstance
            }
          });
        }
      }
    },
    bindFancybox() {
      if (!this.containerEl) return;

      try {
        Fancybox.bind(this.containerEl, '[data-fancybox]', {
          Toolbar: {
            display: {
              right: ['iterateZoom', 'fullscreen', 'close']
            }
          },
          Images: {
            lazy: true,
            protected: this.noDownload
          }
        });

        this.imgClickable = true;
      } catch (error) {
        console.error('Fancybox binding failed:', error);
        this.imgClickable = false;
      }
    },
    protectImgDownload() {
      this.containerEl.addEventListener('contextmenu', function (e) {
        if (e.target.tagName === 'IMG') {
          e.preventDefault();
        }
      });
    }
  }
};
</script>

<style lang="sass">
.fancybox-protected
  background: url('~@/assets/images/logo/easybase-xs.webp') no-repeat center / 75% auto
  opacity: 0.1

.fancybox
  &__nav
    --f-button-color: var(--color-primary)
    --f-button-hover-color: var(--color-primary)

.image-carousel
  &__img
    position: relative
    display: block
    padding-bottom: 56%
    overflow: hidden

    a
      position: absolute
      left: 0
      top: 0
      display: flex
      width: 100%
      height: 100%

      &._clickable
        cursor: pointer

      img
        position: absolute
        left: 0
        top: 0
        width: 100%
        max-width: 100%
        height: 100%
        max-height: 100%
        object-fit: cover

  &__watermark
    position: absolute
    z-index: 9
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url('~@/assets/images/logo/easybase-xs.webp') no-repeat center / 45% auto
    opacity: 0.1
    pointer-events: none

  &__sliders
    --swiper-theme-color: var(--color-primary)

  &__main-slider
    &:not(:last-child)
      margin-bottom: 0.5rem

    .swiper-button-prev,
    .swiper-button-next
      --swiper-navigation-size: 2.5rem
      --swiper-navigation-sides-offset: 0.5rem

      border: none
      width: var(--swiper-navigation-size)
      background: none

      &::after
        font-size: 1.5rem

  &__thumbs-slider
    .swiper
      .swiper-slide
        opacity: 0.75
        cursor: pointer
        transition: opacity var(--transition-duration-primary)

        &-thumb-active
          opacity: 1

        img
          display: block
          width: 100%
          max-width: 100%

  &__thumb-img
    --size: 3rem

    border-radius: 0.125rem
    height: var(--size)
    overflow: hidden

    img
      display: block
      width: 100%
      max-width: 100%
      height: 100%
      max-height: 100%
      object-fit: cover
</style>
