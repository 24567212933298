<template>
  <div class="flex-column align-items-center">
    <p class="lead">Страница не найдена</p>
    <router-link to="/" class="btn btn-link btn-sm" style="color: #308b9d"
      >Вернуться на главную</router-link
    >
    <img
      src="@/assets/images/cat-404.png"
      alt="page not found image"
      width="533"
    />
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
};
</script>
