<template>
  <div>
    <p><b>Ви отримуєте:</b></p>
    <div :class="$bem()">
      <div>
        <item-component
          :iconSrc="require('@/assets/landing/dollar.svg')"
          itemText="Плату за надану Вами інформацію"
        />
        <item-component
          :iconSrc="require('@/assets/landing/bot.svg')"
          itemText="Бот з інформуванням про нові об'єкти"
        />
        <item-component
          :iconSrc="require('@/assets/landing/mail.svg')"
          itemText="Можливість відправляти об'єкти у месенджери"
        />
      </div>
      <div>
        <item-component
          :iconSrc="require('@/assets/landing/phone.svg')"
          itemText="Мобільну версію - вся база у кишені"
        />
        <item-component
          :iconSrc="require('@/assets/landing/box.svg')"
          itemText="Архів за останні 5 років"
        />
        <item-component
          :iconSrc="require('@/assets/landing/laptop.svg')"
          itemText="EasyHata - база для ваших клієнтів"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ItemComponent from './item-component.vue';
import component from '@/mixins/component';

export default {
  name: 'benefits-component',
  components: {
    ItemComponent
  },
  mixins: [component]
};
</script>

<style lang="sass">
.benefits-component
  display: flex
  justify-content: space-between
  max-width: 600px
  @media screen and (max-width: 991px)
   flex-direction: column
</style>
