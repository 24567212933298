const getDefaultState = () => {
  return {
    form: {
      purpose: [],
      sortable: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterLandsPurpose(state) {
    return state.form.purpose && state.form.purpose.length;
  },
  getSortable(state) {
    return state.form.sortable;
  }
};

export const mutations = {
  CHANGE_FILTER_LANDS_PURPOSE(state, payload) {
    state.form.purpose = payload;
  },
  CHANGE_SORTABLE(state, payload) {
    state.form.sortable = payload;
  },
  RESET_FILTER_LANDS_PURPOSE(state) {
    state.form.purpose = [];
  }
};
