<template>
  <div :class="$bem()">
    <e-popup :class="$bem('popup')" v-model="model" @close="closePopup">
      <template #header>
        <h4 class="text-center">{{ item.title }}</h4>
      </template>

      <component
        :is="item.link ? 'a' : 'div'"
        :class="$bem('body')"
        :href="item.link"
        target="_blank"
      >
        <figure v-if="item.image" :class="$bem('frame')">
          <img :class="$bem('frame')" :src="item.image" :alt="item.title" />
        </figure>

        <p :class="$bem('text')">
          {{ item.text }}
        </p>
      </component>
    </e-popup>
  </div>
</template>

<script>
import component from '../../../../../mixins/component';
import ePopup from '../../../../../elements/popups/e-popup';
import api from '../../../../../api';

export default {
  name: 'navbar-notification-popup',
  mixins: [component],
  components: { ePopup },
  data: () => ({
    lastNotification: undefined
  }),
  computed: {
    model: {
      get: ({ value }) => value,
      set(val) {
        this.$emit('input', val);
      }
    },
    item() {
      return this.lastNotification || this.notification;
    }
  },
  methods: {
    async closePopup() {
      this.model = false;
      if (this.item.id) await api.sendViewedNews(this.item.id);
      this.lastNotification = undefined;
    }
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>
