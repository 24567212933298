<template>
  <div :class="[$bem(), { error: isError }]">
    <div
      v-show="(!isError || !showErrorMarker) && $_error.length"
      :class="$bem('errors')"
      v-html="$_error.join(' ')"
    />

    <slot name="input" v-bind="{ on: { focus, blur }, isError }" />

    <t-svg
      v-if="loading"
      v-bind="{ name: 'loader', color: 'magenta', size: 40 }"
      :class="$bem('loader')"
    />

    <div v-show="isError || $_tooltip.length" :class="$bem('top')">
<!--      <t-svg-->
<!--        v-show="isError && showErrorMarker"-->
<!--        :class="$bem('icon')"-->
<!--        name="exclamation"-->
<!--        color="red"-->
<!--        size="20"-->
<!--      />-->

<!--      <t-help-tooltip v-if="!isError && $_tooltip.length">-->
<!--        <div v-for="(tooltip, i) in $_tooltip" :key="i" v-html="tooltip" />-->
<!--      </t-help-tooltip>-->
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'input-wrapper',
  inheritAttrs: false,
  mixins: [component],
  // components: { tSvg, tHelpTooltip },
  data: () => ({
    state: { isError: undefined }
  }),
  methods: {
    focus(e) {
      this.isError = false;

      this.$emit('focus', e);
    },
    blur(e) {
      this.isError = undefined;

      this.$emit('blur', e);
    }
  },
  computed: {
    $_error() {
      const { error } = this;

      if (typeof error === 'boolean') return [];
      if (!error.length) return []

      return [].concat(error);
    },
    $_tooltip() {
      return [].concat(this.tooltip);
    },
    isError: {
      get() {
        const {
          error,
          state: { isError }
        } = this;

        if (isError !== undefined) return isError;

        if (Array.isArray(error)) return !!error.length;

        return !!error;
      },
      set(value) {
        this.state.isError = value;
      }
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, Boolean, Array],
      default: () => []
    },
    tooltip: {
      type: [Array, String],
      default: () => []
    },
    showErrorMarker: {
      type: Boolean,
      default: true
    }
  }
};
</script>
