export const getIntegerValue = (value) => {
  if (typeof value === 'string') {
    value = value.replace(/[^\d.,]/g, '').trim();
  }

  const numericValue = parseFloat(value);

  if (isNaN(numericValue)) {
    return 0;
  }

  return Math.round(numericValue);
};
