<template>
  <button :class="$bem()" @click="onClick">
    Поділитися <img :src="shareIcon" alt="share icon" />
  </button>
</template>

<script>
import shareIcon from '@/assets/icons/share.svg';
import component from '@/mixins/component';

export default {
  name: 'share-btn',
  mixins: [component],
  data() {
    return {
      shareIcon
    };
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>
<style lang="sass">
.share-btn
    background: $cyan
    color: #fff
    display: flex
    align-items: center
    padding: 4px 10px
    border-radius: 30px
    font-size: 14px
    height: 28px
    border: none
    font-weight: 300
    img
        margin-left: 5px
        width: 15px
        height: 12px
</style>
