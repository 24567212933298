/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon11': {
    width: 16,
    height: 16,
    viewBox: '0 0 460.069 460.069',
    data: '<circle pid="0" cx="133.853" cy="37.866" r="37.866"/><path pid="1" d="M85.015 243.318l.008 194.825c0 12.109 9.816 21.925 21.925 21.925s21.925-9.816 21.925-21.925v-170.32a4.927 4.927 0 014.927-4.927 4.54 4.54 0 014.54 4.539v170.708c0 12.109 9.816 21.925 21.925 21.925s21.925-9.816 21.925-21.925l-.398-203.041c-55.736 29.751-46.084 29.418-96.777 8.216zM216.795 216.417l-26.856 14.336.173 34.482c.051 10.06 8.22 18.179 18.269 18.179h.094c10.09-.051 18.229-8.272 18.179-18.362l-.281-55.832a39.113 39.113 0 01-9.578 7.197zM288.406 75.733c20.882 0 37.866-16.933 37.866-37.866C326.272 16.953 309.319 0 288.406 0S250.54 16.953 250.54 37.866c-.001 20.912 16.965 37.867 37.866 37.867zM292.893 401.325v36.817c0 12.109 9.816 21.925 21.925 21.925s21.925-9.816 21.925-21.925V403.97h-31.755c-4.315 0-8.41-.952-12.095-2.645z"/><path pid="2" d="M429.964 298.515a9.08 9.08 0 00-9.079-9.079h-19.99v-23.349c0-5.878-4.765-10.643-10.643-10.643h-9.094l-.626-124.622c-.12-23.721-19.516-43.02-43.237-43.02H225.749c-7.7 0-14.574 4.827-17.186 12.07l-24.982 69.241-55.925 29.853-57.241-23.942 14.546-50.68h.054v34.209l41.557 17.381 40.134-21.424c2.257-6.256 23.465-64.765 24.275-66.676l-119.785-.032a18.272 18.272 0 00-17.562 13.23l-22.827 79.535c-2.509 8.752 1.886 18.254 10.46 21.872l.052.025 80.007 33.463c4.877 2.04 10.946 1.968 16.001-.704.022-.012.045-.021.067-.033l69.592-37.148c4.023-2.148 7.111-5.838 8.583-9.917 3.339-9.256 23.943-63.634 23.998-63.78l.008 313.799c0 12.109 9.816 21.925 21.925 21.925s21.925-9.816 21.925-21.925V394.38c-4.669-5.161-7.518-11.998-7.518-19.49v-76.375c0-7.491 2.849-14.329 7.518-19.49V267.66a4.766 4.766 0 014.765-4.766 4.7 4.7 0 014.701 4.701v4.484a28.855 28.855 0 0110.799-2.612v-3.382c0-16.703 13.161-31.928 32.653-31.928V131.103a3.824 3.824 0 017.647-.097l.625 124.438h-8.996c-5.878 0-10.643 4.765-10.643 10.643v23.349h-19.99a9.08 9.08 0 00-9.079 9.079v76.375a9.08 9.08 0 009.079 9.079h115.897a9.08 9.08 0 009.079-9.079v-76.375h.002z"/>'
  }
})
