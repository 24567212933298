/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon9': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" clip-rule="evenodd" fill-rule="evenodd" d="M6 2h2v4H6zM16 2h2v4h-2z"/><path pid="1" d="M19 4H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/><path pid="2" clip-rule="evenodd" fill-rule="evenodd" d="M12 13h5v5h-5z"/>'
  }
})
