export const objectEqual = (obj1, obj2) => {
  const equal = (a, b) => {
    if (a === b) return true;

    if (a.length !== b.length || !a || !b) return false;

    for (let i = 0; i < a.length; i += 1) {
      if ((a[i] || '').toString() !== (b[i] || '').toString()) return false;
    }

    return true;
  };

  if (obj1 === obj2) return true;

  if (
    (typeof obj1 === 'number' && typeof obj2 === 'number') ||
    (typeof obj1 === 'string' && typeof obj2 === 'string')
  )
    return obj1 === obj2;

  if (!obj1 || !obj2) return false;

  return (
    equal(Object.keys(obj1), Object.keys(obj2)) && equal(Object.values(obj1), Object.values(obj2))
  );
};