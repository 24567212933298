const getDefaultState = () => {
  return {
    form: {
      user_label: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterLabels(state) {
    return !!state.form.user_label.length;
  }
};

export const mutations = {
  CHANGE_LABELS(state, payload) {
    state.form.user_label = payload;
  },
  RESET_FILTER_LABELS(state) {
    Object.assign(state, getDefaultState());
  }
};
