<template>
  <div
    id="app"
    :class="[windowWidth < 767 ? 'mobile' : 'desktop', 'main-wrapper']"
  >
    <div v-if="!this.$route.meta.fullPage" class="main-header">
      <!-- <war-notification /> -->
      <navbar ref="navbar" />
    </div>

    <router-view class="main-content" />

    <subscribes-popup v-if="isSubscriptionPopupShow" ref="subscribe-popup" />

    <profile-data-popup ref="profile-data-popup"></profile-data-popup>

    <template v-if="windowWidth < 767">
      <ToViberPopup @close="$store.commit('SET_AUTH_STATUS', '')">
      </ToViberPopup>
    </template>

    <div v-if="this.$route.meta.disabledPage" class="fullScreen-modal"></div>

    <div v-if="!this.$route.meta.fullPage">
      <add-item-button />

      <bot-popup />
      <add-user-popup />
      <add-client-popup />
      <video-popup />
    </div>

    <add-feedback-popup />

    <notifications group="app" />
  </div>
</template>

<script>
import './components/icons';
import ProfileDataPopup from '@/components/popups/ProfileDataPopup';
import Navbar from './components/app/navbar';
import AddItemButton from './components/app/AddItemButton';
import ePopup from './elements/popups/e-popup';
import { mapActions } from 'vuex';
import BotPopup from './components/popups/bot-popup';
// import WarNotification from './components/app/navbar/components/war-notification';

export default {
  components: {
    // WarNotification,
    ProfileDataPopup,
    Navbar,
    ePopup,
    AddItemButton,
    AddUserPopup: () => import('./components/popups/AddUserPopup'),
    AddClientPopup: () => import('./components/popups/add-client-popup'),
    AddFeedbackPopup: () => import('./components/popups/AddFeedbackPopup'),
    SubscribesPopup: () => import('./components/popups/SubscribesPopup'),
    VideoPopup: () => import('./components/popups/VideoPopup'),
    ToViberPopup: () => import('./components/popups/ToViberPopup'),
    MobileNavs: () => import('./components/app/mobile-navs'),
    BotPopup
  },
  data() {
    return {
      windowWidth: 0,
      scrollPane: null,
      objectPopup: false
    };
  },
  computed: {
    isMobileScreen() {
      return this.windowWidth < 991;
    },
    isSubscriptionPopupShow() {
      return (
        this.$route.name !== 'login' &&
        this.$route.name !== 'objects' &&
        this.$route.name !== 'profile' &&
        this.$route.name !== 'manage-object'
      );
    }
  },
  methods: {
    ...mapActions([
      'handleGetCities',
      'handleGetDistrict',
      'handleGetMicroDistrict',
      'handleGetStreet',
      'handleGetResidentialComplex',
      'handleGetBuildings'
    ]),
    // handleScroll - set custon scroll for mouse
    handleScroll(e) {
      if (!e.target.closest('.product-modal, .partner-review-wrapper')) {
        const delta = Math.max(
          -1,
          Math.min(1, e.deltaY * -1 || e.wheelDelta || -e.detail)
        );
        this.scrollPane.scrollLeft -= delta * 40;
        e.preventDefault();
      }
    },
    fetchData() {
      this.handleGetDistrict();
      this.handleGetMicroDistrict();
      this.handleGetStreet();
      this.handleGetResidentialComplex();
      this.handleGetCities();
      this.handleGetBuildings();
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    // mouse scroll
    this.scrollPane = document.querySelector(
      '#catalog-scroll .simplebar-content-wrapper'
    );
    if (this.scrollPane) {
      this.scrollPane.addEventListener('wheel', this.handleScroll, false);
    }
  },
  updated() {
    // mouse scroll
    this.scrollPane = document.querySelector(
      '#catalog-scroll .simplebar-content-wrapper'
    );
    if (this.scrollPane) {
      this.scrollPane.addEventListener('wheel', this.handleScroll, false);
    }
  },
  destroyed() {
    // mouse scroll
    if (this.scrollPane) {
      this.scrollPane.removeEventListener('wheel', this.handleScroll);
    }
  }
};
</script>

<style lang="sass">
@import 'assets/styles/css-variables'
@import 'assets/styles/libraries'

@import "assets/styles/grid"
@import 'assets/styles/fonts'
@import 'assets/styles/app'
@import 'assets/styles/main'
@import 'assets/styles/svg'
@import 'assets/styles/apartmensFilter'

@import "assets/styles/_animations"

@import 'assets/styles/partials/item'
@import 'assets/styles/partials/catalog-item'
@import 'assets/styles/partials/partner-item'
@import 'assets/styles/partials/person-item'
@import 'assets/styles/partials/cabinet'
@import 'assets/styles/partials/form'
@import 'assets/styles/partials/scrollbar'
@import 'assets/styles/partials/product-modal'
@import 'assets/styles/partials/filter'
@import 'assets/styles/partials/nav-search'
@import 'assets/styles/partials/button'
@import 'assets/styles/partials/modal-add'
@import 'assets/styles/partials/res-modal-wrapp'
@import 'assets/styles/overrides'
@import 'assets/styles/transitions'
@import "assets/styles/partials/upload-photo"
@import 'assets/styles/vue-slick-carousel'
@import "assets/styles/partials/_res-navbar.sass"

@import "assets/styles/components/rich-text"

.slide-fade-enter-active
  transition: all 3s ease

.slide-fade-leave-active
  transition: all 8s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.modal-footer
  position: static

.grecaptcha-badge
  visibility: hidden
  z-index: -1

.btn-support-image
  width: 90px
  margin-bottom: 5px
</style>
