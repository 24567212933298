/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon8': {
    width: 260,
    height: 245,
    viewBox: '0 0 260 245',
    data: '<path pid="0" d="M55 237L129 9l74 228L9 96h240"/>'
  }
})
