<template>
  <article class="useful-card">
    <img class="useful-card__img" :src="item.img" alt="" />

    <div class="useful-card__inner">
      <h3 class="useful-card__title">
        {{ item.title }}
      </h3>

      <UsefulBtn :to="item.href" :target="item.target" class="useful-card__btn">
        Відкрити
      </UsefulBtn>
    </div>
  </article>
</template>

<script>
import UsefulBtn from '@/components/useful/UsefulBtn.vue';

export default {
  name: 'UsefulCard',
  components: {
    UsefulBtn
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
.useful-card
  position: relative
  display: block
  border-radius: var(--border-radius-10)
  width: 100%
  padding-bottom: 56%
  background-color: var(--color-blue-river)
  overflow: hidden

  &::after
    content: ''
    position: absolute
    z-index: 6
    left: 0
    top: 0
    width: 100%
    height: 100%
    filter: blur(2px)
    background: linear-gradient(180deg, rgba(209, 217, 226, 0) 50.58%, rgba(161, 179, 196, 0.85) 61.69%, rgba(78, 111, 144, 0.85) 77.28%, rgba(9, 12, 16, 0.85) 98.5%)

  &__img
    position: absolute
    z-index: 5
    left: 0
    top: 0
    width: 100%
    height: 100%
    object-fit: cover

  &__inner
    position: absolute
    z-index: 10
    left: 0
    bottom: 0
    width: 100%
    display: flex
    justify-content: space-between
    align-items: flex-end
    gap: 0.75rem
    padding: 0.75rem

  &__title
    margin-bottom: 0
    align-self: center
    font-size: 1.25rem
    font-weight: 600
    line-height: 1.4
    font-variant: small-caps
    color: var(--color-white-smoke)
</style>
