import { forEach, isEmpty } from 'lodash';

export const validationFields = {
  flat: {
    required: [
      'city',
      'district',
      'microdistrict',
      'street',
      'floors',
      'floor',
      'price',
      'type',
      'repair',
      'square_common',
      'rooms',
      'apt_type',
      'business_type'
    ],
    rent: [],
    sell: []
  },
  house: {
    required: [
      'type',
      'district',
      'city',
      'street',
      'repair',
      'square_common',
      'square_area',
      'rooms',
      'apt_type',
      'price',
      'floors',
      'business_type'
    ],
    rent: []
  },
  commerce: {
    required: [
      'city',
      'price',
      'street',
      'type',
      'square_common',
      'district',
      'apt_type',
      'repair',
      'business_type'
    ],
    rent: []
  },
  land: {
    required: [
      'type',
      'business_type',
      'city',
      'district',
      'street',
      'purpose',
      'cadastral_number',
      'square_area',
      'price'
    ],
    rent: [],
    sell: []
  }
};
export const apartmentsValidate = (data) => {
  const errors = {};
  const typeObject = validationFields[data.type || 'flat'];

  forEach(typeObject.required, (key) => {
    if (typeof data[key] === 'number') {
      if (!data[key]) {
        errors[key] = "Поле обов'язкове для заповнення";
      }

      return;
    }

    if (isEmpty(data[key])) {
      errors[key] = "Поле обов'язкове для заповнення";
    }
  });

  return errors;
};

export const validationFieldsClients = {
  flat: {
    required: ['city', 'name', 'type', 'priceTo'],
    requiredLength: ['rooms']
  },
  house: {
    required: ['city', 'name', 'type', 'priceTo'],
    requiredLength: ['rooms']
  }
};

export const prepareValidationFields = (data) => {
  const dynamicRequired = {
    residential_complex: data.apt_type === 'new_building',
    microdistrict: data.city && data.city.is_city
  };

  updateValidationFields(validationFields.flat, dynamicRequired);

  const commerceDynamicRequired = {
    square_area: data.apt_type === 'farm',
    square_common: data.apt_type !== 'farm',
    microdistrict: data.city && data.city.is_city
  };

  updateValidationFields(validationFields.commerce, commerceDynamicRequired);

  const houseDynamicRequired = {
    microdistrict: data.city && data.city.is_city
  };

  updateValidationFields(validationFields.house, houseDynamicRequired);
};

const updateValidationFields = (validationObj, requiredFields) => {
  Object.entries(requiredFields).forEach(([key, isRequired]) => {
    if (isRequired) {
      if (!isObjectHasField(validationObj, key)) {
        validationObj.required.push(key);
      }
    } else {
      removeField(validationObj, key);
    }
  });
};

const isObjectHasField = (obj, field) => {
  return obj.required.some((a) => a === field);
};

const removeField = (obj, field) => {
  obj.required = obj.required.filter((a) => a !== field);
};

export const clientValidate = (data, errors) => {
  const typeObject = validationFieldsClients[data.type || 'flat'];
  Object.entries(data).forEach(([key, value]) => {
    if (
      typeObject.required.includes(key) &&
      !(data.apt_type === 'farm' && key === 'square_common')
    ) {
      if (!value) {
        errors[key] = "Поле обов'язкове для заповнення";
      } else {
        errors[key] = '';
      }
    }

    if (typeObject.requiredLength.includes(key) && !value?.length) {
      errors[key] = "Поле обов'язкове для заповнення";
    } else if (typeObject.requiredLength.includes(key) && value?.length) {
      errors[key] = '';
    }
  });

  return errors;
};

export const dataPreparationForSending = (data, isEditing, isHouses) => {
  const prepareData = JSON.parse(JSON.stringify(data));

  prepareData.labels = data.labels.map((item) => {
    return item.value;
  });
  prepareData.images = data.images ? data.images : [];
  prepareData.district = data.district?.id;
  prepareData.microdistrict = data.microdistrict?.id;
  prepareData.street = data.street?.id;
  prepareData.residential_complex = data.residential_complex?.id;
  if (!isEditing) {
    prepareData.repair = data.repair.id ? data.repair.id : data.repair;
  }
  prepareData.business_type = data.business_type;

  if (isHouses) {
    delete prepareData.square_area;
  }
  return prepareData;
};

export const defaultData = {
  city: null,
  district: [],
  microdistrict: [],
  street: [],
  apt_type: '',
  material_type: '',
  labels: [],
  house_letters: '',
  type: '',
  price: null,
  floors: null,
  floor: null,
  user_label: [],
  house_number: null,
  residential_complex: [],
  house_letter: '',
  square_common: null,
  square_living: null,
  square_kitchen: null,
  text: '',
  images: [],
  options: [],
  house_hull: '',
  repair: '',
  business_type: 'sell',
  square_area: null,
  status: false,
  rooms: 0,
  is_angled: false,
  source: 'rieltor'
};

export const getMessage = (status) => {
  return status === 'draft'
    ? 'Об\'єкт створено, перейдіть до розділу "мої об\'єкти"'
    : "Об'єкт буде доданий до бази після підтвердження його актуальності модераторами сайту! Багато часу це не займе 😊";
};
