<template>
  <div :class="[$bem(), 'd-flex']" v-on="$listeners">
    <t-btn-navigate :class="$bem('minus')" @click="model -= step" :disabled="model <= min">
      -
    </t-btn-navigate>

    <div :class="$bem('value')">
      <slot name="value" v-bind="{ count: model }" />

      <template v-if="!$hasSlot('value')">
        {{ model }}
      </template>
    </div>

    <t-btn-navigate :class="$bem('plus')" @click="model += step" :disabled="model >= max">
      +
    </t-btn-navigate>
  </div>
</template>

<script>
import component from '@/mixins/component';

import tBtnNavigate from '../buttons/e-btn-navigate';

export default {
  name: 'e-counter',
  inheritAttrs: false,
  mixins: [component],
  components: { tBtnNavigate },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
