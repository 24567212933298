<template>
  <div :class="$bem()" :id="popoverId">
    <img src="@/assets/icons/cart_icon.svg" :alt="altText" />
    <b-popover
      :target="popoverId"
      triggers="hover"
      placement="top"
      :custom-class="$bem('popover-menu')"
    >
      <!-- Slot для содержимого поповера -->
      <slot></slot>
    </b-popover>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'kebab-menu',
  mixins: [component],
  props: {
    altText: {
      type: String,
      required: true
    },
    popoverId: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="sass">
.kebab-menu
  height: 25px
  width: 20px
  position: absolute
  top: 0
  right: 19.2px
  &__popover-menu
    border-radius: 5px !important
    background: $active_text_color !important
    .popover-body
        background: $active_text_color
        border-radius: 5px
    .arrow
        &::before
            border-bottom-color: $active_text_color !important
        &::after
            border-bottom-color: $active_text_color !important

  img
    width: 5px
    transform: translateX(8px)
</style>
