<template>
  <e-input
    v-model="model"
    v-on="$listeners"
    v-bind="{ ...$attrs, type, showErrorMarker: false }"
  />
</template>

<script>
import eInput from '../e-input';

export default {
  name: 'numeric-field',
  components: { eInput },
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'number'
    }
  },
  computed: {
    model: {
      get() {
        const value = this.value ? this.value.toString() : '';

        return value.replace(/[^\d.]/g, '');
      },
      set(val) {
        if (val === '') {
          this.$emit('input', null);
        } else {
          this.$emit('input', +val);
        }
      }
    }
  }
};
</script>
