<template>
  <div :class="$bem()">
    <e-btn
      :icon="true"
      class="navbar__btn-harebell"
      :class="{ 'show-dot': hasUnseenNotifications }"
      @click="model = true"
    >
      <img
        v-if="!isMobile"
        :style="{ animationPlayState: animationState }"
        :class="{ 'notification-icon': hasUnseenNotifications }"
        src="~@/assets/icons/notification-icon.svg"
        width="24"
        height="24"
        alt=""
      />

      <img
        v-else
        :style="{ animationPlayState: animationState }"
        :class="{ 'notification-icon': hasUnseenNotifications }"
        src="~@/assets/images/icons-svg/notification-outline.svg"
        width="30"
        height="30"
        alt=""
      />
    </e-btn>

    <span
      v-if="shouldShowNotifications"
      class="notification-alert"
      @click="model = true"
    >
      У вас нове повідомлення
    </span>

    <e-popup
      :class="$bem('popup')"
      v-model="model"
      :overlay="false"
      @close="closeModal()"
    >
      <div :class="[$bem('content'), 'd-flex flex-column']">
        <notification-item
          v-for="(news, i) in sortedNotifications"
          :news="news"
          :key="i"
          @click="getItemNotification($event)"
        />
      </div>
    </e-popup>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import component from '@/mixins/component';
// eslint-disable-next-line import/no-unresolved
import eTooltip from '@/elements/e-tooltip';
// eslint-disable-next-line import/no-unresolved
import eBtn from '@/elements/buttons/e-btn';
import { mapActions, mapGetters } from 'vuex';
import notificationItem from './components/notification-item';
import ePopup from '@/elements/popups/e-popup';
import api from '@/api';

export default {
  name: 'navbar-notification-tooltip',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  mixins: [component],
  data: () => ({
    notifications: [],
    model: false,
    hasUnseenNotifications: false,
    animationState: 'running'
  }),
  components: {
    ePopup,
    notificationItem,
    eTooltip,
    eBtn
  },
  computed: {
    ...mapGetters(['getNotifications']),
    sortedNotifications() {
      return [...this.getNotifications].sort((a, b) => a.is_seen - b.is_seen);
    },
    shouldShowNotifications() {
      return this.hasUnseenNotifications && !this.model;
    }
  },
  methods: {
    ...mapActions(['handleGetNotifications', 'updateNotificationStatus']),
    async getItemNotification(id) {
      const notification = this.getNotifications.find(
        (notification) => notification.id === id
      );
      await api.sendViewedNews(id);
      await this.updateNotificationStatus(id);
      this.$emit('open-modal', notification);
    },
    closeModal() {
      this.model = false;
    },
    checkUnseenNotifications() {
      this.hasUnseenNotifications = this.getNotifications.some(
        (notification) => !notification.is_seen
      );
    },
    toggleAnimation() {
      this.animationState =
        this.animationState === 'paused' ? 'running' : 'paused';
    }
  },
  created() {
    this.handleGetNotifications();
    this.checkUnseenNotifications();
  },
  watch: {
    getNotifications: {
      handler() {
        this.checkUnseenNotifications();
      },
      deep: true
    },
    hasUnseenNotifications() {
      if (this.hasUnseenNotifications) {
        /**
         * Run notification animation
         */
        setInterval(this.toggleAnimation, 4000);
      }
    }
  }
};
</script>
