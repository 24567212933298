<template>
  <div :class="$bem()">
    <div class="mobile-header__title-wrap">
      <div :class="$bem('title')">
        {{ $route.meta.title }}
      </div>
    </div>

    <div>
      <div v-if="!loaderObject && (objectsCount || objectsCount === 0)">
        <div>Відкрито об'єктів:</div>

        <div :class="$bem('numbers')">
          <div :class="$bem('large')">{{ objectsCount }}</div>
          <div>|</div>
          <div :class="$bem('small')">{{ totalCount }}</div>
        </div>
      </div>

      <div v-if="loaderObject" :class="$bem('preloader')">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
        <div class="dot dot4"></div>
      </div>
    </div>

    <div class="mobile-header__btns">
      <a
        class="nav-link-self"
        target="_blank"
        href="https://invite.viber.com/?g2=AQAK9IpHpaTlGU%2BoaVtqM8PR7n2OiVrVC69g1LLx7sbtzYdN8w9hYEdqUo7%2BR%2BNd"
      >
        <img src="@/assets/icons/viber-icon.svg" width="20" height="20" />
      </a>

      <a
        class="nav-link-self"
        target="_blank"
        href="https://instagram.com/easybase.dnepr"
      >
        <img src="@/assets/icons/instagram-simple.svg" width="20" height="20" />
      </a>

      <div>
        <notification-tooltip isMobile @open-modal="openModal($event)" />

        <notification-popup :notification="notification" v-model="model" />
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import notificationPopup from '@/components/app/navbar/components/notification-popup';
import notificationTooltip from '@/components/app/navbar/components/notification-tooltip';
import { mapState } from 'vuex';

export default {
  name: 'mobile-header',
  components: { notificationPopup, notificationTooltip },
  mixins: [component],
  props: {
    objectsCount: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      model: false,
      show: false,
      notification: undefined
    };
  },
  methods: {
    openModal(notification) {
      this.model = true;
      this.notification = notification;
    }
  },
  computed: {
    ...mapState({
      loaderObject: (state) => state.main.loaderObject
    })
  }
};
</script>

<style lang="sass">
.mobile-header
  position: fixed
  z-index: 9
  left: 0
  top: 0
  display: flex
  justify-content: space-between
  align-items: center
  gap: 0.5rem
  width: 100%
  max-height: 46px
  padding: 10px 16px
  background-color: var(--color-caspian-sea)

  &__numbers
    display: flex
    justify-content: center
    align-items: flex-end
    gap: 5px
    line-height: 1

  &__large
    font-size: 15px

  &__small
    font-size: 12px

  &__title-wrap
    min-width: 27%

  &__title
    max-width: fit-content
    font-size: 18px

  &__preloader
    font-size: 18px
    text-align: center
    margin: auto
    height: 10px
    display: block
    width: 50px
    background: transparent
    position: relative

  &__btns
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 0.5rem
    min-width: 27%

.mobile-header__title.new-link
  position: relative

  &::before
    content: 'нове'
    position: absolute
    top: -2px
    display: block
    padding-top: 1px
    right: -16px
    font-weight: 700
    font-size: 10px
    line-height: 90%
    text-transform: uppercase
    text-align: right
    padding-left: 1px
    color: $menu_color
    background: $green_text
</style>
