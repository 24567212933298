/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'why_icon': {
    width: 1024,
    height: 1024,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M448 768h128V640H448v128zm64-512c-96 0-192 96-192 192h128c0-32 32-64 64-64s64 32 64 64c0 64-128 64-128 128h128c64-22 128-64 128-160s-96-160-192-160zm0-256C229 0 0 229 0 512s229 512 512 512 512-229 512-512S795 0 512 0zm0 896c-212 0-384-172-384-384s172-384 384-384 384 172 384 384-172 384-384 384z"/>'
  }
})
