<template>
  <div :class="$bem()">
    <e-input
      v-bind="{ label: 'Позначка' }"
      v-model="selectedLabels"
      @click="opened = true"
    />
    <e-menu v-bind="{ right: true, top: true }" v-model="opened">
      <div :class="$bem('item')" v-for="(item, i) in options" :key="i" class="w-100 pl-2">
        <e-checkbox v-model="selected" :value="item" :label="item.label" class="w-100" />
        <i :class="[$bem('item-icon'), 'mdi', item.icon ]"></i>
      </div>
    </e-menu>
  </div>
</template>

<script>
import { objectToArray } from '../../../../../../utils/object-to-array';
import eInput from '../../../../../../elements/inputs/e-input';
import component from '../../../../../../mixins/component';
import eCheckbox from '../../../../../../elements/inputs/e-checkbox';
import eMenu from '../../../../../../elements/e-menu';
import { LABELS } from '@/helpers/constants';
const LABELS_ICON =  [
  'mdi-thumb-up-outline',
  'mdi-thumb-down-outline',
  'mdi-account',
  'mdi-flash-outline',
  'mdi-autorenew',
  'mdi-checkbox-marked',
  'mdi-minus-box',
  'mdi-account',
  'mdi-cellphone-arrow-down',
  'mdi-briefcase'
];

export default {
  name: 'add-object-labels',
  components: { eCheckbox, eInput, eMenu },
  mixins: [component],
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    opened: false
  }),
  computed: {
    options() {
      const array =  objectToArray(LABELS);
      return array.map((item, index) => ({...item, icon: LABELS_ICON[index]}))
    },
    selectedLabels() {
      if (!this.selected) {
        return;
      }
      return this.selected.map(i => i.label).join(',')
    },
    selected: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
