<template>
  <div class="login-form register-form">
    <div
      v-if="this.$store.getters.authStatus === 'loading'"
      class="container-loading"
    >
      <img :src="require('@/assets/images/loading.gif')" alt="Авторизация..." />
    </div>

    <form class="form-register" @submit.prevent="register">
      <div class="form-group">
        <div class="form-required-container" style="margin-bottom: 0">
          <icon name="required_icon" class="form-required-icon" />
          <div
            class="d-flex align-items-end block-phone"
            v-if="!statusPhone.phone"
          >
            <input
              v-model="countryCode"
              type="text"
              disabled
              class="form-input required country-code"
            />
            <input
              v-model="username"
              type="number"
              class="form-input required phone-number"
            />
          </div>
        </div>
        <div v-if="!statusPhone.phone" style="text-align: center">
          <span>Введіть номер телефону</span>
        </div>

        <div
          class="form-required-container"
          v-if="statusPhone.phone"
          style="margin-bottom: 0"
        >
          <icon name="required_icon" class="form-required-icon" />
          <input
            maxlength="4"
            v-if="statusPhone.phone && !statusCode"
            v-model="code"
            placeholder="Код"
            class="form-input required"
          />
        </div>
        <div v-if="statusPhone.phone && !statusCode" style="text-align: center">
          <span>Введіть код із SMS</span>
        </div>
        <div class="form-required-container" v-if="statusCode">
          <div class="row form-required-container" style="margin-bottom: 0">
            <icon name="required_icon" class="form-required-icon" />
            <input
              v-model="password"
              minlength="6"
              type="password"
              placeholder="Пароль"
              class="form-input required"
            />
          </div>
          <div>
            <span>Придумайте пароль не меньше 6 символов </span>
          </div>
          <div class="row btn-group-toggle form-required-container">
            <label class="agreed">
              <icon name="required_icon" class="form-required-icon" />
              <input v-model="consent" type="checkbox" />
              <div class="btn-check-border">
                <icon name="check_icon" class="btn-check-icon" />
              </div>
              <div>Согласен с условиями и правилами при регистрации</div>
            </label>
          </div>
          <b-button class="btn-rules" v-b-modal.terms-popup
            >Умови та правила</b-button
          >
        </div>
      </div>

      <div class="input-error" v-if="errorsAuth.phone_error">
        {{ errorsAuth.phone_error }}
      </div>
      <div class="input-error" v-if="errorsAuth.phone">
        {{ errorsAuth.phone[0] }}
      </div>
      <div class="input-error" v-if="errorsAuth.password">
        {{ errorsAuth.password[0] }}
      </div>
      <div class="form-error" v-if="errorsAuth.username">
        {{ errorsAuth.username[0] }}
      </div>
      <div class="input-error" v-if="errorsAuth.non_field_errors">
        {{ errorsAuth.non_field_errors[0] }}
      </div>
      <div class="input-error" v-if="errorsAuth.error_codes">
        {{ errorsAuth.error_codes }}
      </div>

      <div
        @click.prevent="sendPhone"
        v-if="!statusPhone.phone"
        class="btn-filter btn getCode"
        :class="username.length <= 8 ? 'disabled ' : ''"
      >
        Отримати код
      </div>
      <div
        v-if="statusPhone.phone && !statusCode"
        @click="confirmCode"
        class="btn-filter btn"
      >
        Підтвердити код
      </div>
      <button
        type="submit"
        v-if="statusCode"
        class="btn-filter btn"
        :disabled="!consent && password.length > 5"
      >
        Начать работать
      </button>
    </form>
    <TermsConditionsPopup />
  </div>
</template>

<script>
import TermsConditionsPopup from '@/components/popups/TermsConditionsPopup';
import { mapActions, mapState, mapMutations } from 'vuex';
import { getUrlParams } from '@/utils/url-parse-to-object';

export default {
  components: {
    TermsConditionsPopup
  },
  data() {
    return {
      consent: false,
      countryCode: '+380',
      username: '',
      password: '',
      code: ' '
    };
  },
  computed: {
    ...mapState({
      errorsAuth: (state) => state.auth.errorsAuth,
      statusCode: (state) => state.auth.statusCode,
      statusPhone: (state) => state.auth.statusPhone
    })
  },
  beforeMount() {
    this.code = '';
    const { inv: invite_link } = getUrlParams(window.location.search);
    if(!invite_link || invite_link === 'LnIUe'){ 
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions(['handleSendPhone', 'handleSendCode', 'handleRegister']),
    ...mapMutations(['RESET_REGISTER', 'RESET_ERRORS']),
    async sendPhone() {
      await this.RESET_ERRORS();

      const payload = {
        path: 'sms',
        phone: `0${this.username}`.replace(/\s+/g, '')
      };
      await this.handleSendPhone(payload);
    },
    async confirmCode() {
      const {
        code,
        statusPhone: { phone }
      } = this;
      await this.RESET_ERRORS();

      if (code.length >= 4 && phone)
        await this.handleSendCode({ code, path: 'sms' });
    },

    register() {
      let { username, password } = this;
      const { inv: invite_link } = getUrlParams(window.location.search);
      username = `0${username}`.replace(/\s+/g, '');
      this.handleRegister({ username, password, invite_link });
    }
  },
  destroyed() {
    this.RESET_ERRORS();
    this.RESET_REGISTER();
  }
};
</script>

<style>
.register-form {
  align-self: center;
}
@media screen and (max-width: 991px) {
  .register-form {
    top: 30px
  } 
}
.login-form form .block-phone *:not(:last-child) {
  margin-bottom: 0px;
}
.form-register {
  width: 100%;
}
.btn-rules {
  margin: 0 auto;
  display: block;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.block-phone .form-input {
  outline: 0;
}
.block-phone .form-input:first-child {
  width: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
}
.block-phone .form-input:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
}
.login-form.main-content {
  flex: 0;
}
.register-form .form-input.country-code {
    border-right: none; 
}
.register-form .form-input.phone-number {
  border-left: none !important; 
  padding-left: 5px !important;
}
.register-form .btn-filter {
  height: 38px !important;
  padding: 10px 0 !important
}

.agreed .form-required-icon {
  position: absolute;
  top: 19px !important;
  left: 2px
}

.agreed {
  display: flex;
  align-items: top;
  padding: 10px;
  margin-bottom: 0;
}

.agreed .btn-check-border {
  margin-right: 5px;
  margin-top: 4px;
}

.agreed .btn-check-border svg {
  border: 1px solid #308b9d !important
}

.agreed input {
  display: none;
}
</style>
