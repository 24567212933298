<template>
  <div
    @click="$emit('click', news.id)"
    :class="[$bem(), 'd-flex justify-content-start', { 'is-seen': news.is_seen }]"
  >
    <div :class="$bem('frame')">
      <img :class="$bem('img')" :src="news.image" />
    </div>
    <div :class="[$bem('content'), 'ml-2']">
      <h5 :class="$bem('title')">{{ news.title }}</h5>
      <p :class="$bem('text')">{{ news.text }}</p>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import component from '@/mixins/component';

export default {
  name: 'notification-item',
  mixins: [component],
  props: {
    news: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
