
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_icon12': {
    width: 16,
    height: 16,
    viewBox: '0 0 460.069 460.069',
    data: `<svg width="416" height="352" viewBox="0 0 416 352" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M333.25 32C329.939 22.6449 323.81 14.5458 315.707 8.81764C307.603 3.08944 297.924 0.0136642 288 0.0136642C278.076 0.0136642 268.397 3.08944 260.293 8.81764C252.19 14.5458 246.061 22.6449 242.75 32H0V64H242.75C246.061 73.3551 252.19 81.4542 260.293 87.1824C268.397 92.9106 278.076 95.9863 288 95.9863C297.924 95.9863 307.603 92.9106 315.707 87.1824C323.81 81.4542 329.939 73.3551 333.25 64H416V32H333.25ZM128 128C118.078 128.014 108.403 131.097 100.302 136.826C92.2005 142.555 86.0698 150.65 82.75 160H0V192H82.75C86.0607 201.355 92.1896 209.454 100.293 215.182C108.397 220.911 118.076 223.986 128 223.986C137.924 223.986 147.603 220.911 155.707 215.182C163.81 209.454 169.939 201.355 173.25 192H416V160H173.25C169.93 150.65 163.799 142.555 155.698 136.826C147.597 131.097 137.922 128.014 128 128ZM288 256C278.078 256.014 268.403 259.097 260.302 264.826C252.201 270.555 246.07 278.65 242.75 288H0V320H242.75C246.061 329.355 252.19 337.454 260.293 343.182C268.397 348.911 278.076 351.986 288 351.986C297.924 351.986 307.603 348.911 315.707 343.182C323.81 337.454 329.939 329.355 333.25 320H416V288H333.25C329.93 278.65 323.799 270.555 315.698 264.826C307.597 259.097 297.922 256.014 288 256Z" fill="white"/>
    </svg>`
    
    
  }
})
