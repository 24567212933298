<template>
  <div class="form-group form-group-filter">
    <e-multiselect
      v-model="model"
      placeholder="Почніть вводити:"
      :close-on-select="false"
      :clear-on-select="false"
      :show-no-results="false"
      :show-labels="false"
      label="name"
      track-by="id"
      v-bind="{ error, title, multiple, required }"
      :options="options"
    >
      <span slot="noResult">Немає результатів!!!</span>
    </e-multiselect>
  </div>
</template>

<script>
import component from '../../mixins/component';
import eMultiselect from '../../elements/e-multiselect';
import { objectToArray } from '../../utils/object-to-array';
import { CHOISES_LABEL_OBJECT } from '../../helpers/constants';

export default {
  name: 'select-config',
  mixins: [component],
  components: { eMultiselect },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    options() {
      return objectToArray(CHOISES_LABEL_OBJECT, true);
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        const value = typeof val === 'string' ?  this.options.find(a => a.id === this.value) : val

        this.$emit('input', value);
      }
    }
  },
};
</script>
