/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu_inner_icon9': {
    width: 16,
    height: 16,
    viewBox: '0 0 392 392',
    data: '<path pid="0" d="M375.64 248.559l-155.64-.16v16c-.022 7.634-6.206 13.818-13.84 13.84h-20.28c-7.582.023-13.77-6.059-13.88-13.64v-16H16.52A27.638 27.638 0 010 243.039v92c.044 10.254 8.346 18.556 18.6 18.6h354.48c10.316.133 18.787-8.121 18.92-18.437v-92.003a27.635 27.635 0 01-16.36 5.36zM373.24 90.439H272v-28.28a23.759 23.759 0 00-7-16.8 23.759 23.759 0 00-16.8-7H143.64c-13.255 0-24 10.745-24 24v28.28H18.76C8.521 90.594.153 98.801 0 109.039v111.56c.022 9.026 7.334 16.338 16.36 16.36H172v-16c.022-7.635 6.206-13.818 13.84-13.84h20.24c7.597-.045 13.81 6.043 13.92 13.64v16h155.52c8.996.045 16.348-7.165 16.48-16.16v-111.56c-.044-10.316-8.443-18.644-18.76-18.6zm-125.56 0H144.32v-27.6h103.36v27.6z"/>'
  }
})
