/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'required_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M503.7 990h-7.4c-41.1 0-74.7-33.6-74.7-74.7V84.7c0-41.1 33.6-74.7 74.7-74.7h7.4c41.1 0 74.7 33.6 74.7 74.7v830.6c0 41.1-33.6 74.7-74.7 74.7z"/><path pid="1" d="M77.5 748.2l-3.7-6.5c-20.5-35.6-8.2-81.5 27.3-102l719.4-415.3c35.6-20.5 81.5-8.2 102 27.3l3.7 6.4c20.5 35.6 8.2 81.5-27.3 102L179.5 775.6c-35.5 20.5-81.5 8.2-102-27.4z"/><path pid="2" d="M73.8 258.2l3.7-6.5c20.5-35.6 66.4-47.9 102-27.3l719.4 415.3c35.6 20.5 47.9 66.4 27.3 102l-3.7 6.5c-20.5 35.6-66.4 47.9-102 27.3L101.1 360.2c-35.6-20.5-47.8-66.4-27.3-102z"/>'
  }
})
