// src/mixins/responsive.js

import responsiveService from '@/services/responsive';

export default {
  data() {
    return {
      mediaBreakpoint: 991
    };
  },
  computed: {
    windowWidth() {
      return responsiveService.state.windowWidth;
    },
    isDesktop() {
      return this.windowWidth > this.mediaBreakpoint;
    },
    isMobile() {
      return this.windowWidth <= this.mediaBreakpoint;
    }
  },
  mounted() {
    responsiveService.addResizeListener();
  },
  beforeDestroy() {
    responsiveService.removeResizeListener();
  }
};
