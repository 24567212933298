var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input-wrapper',_vm._g(_vm._b({class:[_vm.$bem(), { 'not-empty': !!_vm.value }],scopedSlots:_vm._u([{key:"input",fn:function(ref){
var on = ref.on;
var isError = ref.isError;
return [_c('textarea',_vm._g(_vm._b({directives:[{name:"input-autofocus",rawName:"v-input-autofocus",value:(_vm.autofocus),expression:"autofocus"}],class:[_vm.$bem('input')]},'textarea',{
        name: _vm.name,
        value: _vm.value,
        required: _vm.required,
        disabled: _vm.disabled,
        size: _vm.size,
        placeholder: _vm.placeholder,

      },false),Object.assign({}, _vm.$listeners,
        on,
        {input: function (e) { return _vm.$emit('input', e.target.value); }}))),_c('label',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!!_vm.label),expression:"!!label"}],class:[_vm.$bem('label'), _vm.dir]},'label',{ disabled: _vm.disabled },false),[_vm._v(" "+_vm._s(_vm.label)+" "),_vm._t("label",null,null,{ label: _vm.label, isError: isError }),_c('sub',{directives:[{name:"show",rawName:"v-show",value:(_vm.required),expression:"required"}],class:_vm.$bem('required')},[_vm._v("*")])],2)]}}],null,true)},'input-wrapper',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }